import { MatchMode } from '../../enumeration/match-mode.enum';
import { FilterType } from './filterType';

export class FilterCriteriaResource {
  key: string;

  value: any;

  filterType: FilterType;

  matchMode: MatchMode;

  constructor(key: string, value: any, filterType: FilterType, matchMode: MatchMode = MatchMode.CONTAINING) {
    this.key = key;
    this.value = value;
    this.filterType = filterType;
    this.matchMode = matchMode;
  }
}
