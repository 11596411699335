import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'portal-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  error: any;

  constructor(@Inject(MAT_DIALOG_DATA) private readonly dialogData) {}

  ngOnInit() {
    this.error = this.dialogData.error;
  }
}
