<div class="headline">
  @if (headlineKey) {
    <label>{{ headlineKey | translate }}</label>
  }
  @if (headline) {
    <label>{{ headline }}</label>
  }
</div>
@if (messageKey) {
  <div class="dialog-content">
    {{ messageKey | translate }}
  </div>
}
@if (message) {
  <div class="dialog-content">
    {{ message }}
  </div>
}
<div class="[ form-content-modal ]">
  <button class="[ c-mat-button no-color-button ]" mat-button mat-dialog-close>
    <i aria-hidden="true"></i>
    {{ 'CONFIRMATION_DIALOG.BUTTON.NO' | translate }}
  </button>
  <button [mat-dialog-close]="true" class="[ c-mat-button green-button right-button ]" mat-flat-button>
    <i aria-hidden="true" class="[ icon-baseline-done-24px ]" style="font-size: 24px"></i>
    {{ 'CONFIRMATION_DIALOG.BUTTON.YES' | translate }}
  </button>
</div>