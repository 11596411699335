import { FilterType } from '../../core/filter/filterType';

export class SearchFieldDefinition {
  name: string;

  type: FilterType;

  constructor(name: string, type: FilterType) {
    this.name = name;
    this.type = type;
  }
}
