import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Agrarartikel } from '../../model/agrarartikel';
import { AgrarartikelService } from '../agrarartikel/agrarrartikel.service';
import { CustomErrorHandlerService } from '../custom-error-handler/custom-error-handler.service';
import { ModaTranslateService } from '../moda-translate.service';

@Injectable({
  providedIn: 'root'
})
export class BesamungsdienstleistungenService {
  besamungsDienstleistungen: Agrarartikel[];

  vitartikelnummerAnfahrt: string;

  vitartikelnummerDurchfuehrung: string;

  mapArtikelGruppe: Map<number, string[]>;

  constructor(
    private readonly agrarartikelService: AgrarartikelService,
    private readonly customErrorHandlerService: CustomErrorHandlerService,
    private readonly modaTranslateService: ModaTranslateService
  ) {
    this.getVitDienstleistungen();
  }

  /**
   * Erstellt ein Map mit Artikelgruppe und Artikelnummer.
   * @param data Liste von Agrarartikeln
   */
  addArtikelGruppeMap(data: Array<Agrarartikel>) {
    this.mapArtikelGruppe = new Map();
    for (const artikel of data) {
      let dienstleistungen = this.mapArtikelGruppe.get(artikel.artikelgruppe);
      if (!dienstleistungen) {
        dienstleistungen = [];
        this.mapArtikelGruppe.set(artikel.artikelgruppe, dienstleistungen);
      }
      if (!dienstleistungen.includes(artikel.artikelnummer)) {
        dienstleistungen.push(artikel.artikelnummer);
      }
    }
  }

  /**
   * Liefert ein Map mit artikelgruppe und artikelnummer.
   */
  public getArtikelGruppeMap(): Map<number, string[]> {
    return this.mapArtikelGruppe;
  }

  /**
   * Liefert VitArtikelnummer von Anfahrt.
   */
  public getVitArtikelnummerAnfahrt(): string {
    return this.vitartikelnummerAnfahrt;
  }

  /**
   * Liefert VitArtikelnummer von Durchfuehrung.
   */
  public getVitArtikelnummerDurchfuehrung(): string {
    return this.vitartikelnummerDurchfuehrung;
  }

  /**
   * Liefert alle Dienstleistungen.
   */
  getVitDienstleistungen(): Observable<Agrarartikel[]> {
    if (!this.besamungsDienstleistungen) {
      const subject = new Subject<Agrarartikel[]>();
      this.agrarartikelService.getVitDienstleistungen().subscribe(
        (data) => {
          this.besamungsDienstleistungen = data;
          this.setAnfahrtDurchfuehrungVitArtikelNummer();
          this.addArtikelGruppeMap(data);
          subject.next(data);
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
      return subject;
    } else {
      return of(this.besamungsDienstleistungen);
    }
  }

  /**
   * Setzt BesamungCheckboxen Artikelnummer als globale Variable.
   * Damit diese VitArtikelnummer im html benutzt werden kann.
   */
  private setAnfahrtDurchfuehrungVitArtikelNummer() {
    for (const besamungDienstleistung of this.besamungsDienstleistungen) {
      if (besamungDienstleistung.bezeichnung.trim() === this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.BESAMUNGSCHECKBOXEN.ANFAHRT')) {
        this.vitartikelnummerAnfahrt = besamungDienstleistung.vitArtikelnummer;
      } else if (
        besamungDienstleistung.bezeichnung.trim() === this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.BESAMUNGSCHECKBOXEN.DURCHFUEHRUNG')
      ) {
        this.vitartikelnummerDurchfuehrung = besamungDienstleistung.vitArtikelnummer;
      }
    }
  }
}
