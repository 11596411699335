import { Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomErrorHandlerService } from '../../../service/custom-error-handler/custom-error-handler.service';
import { ModaTranslateService } from '../../../service/moda-translate.service';
import { Utils } from '../../../utils/utils';

@Directive()
export abstract class AbstractTable {
  customClasses = {
    sortAscending: 'fa fa-caret-up',
    sortDescending: 'fa fa-caret-down'
  };

  translatedMessageEmpty: Subscription;

  tableMessages;

  modaTranslateService: ModaTranslateService;

  customErrorHandlerService: CustomErrorHandlerService;

  /**
   * Konstruktor.
   *
   * @param modaTranslateService ModaTranslateService
   * @param customErrorHandlerService CustomErrorHandlerService
   */
  constructor(modaTranslateService: ModaTranslateService, customErrorHandlerService: CustomErrorHandlerService) {
    this.modaTranslateService = modaTranslateService;
    this.customErrorHandlerService = customErrorHandlerService;
    this.createTableMessages();
  }

  /**
   * Erstellt die Messages.
   *
   */
  createTableMessages() {
    this.tableMessages = {
      emptyMessage: null,
      totalMessage: null,
      selectedMessage: null
    };
    this.tableMessages.emptyMessage = this.getModaTranslateService().translate('TABLE.MESSAGE.EMPTY');
  }

  /**
   * CustomErrorHandlerService.
   */
  public getCustomErrorHandlerService(): CustomErrorHandlerService {
    return this.customErrorHandlerService;
  }

  /**
   * ModaTranslateService.
   */
  public getModaTranslateService(): ModaTranslateService {
    return this.modaTranslateService;
  }

  /**
   * Unsubscribe Message Subscription.
   */
  unsubscribeMessageSubscription() {
    Utils.unsubscribeSubscription(this.translatedMessageEmpty);
  }
}
