import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { Role } from '../../guard/role';
import { EventService } from '../../service/event/event.service';
import { InseminatorService } from '../../service/inseminator/inseminator.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';

@Component({
  selector: 'portal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  authenticated: boolean;

  userRoles: string[];

  navigation: Array<object>;

  logoPath: string;

  hover: boolean;

  openSubMenu: string;

  user: string;

  isBesamungstechniker: boolean;

  vertreter: number[];

  constructor(
    private keycloakService: KeycloakService,
    private inseminatorService: InseminatorService,
    private eventService: EventService,
    private readonly modaKeycloakService: ModaKeycloakService
  ) {
    this.logoPath = './assets/images/vost_logo_white.svg';
    this.navigation = [
      {
        item: 'NAVIGATION.AUFTRAEGE',
        roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER],
        subMenu: [
          {
            item: 'NAVIGATION.NEUER_AUFTRAG',
            link: '/neuerAuftrag',
            roles: [Role.BAP_BERATER, Role.BESAMUNGSTECHNIKER]
          },
          {
            item: 'NAVIGATION.NEUE_BESTELLUNG_SPERMA',
            link: '/bestellungen',
            queryParams: {
              neueBestellung: true
            },
            roles: [Role.BESAMUNGSTECHNIKER]
          },
          {
            item: 'NAVIGATION.NEUER_VERLUST',
            link: '/verlustuebersicht',
            queryParams: {
              neuerVerlust: true
            },
            roles: [Role.BESAMUNGSTECHNIKER]
          },
          {
            item: 'NAVIGATION.NEUER_TAUSCH',
            link: '/tauschuebersicht',
            queryParams: {
              neuerTausch: true
            },
            roles: [Role.BESAMUNGSTECHNIKER]
          }
        ]
      },
      {
        item: 'NAVIGATION.BESTANDSVERWALTUNG',
        roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
        subMenu: [
          {
            item: 'NAVIGATION.BESTAND_SPERMA',
            link: 'bestandsperma',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN]
          },
          {
            item: 'NAVIGATION.BESTAND_BETRIEBSSPERMA',
            link: 'bestandbetriebssperma',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN]
          },
          {
            item: 'NAVIGATION.BESTAND_AGRARARTIKEL',
            link: '/bestandagrarartikel',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN]
          }
        ]
      },
      {
        item: 'NAVIGATION.STAMMDATEN',
        roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
        subMenu: [
          {
            item: 'NAVIGATION.STAMMDATEN_TIERLISTE',
            link: '/tierliste',
            roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN]
          },
          {
            item: 'NAVIGATION.STAMMDATEN_TIERLISTE_GESAMT',
            link: '/tierlistegesamt',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN]
          },
          {
            item: 'NAVIGATION.STAMMDATEN_ARTIKELLISTE',
            link: '/artikelliste',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN]
          },
          {
            item: 'NAVIGATION.STAMMDATEN_BETRIEBLISTE',
            link: '/betriebsliste',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN]
          }
        ]
      },
      {
        item: 'NAVIGATION.AUSWERTUNGEN',
        roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
        subMenu: [
          {
            item: 'NAVIGATION.LEISTUNGSPROTOKOLL',
            link: '/leistungsprotokoll',
            roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER]
          },
          {
            item: 'NAVIGATION.LEISTUNGSPROTOKOLL_LANDWIRT',
            link: '/betriebLeistungsprotokollLandwirt',
            roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER]
          },
          {
            item: 'NAVIGATION.AUFTRAGSHISTORIE',
            link: '/auftraege',
            roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN]
          },
          {
            item: 'NAVIGATION.BESTELLUNGEN',
            link: '/bestellungen',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN]
          },
          {
            item: 'NAVIGATION.VERLUST',
            link: '/verlustuebersicht',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN]
          },
          {
            item: 'NAVIGATION.TAUSCH',
            link: '/tauschuebersicht',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN]
          },
          {
            item: 'NAVIGATION.DOKUMENTE',
            link: '/dokumentuebersicht',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN]
          }
        ]
      },
      {
        item: 'NAVIGATION.CONFIGURATION',
        roles: [Role.MODA_ADMIN, Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER],
        subMenu: [
          {
            item: 'NAVIGATION.DEVICE_CONFIGURATION_UEBERSICHT',
            link: '/devices',
            roles: [Role.MODA_ADMIN]
          },
          {
            item: 'NAVIGATION.JOBUEBERSICHT',
            link: '/jobs',
            roles: [Role.MODA_ADMIN]
          },
          {
            item: 'NAVIGATION.VERTRETUNG_TECHNIKER',
            link: '/vertretungTechniker',
            roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER]
          },
          {
            item: 'NAVIGATION.VERTRETUNG_BETRIEB',
            link: '/vertretungBetrieb',
            roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER]
          }
        ]
      }
    ];
  }

  /**
   * Prueft, ob der eingeloggte Benutzer das Recht hat, auf Navigationspunkte zuzugreifen oder nicht.
   *
   * @param roles Rollen des eingeloggeten Benutzers
   */
  checkAuthentication(roles: Array<Role>): boolean {
    let authenticated = false;
    for (const role of roles) {
      if (
        this.userRoles.find((element) => {
          return element === role;
        })
      ) {
        authenticated = true;
      }
    }
    return authenticated;
  }

  /**
   * Vertretungen werden geladen.
   */
  loadVertreter() {
    this.inseminatorService.loadDataForUser(new SearchFilterPagingResource()).subscribe((data) => {
      this.vertreter = data.content.map((value) => value.technikerKey.number);
    });
  }

  login(): void {
    this.keycloakService.login();
    this.authenticated = this.keycloakService.getKeycloakInstance().authenticated;
    if (this.authenticated) {
      this.keycloakService.loadUserProfile();
    }
  }

  logout(): void {
    this.keycloakService.logout(window.location.origin);
    this.authenticated = this.keycloakService.getKeycloakInstance().authenticated;
  }

  /**
   * Funktion zum oeffnen des Submenus.
   *
   * @param id ElementID
   * @param level Level
   */
  mouseHovering(id: string, level: number) {
    if (level === 1) {
      this.openSubMenu = id;
      this.hover = true;
    }
  }

  /**
   * Funktion zum schliessen des SubMenus
   *
   * @param level Level
   */
  mouseLeft(level: number) {
    if (level === 1) {
      this.openSubMenu = '';
      this.hover = false;
    }
  }

  ngOnInit() {
    this.authenticated = this.keycloakService.getKeycloakInstance().authenticated;
    this.userRoles = this.keycloakService.getUserRoles();
    if (this.authenticated) {
      this.user = this.keycloakService
        .getKeycloakInstance()
        .idTokenParsed['given_name'].concat(' ', this.keycloakService.getKeycloakInstance().idTokenParsed['family_name']);
      this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
      if (this.isBesamungstechniker) {
        this.eventService.currentData.subscribe((currentData) => this.loadVertreter());
      }
    }
  }
}
