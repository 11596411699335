import { FilterCriteriaResource } from './filterCriteriaResource';
import { FilterType } from './filterType';

/**
 * FilterCriteria.
 */
export class FilterCriteria {
  private filter: FilterCriteriaResource[];

  constructor() {
    this.filter = [];
  }

  /**
   * Fuegt neue Filtercriteria
   * @param key Key
   * @param value Value
   * @param filterType FilterType
   */
  public addFilter(key: string, value: any, filterType: FilterType) {
    this.addFilterCriteriaResource(new FilterCriteriaResource(key, value, filterType));
  }

  /**
   * Fuegt neue FilterCriteriaResource
   * @param filterCriteriaResource FilterCriteriaResource
   */
  public addFilterCriteriaResource(filterCriteriaResource: FilterCriteriaResource) {
    this.filter.push(filterCriteriaResource);
  }

  public getFilterCriteria(): FilterCriteriaResource[] {
    return this.filter;
  }
}
