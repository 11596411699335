<div class="headline">
  <label>{{ 'ERROR_DIALOG.HEADLINE' | translate }}</label>
</div>
@if (error) {
  <div class="content">
    @if (error.status) {
      <div>
        <h3>{{ 'ERROR_DIALOG.STATUS' | translate }}</h3>
        <p>
          {{ error.status }}
        </p>
      </div>
    }
    @if (error.statusText) {
      <div>
        <h3>{{ 'ERROR_DIALOG.STATUS_TEXT' | translate }}</h3>
        <p>
          {{ error.statusText }}
        </p>
      </div>
    }
    @if (error.message) {
      <div>
        <h3>{{ 'ERROR_DIALOG.MESSAGE' | translate }}</h3>
        <p>
          {{ error.message }}
        </p>
      </div>
    }
  </div>
}
