<div class="[ panel verlustuebersicht ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'NAVIGATION.VERLUST' | translate }}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="'TOOLBAR.SUCHBEGRIFF' | translate"></portal-search>
        <portal-date-search (search)="doAndSearch($event)" [field]="getSearchFieldDate()" [interval]="true"
          class="[ date-search ]">
        </portal-date-search>
      </div>
      <div class="[ buttons ]">
        @if (isBesamungstechniker) {
          <button (click)="neuerVerlust()" class="[ c-mat-button green-button ]"
            mat-flat-button>
            <i aria-hidden="true" class="[ icon-plus ]" style="margin-right: 10px"></i>
            {{ 'VERLUSTUEBERSICHT.NEUER_VERLUST' | translate | uppercase }}
          </button>
        }
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]">
          <ngx-datatable
              #verlustTabelle
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="100" name="{{'VERLUSTUEBERSICHT.DATUM' | translate}}" prop="auftragsdatum">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value | datexpipe: 'L'}}">{{ value | datexpipe: 'L' }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="100" name="{{'VERLUSTUEBERSICHT.VERLUSTNUMMER' | translate}}" prop="receiptNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'VERLUSTUEBERSICHT.VERLUSTNUMMER' | translate}}">
                {{ 'VERLUSTUEBERSICHT.VERLUSTNUMMER' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            @if (!isBesamungstechniker) {
              <ngx-datatable-column [draggable]="false"
                [headerClass]="'c-data-list__header--default'" [sortable]="true"
                [width]="100" name="{{'VERLUSTUEBERSICHT.TECHNIKER' | translate}}" prop="technikerKey.number">
                <ng-template let-sort="sortFn" ngx-datatable-header-template>
                  <span (click)="sort()" class="pointer right-cell-header"
                    title="{{'VERLUSTUEBERSICHT.TECHNIKER' | translate}}">
                  {{ 'VERLUSTUEBERSICHT.TECHNIKER' | translate }}</span>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  <div class="right-cell" title="{{ value }}">{{ value }}</div>
                </ng-template>
              </ngx-datatable-column>
            }
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'VERLUSTUEBERSICHT.NAME' | translate}}" prop="name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span title="{{ row.name }}({{ row.herdbookNumber }})">
                  {{ row.name }}({{ row.herdbookNumber }})
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="true" [width]="80"
              name="{{'VERLUSTUEBERSICHT.RASSE' | translate}}" prop="rasse.shortLabel">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="false"
              name="{{'VERLUSTUEBERSICHT.BEHANDLUNGSART' | translate}}" prop="behandlungsart">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span [title]="value | spermaart">{{ value | spermaart }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="false"
              name="{{'VERLUSTUEBERSICHT.GRUND' | translate}}" prop="comment">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="90" name="{{'VERLUSTUEBERSICHT.KURZNUMMER' | translate}}" prop="farmInfo.oldShortNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'VERLUSTUEBERSICHT.KURZNUMMER' | translate}}">
                {{ 'VERLUSTUEBERSICHT.KURZNUMMER' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false"
              [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="170" name="{{'VERLUSTUEBERSICHT.BETRIEB_NAME' | translate}}"
              prop="farmInfo.adresse.nachname">
              <ng-template let-row="row" ngx-datatable-cell-template>
                @if (row.farmInfo) {
                  <span
                    title="{{ row.farmInfo.adresse?.nachname}}, {{ row.farmInfo.adresse?.vorname }}">
                    {{ row.farmInfo.adresse?.nachname }}, {{ row.farmInfo.adresse?.vorname }}
                  </span>
                }
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="80" name="{{'VERLUSTUEBERSICHT.ANZAHL' | translate}}" prop="anzahl">
              <ng-template ngx-datatable-header-template>
                <span class="right-cell-header" title="{{'VERLUSTUEBERSICHT.ANZAHL' | translate}}">
                {{ 'VERLUSTUEBERSICHT.ANZAHL' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="false" name="{{'VERLUSTUEBERSICHT.STATUS' | translate}}"
              prop="auftragspositionsstatus">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span [title]="value | translate">{{ value | translate }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="50">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="right-cell">
                  @if (row.auftragspositionsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.ERSTELLT') {
                    <i (click)="onDeletePosition(row)"
                      aria-hidden="true"
                      class="[ icon-baseline-delete-24px action-icon ]"></i>
                  }
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="verlustTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
