import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Farm } from '../../model/farm';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private selectedFarm = new BehaviorSubject(null);

  constructor() {}

  getSelectedFarm(): Farm {
    return this.selectedFarm.getValue();
  }

  passSelectedFarm(farm: Farm) {
    this.selectedFarm.next(farm);
  }
}
