import { Adresse } from './adresse';

export class FarmInfo {
  betriebsschluessel: string;

  shortNumber: string;

  oldShortNumber: string;

  adresse: Adresse;

  farmCategory: number;

  stammtechnikername: string;
}
