import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { TechnikerDocument } from '../../model/technikerDocument';
import { ClientPaginationService } from '../client-pagination-service';

@Injectable({
  providedIn: 'root'
})
export class DocumentFileService implements ClientPaginationService<TechnikerDocument> {
  private url = '/api/v1/document';

  constructor(private http: HttpClient) {}

  /**
   *  Download die Dokumente als zip von der Liste mit Dokumenten für den angemeldeten Techniker.
   *
   * @param filenames Filenames, die herunterladen sollen.
   * @return  Observable<HttpResponse<Blob>>
   */
  public downloadAsZip(filenames: string[]): Observable<HttpResponse<Blob>> {
    return this.http.post(this.url + '/downloadAsZip', filenames, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  /**
   * Liefert eine Liste mit Dokumenten für den angemeldeten Techniker.
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return  Observable<Array<TechnikerDocument>>
   */
  public findAll(searchFilterPagingResource?: SearchFilterPagingResource): Observable<Array<TechnikerDocument>> {
    return this.http.post<Array<TechnikerDocument>>(this.url + '/findAll', searchFilterPagingResource);
  }

  /**
   * {@inheritDoc}
   */
  public loadData(): Observable<Array<TechnikerDocument>> {
    return this.http.get<Array<TechnikerDocument>>(this.url);
  }
}
