export class Role {
  public static BAP_BERATER = 'BAP_BERATER';

  public static BESAMUNGSSTATION = 'BESAMUNGSSTATION';

  public static BESAMUNGSTECHNIKER = 'BESAMUNGSTECHNIKER';

  public static MODA_ADMIN = 'MODA_ADMIN';

  public static MODAREALMADMIN = 'MODAREALMADMIN';
}
