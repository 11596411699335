import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogUtils } from '../../../utils/dialog-utils';

@Component({
  selector: 'portal-error-dialog-customer',
  templateUrl: './error-dialog-customer.component.html',
  styleUrls: ['./error-dialog-customer.component.scss']
})
export class ErrorDialogCustomerComponent implements OnInit {
  error: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly dialogData,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly dialog: MatDialog
  ) {}

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.error = this.dialogData.error;
  }

  /**
   * Oeffnet einen Modalen Dialog mit der Fehlermeldung.
   */
  showDetails() {
    DialogUtils.openInformationDialog(this.dialog, {
      headlineKey: 'ERROR_DIALOG_CUSTOMER.FEHLERMELDUNG_HEADLINE',
      message: JSON.stringify(this.error)
    });
  }
}
