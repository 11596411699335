<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'AUFTRAGSUEBERSICHT.AUFTRAG_HISTORIE'|translate }}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="'TOOLBAR.SUCHBEGRIFF' | translate"></portal-search>
        <portal-date-search (search)="doAndSearch($event)" [field]="getSearchFieldDate()" [interval]="true"
          class="[ date-search ]"></portal-date-search>
        @if (isBesamungstechniker || isBapBerater) {
          <div class="[ checkbox ]">
            <mat-checkbox (change)="changeCheckbox()"
              [(ngModel)]="nurEigene">{{ 'AUFTRAGSUEBERSICHT.EIGENE_ANZEIGEN' | translate }}
            </mat-checkbox>
          </div>
        }
      </div>
      <div class="[ buttons ]">
        @if (isBesamungstechniker) {
          <button (click)="auftragErfassen()" class="[ c-mat-button green-button ]"
            mat-flat-button>
            <i aria-hidden="true" class="[ icon-plus ]" style="margin-right: 10px"></i>
            {{ 'AUFTRAGSUEBERSICHT.AUFTRAG_ERFASSEN'|translate }}
          </button>
        }
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]">
          <ngx-datatable
              #auftragsuebersichtTabelle
            (activate)="onActivate($event)"
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="90" name="{{'AUFTRAGSUEBERSICHT.DATUM' | translate}}" prop="auftragsdatum">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value | datexpipe: 'L'}}">{{ value | datexpipe: 'L' }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="40"
              name="{{'AUFTRAGSUEBERSICHT.HERKUNFT' | translate}}">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer" title="{{'AUFTRAGSUEBERSICHT.HERKUNFT_TITLE' | translate}}">
                {{ 'AUFTRAGSUEBERSICHT.HERKUNFT' | translate }}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                @if (row.auftragsnummer.length === 16 && row.auftragsnummer.startsWith('1')) {
                  <span
                    title="{{ 'AUFTRAGSUEBERSICHT.HERKUNFT_P_TITLE' | translate }}">
                  {{ 'AUFTRAGSUEBERSICHT.HERKUNFT_P' | translate }}</span>
                }
                @if (row.auftragsnummer.length === 16 && row.auftragsnummer.startsWith('2')) {
                  <span
                    title="{{ 'AUFTRAGSUEBERSICHT.HERKUNFT_A_TITLE' | translate }}">
                  {{ 'AUFTRAGSUEBERSICHT.HERKUNFT_A' | translate }}</span>
                }
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="50" name="{{'AUFTRAGSUEBERSICHT.TYP' | translate}}" prop="type">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{value +'_TITLE' | translate }}">{{ value | translate }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="100" name="{{'AUFTRAGSUEBERSICHT.AUFTRAGSNUMMER' | translate}}" prop="auftragsnummer">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'AUFTRAGSUEBERSICHT.AUFTRAGSNUMMER' | translate}}">
                {{ 'AUFTRAGSUEBERSICHT.AUFTRAGSNUMMER' | translate }}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                @if (row.auftragsnummer?.length > 15) {
                  <div class="right-cell"
                    title="{{row.auftragsnummer}}">
                    {{ row.auftragsnummer?.substring(row.auftragsnummer?.length - 8, row.auftragsnummer?.length) }}
                  </div>
                }
                @if (row.auftragsnummer?.length <= 15) {
                  <div class="right-cell"
                    title="{{ row.auftragsnummer }}">{{ row.auftragsnummer }}
                  </div>
                }
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="90"
              prop="receiptNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'AUFTRAGSUEBERSICHT.POSITIONSNUMMER_TITLE' | translate}}">
                {{ 'AUFTRAGSUEBERSICHT.POSITIONSNUMMER' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="75" name="{{'AUFTRAGSUEBERSICHT.TECHNIKER' | translate}}">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'AUFTRAGSUEBERSICHT.TECHNIKER_TITLE' | translate}}">
                {{ 'AUFTRAGSUEBERSICHT.TECHNIKER' | translate }}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="right-cell" title="{{row.technikerKey.number}}">{{ row.technikerKey.number }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="80" name="{{'AUFTRAGSUEBERSICHT.KURZNUMMER' | translate}}" prop="shortNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'AUFTRAGSUEBERSICHT.KURZNUMMER' | translate}}">
                {{ 'AUFTRAGSUEBERSICHT.KURZNUMMER' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="true" [width]="350" name="{{'NEUER_AUFTRAG.NAME' | translate}}" prop="name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                @if (row.adresse) {
                  <span
                    title="{{ row.adresse.nachname}}, {{ row.adresse.vorname }} - {{ row.adresse.ort}}, {{row.adresse.ortsteil}}">
                    {{ row.adresse.nachname }}, {{ row.adresse.vorname }} - {{ row.adresse.ort }}
                    , {{ row.adresse.ortsteil }}
                  </span>
                }
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="155" name="{{'AUFTRAG_DETAIL.LOM' | translate}}" prop="cattleKey.lom">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span class="right-cell-header" title="{{'AUFTRAGSUEBERSICHT.LOM' | translate}}">
                {{ 'AUFTRAGSUEBERSICHT.LOM' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="90"
              prop="itemNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'AUFTRAGSUEBERSICHT.ARTIKELNUMMER_TITLE' | translate}}">
                {{ 'AUFTRAGSUEBERSICHT.ARTIKELNUMMER' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="140" name="{{'AUFTRAG_DETAIL.BEZEICHNUNG' | translate}}" prop="bezeichnung">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="100"
              name="{{'AUFTRAGSUEBERSICHT.AUFTRAGSPOSITIONSSTATUS' | translate}}"
              prop="auftragspositionsstatus">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span [title]="value | translate">{{ value | translate }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="40" name="">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <i (click)="showAuftragDetails(row)" aria-hidden="true" class="[ icon-ic_inspect icon-btn ]"></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="auftragsuebersichtTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
