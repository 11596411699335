<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'BESTAND_SPERMA.BESTANDUEBERSICHT' | translate }}
    </div>
  </div>
  <div class="bestand-sperma-container">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
        @if (!isBesamungstechniker) {
          <portal-search (search)="doAndSearchTechniker($event)"
            [fields]="getSearchFieldTechniker()"
            [placeholder]="('TOOLBAR.SUCHBEGRIFF_TECHNIKER_NR' | translate)" class="[ date-search ]"></portal-search>
        }
        <portal-range-search (search)="doAndSearchBestand($event)"
          [defMinValue]="1" [field]="getSearchFieldDefinitionBestand()"
          [maxPlaceholder]="('SPERMAAUSWAHL.BESTAND_BIS' | translate)"
          [minPlaceholder]="('SPERMAAUSWAHL.BESTAND_VON' | translate)"
          class="[ margin-left-20 ] [ date-search ]"></portal-range-search>
        <portal-search #searchRasseField (search)="doAndSearchRasse($event)" [fields]="getSearchFieldDefinitionRasse()"
          [placeholder]="('BULLENUEBERSICHT.RASSE' | translate)"
          class="[ margin-left-20 ]"></portal-search>
      </div>
    </div>
    <div class="[ row ]">
      <div class="[ col-12 ]  [ c-data-list ]">
        <div class="[ content-table ]">
          <ngx-datatable
              #bestandSpermaartikelTabelle
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            @if (!isBesamungstechniker) {
              <ngx-datatable-column [draggable]="false"
                [headerClass]="'c-data-list__header--default'"
                [maxWidth]="100" [sortable]="true"
                name="{{'BESTAND_SPERMA.TECHNIKER' | translate}}"
                prop="spermabestandTechnikerList.spermabestandTechnikerKey.technikerKey.number">
                <ng-template let-sort="sortFn" ngx-datatable-header-template>
                  <span (click)="sort()" class="pointer right-cell-header"
                    title="{{'BESTAND_SPERMA.TECHNIKER' | translate}}">
                  {{ 'BESTAND_SPERMA.TECHNIKER' | translate }}</span>
                </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div class="right-cell">
                    {{ row.spermabestandTechnikerList[0]?.spermabestandTechnikerKey.technikerKey.number }}
                  </div>
                </ng-template>
              </ngx-datatable-column>
            }
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="100" [sortable]="true"
              name="{{'BESTAND_SPERMA.HB_NR' | translate}}" prop="herdbookNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_SPERMA.HB_NR' | translate}}">{{ 'BESTAND_SPERMA.HB_NR' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              name="{{'BESTAND_SPERMA.NAME' | translate}}" prop="name">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              name="{{'BESTAND_SPERMA.RASSE' | translate}}" prop="rasse.shortLabel">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              name="{{'BESTAND_SPERMA.BEHANDLUNGSART' | translate}}" prop="label">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span title="{{ value | spermaart }} ">{{ value | spermaart }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              name="{{'BESTAND_SPERMA.CHARGE' | translate}}">
              <ng-template ngx-datatable-header-template>
                <span class="right-cell-header"
                  title="{{'BESTAND_SPERMA.CHARGE' | translate}}">{{ 'BESTAND_SPERMA.CHARGE' | translate }}</span>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="right-cell"
                  title="{{ row.spermabestandTechnikerList[0]?.spermabestandTechnikerKey.lotNumber}}">
                  {{ row.spermabestandTechnikerList[0]?.spermabestandTechnikerKey.lotNumber }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
              [sortable]="true" name="{{'BESTAND_SPERMA.BESTAND' | translate}}"
              prop="spermabestandTechnikerList.amount">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'BESTAND_SPERMA.BESTAND' | translate}}">{{ 'BESTAND_SPERMA.BESTAND' | translate }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ row.spermabestandTechnikerList[0]?.amount }}">
                  {{ row.spermabestandTechnikerList[0]?.amount }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="bestandSpermaartikelTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>