import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { FilterCriteria } from '../../../core/filter/filterCriteria';
import { FilterCriteriaResource } from '../../../core/filter/filterCriteriaResource';
import { FilterType } from '../../../core/filter/filterType';
import { SearchFilterPagingResource } from '../../../core/filter/searchFilterPagingResource';
import { SpermaartPipe } from '../../../core/pipe/spermaart.pipe';
import { Aenderungstyp } from '../../../enumeration/aenderungstyp.enum';
import { DateValidation } from '../../../model/dateValidation';
import { Inseminator } from '../../../model/Inseminator';
import { Spermaartikel } from '../../../model/spermaartikel';
import { SpermabestandTechniker } from '../../../model/spermabestand-techniker';
import { Spermatausch } from '../../../model/spermatausch';
import { CustomErrorHandlerService } from '../../../service/custom-error-handler/custom-error-handler.service';
import { InseminatorService } from '../../../service/inseminator/inseminator.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { SpermaartikelService } from '../../../service/spermaartikel/spermaartikel.service';
import { SpermatauschService } from '../../../service/spermatausch/spermatausch.service';
import { Utils } from '../../../utils/utils';
import { SearchFieldDefinition } from '../../search/search-field-definition';
import { SearchComponent } from '../../search/search.component';
import { SpermaauswahlModalComponent } from '../../spermaauswahl-modal/spermaauswahl-modal.component';
import { TechnikeruebersichtModalComponent } from '../../technikeruebersicht-modal/technikeruebersicht-modal.component';
import { VerlustuebersichtComponent } from '../../verlustuebersicht/verlustuebersicht.component';

@Component({
  selector: 'portal-tauschauswahl',
  templateUrl: './tauschauswahl.component.html',
  styleUrls: ['./tauschauswahl.component.scss']
})
export class TauschauswahlComponent implements OnInit, OnDestroy {
  selectableChargen: Array<SpermabestandTechniker>;

  selectedBulle: Spermaartikel;

  tauschForm: UntypedFormGroup;

  searchBulleSubscription: Subscription;

  searchTechnikerSubscription: Subscription;

  selectedDate: DateValidation;

  selectedTechniker: Inseminator;

  minDate: Moment;

  @ViewChild('searchBulleInput') searchBulleInput: SearchComponent;

  @ViewChild('searchTechnikerInput') searchTechnikerInput: SearchComponent;

  constructor(
    public dialogRef: MatDialogRef<VerlustuebersichtComponent>,
    @Inject(MAT_DIALOG_DATA) readonly dialogData,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService,
    private readonly spermaartPipe: SpermaartPipe,
    private readonly form: UntypedFormBuilder,
    private readonly spermatauschService: SpermatauschService,
    private readonly customErrorHandlerService: CustomErrorHandlerService,
    private readonly spermaartikelService: SpermaartikelService,
    private readonly inseminatorService: InseminatorService
  ) {}

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Funktion um das Validierungsformular zu bauen und in welcher Form die Validierung stattfindet.
   * Es muessen alle Felder die angegeben werden, welche das formControlName Attribute aufweisen.
   * Zusaetzlich wird hier bestimmt mit welchem Event (blur, change oder submit) die Validierung ausgeführt wird.
   */
  buildForm() {
    this.tauschForm = this.form.group({
      selectedBulleLabel: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ),
      charge: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [Validators.required, Validators.minLength(4)],
          updateOn: 'change'
        }
      ),
      bemerkung: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [],
          updateOn: 'blur'
        }
      ),
      anzahl: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [Validators.required, Validators.min(1)],
          updateOn: 'blur'
        }
      ),
      selectedTechnikerLabel: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      )
    });
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldDefinitionsBulle(): SearchFieldDefinition[] {
    return [new SearchFieldDefinition('name', FilterType.STRING), new SearchFieldDefinition('herdbookNumber', FilterType.STRING)];
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldDefinitionsTechniker(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('number', FilterType.NUMBER),
      new SearchFieldDefinition('firstname', FilterType.STRING),
      new SearchFieldDefinition('lastname', FilterType.STRING)
    ];
  }

  /**
   * Schliesst den Modalen Dialog und uebergibt den Tausch.
   */
  hinzufuegen() {
    if (this.tauschForm.valid) {
      if (+this.tauschForm.value.charge === 0) {
        this.notificationService.showInfoNotification('TAUSCHAUSWAHL.MESSAGE.NULL_CHARGE');
        this.tauschForm.controls.charge.setErrors({ incorrect: true });
      } else {
        const newTausch: Spermatausch = new Spermatausch();
        newTausch.aenderungstyp = Aenderungstyp.TRADE_OFF;
        newTausch.itemNumber = this.selectedBulle.itemNumber;
        newTausch.anzahl = this.tauschForm.value.anzahl;
        newTausch.auftragsdatum = this.selectedDate.date;
        newTausch.name = this.selectedBulle.name;
        newTausch.rasse = this.selectedBulle.rasse;
        newTausch.behandlungsart = this.selectedBulle.label;
        newTausch.comment = this.tauschForm.value.bemerkung ? this.tauschForm.value.bemerkung : '';
        newTausch.lotNumber = this.tauschForm.value.charge;
        newTausch.herdbookNumber = this.selectedBulle.herdbookNumber;
        newTausch.quelleTechnikerKey = this.selectedTechniker.technikerKey;
        newTausch.quelleTechnikerVorname = this.selectedTechniker.firstname;
        newTausch.quelleTechnikerNachname = this.selectedTechniker.lastname;
        this.spermatauschService.create(newTausch).subscribe(
          (tausch) => {
            this.notificationService.showSuccessNotification('TAUSCHAUSWAHL.MESSAGE.TAUSCH.SUCCESS');
            this.dialogRef.close(tausch);
          },
          (error) => {
            this.notificationService.showErrorNotification('TAUSCHAUSWAHL.MESSAGE.TAUSCH.ERROR');
            this.customErrorHandlerService.handleError(error);
          }
        );
      }
    } else {
      this.notificationService.showInfoNotification('TAUSCHAUSWAHL.MESSAGE.PFLICHTFELDER');
    }
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.searchBulleSubscription);
    Utils.unsubscribeSubscription(this.searchTechnikerSubscription);
  }

  ngOnInit() {
    this.buildForm();
    this.minDate = moment().startOf('day').subtract(7, 'days');
    this.selectedDate = new DateValidation(moment(), true);
  }

  /**
   * Stoesst die Suche eines Bullen an.
   *
   * @param value Value
   */
  searchBulle(value: FilterCriteria) {
    const searchFilterPagingResource = new SearchFilterPagingResource();
    if (value) {
      searchFilterPagingResource.orFilterCriteriaResourceList = value.getFilterCriteria();
      this.searchBulleSubscription = this.spermaartikelService.loadData(searchFilterPagingResource).subscribe(
        (result) => {
          if (result.content.length === 1) {
            this.selectedBulle = result.content[0];
            this.setBullendaten();
          } else {
            this.sucheBulle(searchFilterPagingResource.orFilterCriteriaResourceList);
          }
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    } else {
      searchFilterPagingResource.orFilterCriteriaResourceList = [];
    }
  }

  /**
   * Stoesst die Suche eines Technikers an.
   *
   * @param value Value
   */
  searchTechniker(value: FilterCriteria) {
    const searchFilterPagingResource = new SearchFilterPagingResource();
    if (value) {
      searchFilterPagingResource.orFilterCriteriaResourceList = value.getFilterCriteria();
      this.searchTechnikerSubscription = this.inseminatorService.loadData(searchFilterPagingResource).subscribe(
        (result) => {
          if (result.content.length === 1) {
            this.selectedTechniker = result.content[0];
            this.setTechnikerdaten();
          } else {
            this.sucheTechniker(searchFilterPagingResource.orFilterCriteriaResourceList);
          }
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    } else {
      searchFilterPagingResource.orFilterCriteriaResourceList = [];
    }
  }

  /**
   * Setzt die Bullendaten vom selektierten Bullen.
   */
  setBullendaten() {
    this.tauschForm.patchValue({
      selectedBulleLabel: `${this.selectedBulle.name}, ${this.spermaartPipe.transform(this.selectedBulle.label)} (${this.selectedBulle.herdbookNumber})`
    });
    if (this.selectedBulle.spermabestandTechnikerList.length !== 0) {
      this.selectableChargen = this.selectedBulle.spermabestandTechnikerList.filter((charge) => charge.amount > 0);
      if (this.selectableChargen.length === 1) {
        this.tauschForm.patchValue({ charge: this.selectableChargen[0].spermabestandTechnikerKey.lotNumber });
      }
    }
    if (this.selectedBulle.bestandAnrechtssperma === 0 && this.selectedBulle.bestandStationssperma === 0 && this.selectedBulle.bestandBetriebssperma === 0) {
      this.notificationService.showInfoNotification('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.SPERMA_BESTAND');
    }
  }

  /**
   * Setzt die Technikerdaten vom selektierten Techniker.
   */
  setTechnikerdaten() {
    this.tauschForm.patchValue({
      selectedTechnikerLabel: this.selectedTechniker.technikerKey.number + ' - ' + this.selectedTechniker.lastname + ', ' + this.selectedTechniker.firstname
    });
  }

  /**
   * Oeffnet einen Modalen Dialog, mit der Bullenliste, zum hinzufuegen eines Bullen.
   *
   * @param filterCriteriaResourceList FilterCriteriaResourceList (optional)
   */
  sucheBulle(filterCriteriaResourceList?: FilterCriteriaResource[]) {
    const bulleDialogRef = this.dialog.open(SpermaauswahlModalComponent, {
      width: '1216px',
      panelClass: 'vost-modal-dialog',
      data: {
        filterCriteriaResourceList
      }
    });

    bulleDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchBulleInput.searchField.nativeElement.value = '';
        this.selectedBulle = result;
        this.setBullendaten();
      }
    });
  }

  /**
   * Oeffnet einen Modalen Dialog, mit der Technikerliste, zum hinzufuegen eines Technikers.
   *
   * @param filterCriteriaResourceList FilterCriteriaResourceList (optional)
   */
  sucheTechniker(filterCriteriaResourceList?: FilterCriteriaResource[]) {
    const technikerDialogRef = this.dialog.open(TechnikeruebersichtModalComponent, {
      width: '1216px',
      panelClass: 'vost-modal-dialog',
      data: {
        filterCriteriaResourceList
      }
    });

    technikerDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchTechnikerInput.searchField.nativeElement.value = '';
        this.selectedTechniker = result;
        this.setTechnikerdaten();
      }
    });
  }
}
