import { FilterCriteriaResource } from './filterCriteriaResource';
import { SortResource } from './sort-resource';

export class SearchFilterPagingResource {
  pageSize: number;

  page: number;

  orFilterCriteriaResourceList: FilterCriteriaResource[] = [];

  andFilterCriteriaResourceList: FilterCriteriaResource[] = [];

  sortResourceList: SortResource[] = [];
}
