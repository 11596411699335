<div class="[ date ]">
  @if (readonly) {
    <mat-form-field>
      <mat-label>{{datePlaceholder}}</mat-label>
      <input #inputField [formControl]="dateForm" [matDatepicker]="pickerReadonly" [readonly]="true" matInput>
      <mat-datepicker-toggle disabled matSuffix>
        <i class="[ icon-baseline-event-24px ]" matDatepickerToggleIcon></i>
      </mat-datepicker-toggle>
      <mat-datepicker #pickerReadonly></mat-datepicker>
    </mat-form-field>
  } @else {
    <mat-form-field>
      <mat-label>{{datePlaceholder}}</mat-label>
      <input #inputField (dateChange)="changeDate()" (keydown)="onKeyDownDate($event)" (keyup)="onKeyUpDate($event)"
        [formControl]="dateForm" [matDatepicker]="picker" [max]="maxDate" [min]="minDate" matInput>
      @if (inputField.value) {
        <i (click)="clearDate(inputField)"
          class="search-clear">x
        </i>
      }
      <mat-datepicker-toggle [for]="picker" matSuffix>
        <i class="[ icon-baseline-event-24px ]" matDatepickerToggleIcon></i>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  }
</div>
