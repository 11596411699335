import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractServerPaginationTable } from '../../../../core/component/table/abstract-server-pagination-table';
import { FilterCriteria } from '../../../../core/filter/filterCriteria';
import { FilterCriteriaResource } from '../../../../core/filter/filterCriteriaResource';
import { FilterType } from '../../../../core/filter/filterType';
import { SortDirection } from '../../../../core/filter/sort-direction';
import { DeviceConfiguration } from '../../../../model/deviceConfiguration';
import { CustomErrorHandlerService } from '../../../../service/custom-error-handler/custom-error-handler.service';
import { DeviceConfigurationService } from '../../../../service/device-configuration/device-configuration.service';
import { ModaTranslateService } from '../../../../service/moda-translate.service';
import { ServerPaginationService } from '../../../../service/serverPaginationService';
import { SearchFieldDefinition } from '../../../search/search-field-definition';
import { DeviceConfigurationDetailComponent } from '../device-configuration-detail/device-configuration-detail.component';

@Component({
  selector: 'portal-device-configuration-uebersicht',
  templateUrl: './device-configuration-uebersicht.component.html',
  styleUrls: ['./device-configuration-uebersicht.component.scss']
})
export class DeviceConfigurationUebersichtComponent extends AbstractServerPaginationTable<DeviceConfiguration> implements OnInit {
  static DEFAULT_CONFIGURATION_FILTER: FilterCriteriaResource = new FilterCriteriaResource('defaultConfiguration', 'true', FilterType.STRING);

  sortField = [
    {
      prop: 'devicename',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  nurDefaultConfiguration = false;

  constructor(
    modaTranslateService: ModaTranslateService,
    customErrorHandlerService: CustomErrorHandlerService,
    private readonly deviceConfigurationService: DeviceConfigurationService,
    private readonly dialog: MatDialog
  ) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * {@inheritDoc}.
   */
  public doAndSearch(value?: FilterCriteria) {
    this.searchFilterPagingResource.andFilterCriteriaResourceList = [];
    if (this.nurDefaultConfiguration) {
      this.searchFilterPagingResource.andFilterCriteriaResourceList.push(DeviceConfigurationUebersichtComponent.DEFAULT_CONFIGURATION_FILTER);
    }
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen.
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [new SearchFieldDefinition('devicename', FilterType.STRING), new SearchFieldDefinition('technikername', FilterType.STRING)];
  }

  /**
   * {@inheritDoc}.
   */
  public getService(): ServerPaginationService<DeviceConfiguration> {
    return this.deviceConfigurationService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return this.sortField;
  }

  /**
   * {@inheritDoc}.
   */
  ngOnInit() {
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * {@inheritDoc}.
   */
  public rowIdentity(row: any) {
    return row.id;
  }

  /**
   * Zeigt die Details einer Device-Configuration an.
   * @param deviceConfiguration DeviceConfiguration
   */
  showDetail(deviceConfiguration: DeviceConfiguration) {
    this.dialog.open(DeviceConfigurationDetailComponent, {
      minWidth: '1000px',
      panelClass: 'vost-modal-dialog',
      data: {
        deviceConfiguration
      }
    });
  }
}
