import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Moment } from 'moment';
import { DateValidation } from '../../../model/dateValidation';
import { DateUtils } from '../../../utils/date-utils';
import { FilterCriteria } from '../../filter/filterCriteria';
import { FilterType } from '../../filter/filterType';
import { DateComponent } from '../date/date.component';

@Component({
  selector: 'portal-date-search',
  templateUrl: './date-search.component.html',
  styleUrls: ['./date-search.component.scss']
})
export class DateSearchComponent {
  @Input() dateLabel: string;

  @Input() field: string;

  @Input() interval: boolean;

  @Output() public search = new EventEmitter<FilterCriteria>();

  @Input() dateFromValue: Moment;

  @Input() dateToValue: Moment;

  @Input() takeMinDateOnClear: boolean;

  @Input() minDateFrom: Moment = DateUtils.getMinDate();

  @Input() maxDateFrom: Moment = DateUtils.getMaxDate();

  @Input() minDateTo: Moment = DateUtils.getMinDate();

  @Input() maxDateTo: Moment = DateUtils.getMaxDate();

  /**
   * Konstruktor
   */
  constructor(private adapter: DateAdapter<any>) {
    this.adapter.setLocale('de');
  }

  /**
   * Leert das From Datum
   * @param $event Event
   */
  clearFromField($event: DateComponent) {
    this.dateFromValue = this.takeMinDateOnClear ? this.minDateFrom : null;
    this.doSearch(null, true);
    $event.setDate(this.dateFromValue);
  }

  /**
   * Leert das Bis Datum
   * @param $event Event
   */
  clearToField($event: DateComponent) {
    this.dateToValue = null;
    this.doSearch(null, true);
    $event.setDate(this.dateToValue);
  }

  /**
   * Suche nach Datum
   */
  doSearch(dateValidation: DateValidation, clear?: boolean) {
    if (clear || (dateValidation && dateValidation.isValid)) {
      this.search.emit(this.createFilterCriteria(this.dateFromValue, this.dateToValue));
    }
  }

  /**
   * Erstellt Filterkriteria für FromDate to ToDate
   * @param fromValue VonDatum
   * @param toValue BisDatum
   */
  private createFilterCriteria(fromValue: Moment, toValue: Moment): FilterCriteria {
    const criteria: FilterCriteria = new FilterCriteria();
    let fromDate = null;
    let toDate = null;
    if (fromValue) {
      fromDate = fromValue.startOf('day');
    } else {
      fromDate = DateUtils.getMinDate();
    }
    if (toValue) {
      toDate = toValue.endOf('day');
    } else {
      toDate = DateUtils.getMaxDate();
    }
    criteria.addFilter(
      this.field,
      {
        from: fromDate,
        to: toDate
      },
      FilterType.STRING
    );
    return criteria;
  }
}
