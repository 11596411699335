<div class="[ c-breadcrumb ]">
  <div class="[ c-breadcrumb__text ]">
    <a routerLink="">{{ 'HEADER.TITLE' | translate }}</a>
  </div>
  @for (breadcrumb of breadcrumbs; track breadcrumb) {
    <div class="[ c-breadcrumb__text ]">
      @if (breadcrumb.label !== '') {
        <i aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]"></i>
      }
      <a [routerLink]="[breadcrumb.url, breadcrumb.params]">{{ breadcrumb.label | translate }}</a>
    </div>
  }
  <div class="[ c-breadcrumb__text margin-right-left-auto ]">
    @if (applicationInfo) {
      <label>{{ 'HEADER.VERSION' | translate }} {{ applicationInfo?.versionnummer }}</label>
    }
  </div>
</div>
