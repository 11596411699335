import { Moment } from 'moment';

export class DateValidation {
  date: Moment;

  isValid: boolean;

  constructor(date: Moment, isValid: boolean) {
    this.date = date;
    this.isValid = isValid;
  }
}
