import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractDialog } from '../../core/component/dialog/abstract-dialog';
import { FilterType } from '../../core/filter/filterType';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { Cattle } from '../../model/cattle';
import { CattleService } from '../../service/cattle/cattle.service';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { BesamungComponent } from '../auftrag/warenkorb/warenauswahl/besamung/besamung.component';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-cattle-modal',
  templateUrl: './cattle-modal.component.html',
  styleUrls: ['./cattle-modal.component.scss']
})
export class CattleModalComponent extends AbstractDialog<Cattle> implements OnInit {
  sortField = [
    {
      prop: 'name',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  @ViewChild('searchCattleField', { static: true }) searchCattleField: any;

  private betriebsschluessel;

  constructor(
    modaTranslateService: ModaTranslateService,
    customErrorHandlerService: CustomErrorHandlerService,
    private readonly cattleService: CattleService,
    private readonly dialogRef: MatDialogRef<BesamungComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData
  ) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   *  {@inheritDoc}.
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    this.loadDataSubscription = this.cattleService.loadDataWithPath(this.betriebsschluessel, searchFilterPagingResource, 'Kuh aktiv').subscribe(
      (data) => {
        this.doAfterLoadData(data);
      },
      (error) => this.customErrorHandlerService.handleError(error)
    );
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('name', FilterType.STRING),
      new SearchFieldDefinition('alphaLom', FilterType.STRING),
      new SearchFieldDefinition('status', FilterType.STRING),
      new SearchFieldDefinition('rasse.label', FilterType.STRING),
      new SearchFieldDefinition('stallNummer', FilterType.STRING),
      new SearchFieldDefinition('cattleKey.lom', FilterType.STRING)
    ];
  }

  /**
   * {@inheritDoc}
   */
  public getService(): ServerPaginationService<Cattle> {
    return this.cattleService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return [
      {
        prop: 'name',
        dir: SortDirection.ASC.frontendkuerzel
      },
      {
        prop: 'alphaLom',
        dir: SortDirection.ASC.frontendkuerzel
      }
    ];
  }

  ngOnInit() {
    this.betriebsschluessel = [this.dialogData.betriebsschluessel];
    this.initPagination();
    if (this.dialogData.filterCriteriaResourceList) {
      this.searchCattleField.searchField.nativeElement.value = this.dialogData.filterCriteriaResourceList[0].value;
      this.searchFilterPagingResource.orFilterCriteriaResourceList = this.dialogData.filterCriteriaResourceList;
    }
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   *  {@inheritDoc}.
   */
  onDoubleClick(row: Cattle) {
    this.dialogRef.close(row);
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row Auftrag
   */
  rowIdentity(row: Cattle) {
    return row.id;
  }
}
