<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'DOKUMENTE.DOKUMENTE' | translate }}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
      </div>
      <button (click)="download()" class="[ c-mat-button green-button margin-right-left-auto ]" mat-flat-button>
        <i aria-hidden="true" style="margin-right: 10px"></i>
        {{ 'BUTTON.DOWNLOAD' | translate }}
      </button>
    </div>
    <div class="[ content-table ]">
      <div class="[ c-data-list ]">
        <ngx-datatable
            #dokumentTabelle
          (page)="onPageChange($event)"
          (select)="onSelect($event)"
          [columnMode]="'force'"
          [cssClasses]="customClasses"
          [footerHeight]="57"
          [headerHeight]="57"
          [limit]="limt"
          [messages]="tableMessages"
          [rowHeight]="49"
          [rowIdentity]="rowIdentity"
          [rows]="filterdData"
          [selected]="selected"
          [selectionType]="'multiClick'"
          [sorts]="sortField"
          class="[ c-data-list__table ] "
          style="height:604px">
          <ngx-datatable-column [canAutoResize]="false" [cellClass]="'c-data-list__column-first'" [draggable]="false"
            [resizeable]="false" [sortable]="true" [width]="40">
            <ng-template let-value="value" ngx-datatable-header-template>
              <div class="[ c-data-list__checkbox-column--header ]">
                <span class="[ c-data-list__checkbox-label ]"
                  title="{{ 'DOKUMENTE.ALL' | translate }}"
                  translate="DOKUMENTE.ALL"></span>
                <input #selectAllBox (change)="selectAll($event)" type="checkbox" />
              </div>
            </ng-template>
            <ng-template
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
                let-value="value"
              ngx-datatable-cell-template>
              <input (change)="onCheckboxChangeFn($event)" [checked]="isSelected" type="checkbox" />
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="150"
            name="{{'DOKUMENTE.NAME' | translate}}" prop="filename">
          </ngx-datatable-column>
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
              <portal-table-client-pagination-footer [rows]="data"
                [selected]="selected"
                [table]="dokumentTabelle" class="[ table-footer ]">
              </portal-table-client-pagination-footer>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>