import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Cattle } from '../../model/cattle';
import { CattleKey } from '../../model/cattleKey';
import { CattleService } from '../../service/cattle/cattle.service';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { NotificationService } from '../../service/notification/notification.service';
import { DialogUtils } from '../../utils/dialog-utils';
import { Utils } from '../../utils/utils';
import { BesamungComponent } from '../auftrag/warenkorb/warenauswahl/besamung/besamung.component';

@Component({
  selector: 'portal-new-cattle',
  templateUrl: './new-cattle.component.html',
  styleUrls: ['./new-cattle.component.scss']
})
export class NewCattleComponent implements OnDestroy {
  lomForm: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]);

  name: string;

  stallnummer: string;

  createCattleSubscription: Subscription;

  checkLomSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<BesamungComponent>,
    @Inject(MAT_DIALOG_DATA) readonly dialogData,
    private readonly cattleService: CattleService,
    private readonly dialog: MatDialog,
    private readonly customErrorHandlerService: CustomErrorHandlerService,
    private readonly notificationService: NotificationService,
    private readonly modaTranslateService: ModaTranslateService
  ) {}

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Speichert das neue Tier.
   */
  hinzufuegen() {
    if (this.lomForm.hasError('required')) {
      this.notificationService.showInfoNotification('NEW_CATTLE.MESSAGE.REQUIRED');
    } else if (this.lomForm.value.length === 10 || this.lomForm.value.length === 15) {
      const cattle = new Cattle();
      if (this.lomForm.value.length === 10) {
        cattle.cattleKey = new CattleKey('27600' + this.lomForm.value, this.dialogData.betriebsschluessel);
      } else {
        cattle.cattleKey = new CattleKey(this.lomForm.value, this.dialogData.betriebsschluessel);
      }
      cattle.name = this.name;
      cattle.stallNummer = this.stallnummer;
      cattle.status = 'Kuh aktiv';
      this.checkLomSubscription = this.cattleService.checkLom(cattle).subscribe({
        next: (resultCattle) => {
          if (resultCattle) {
            this.dialogRef.close(cattle);
          } else {
            this.createCattle(cattle);
          }
        },
        error: (err) => {
          if (err.status === 409) {
            if (err.error && err.error.message && err.error.message.startsWith('E11000 duplicate key error')) {
              this.showInfoNotificationVorhandeneLom();
            } else {
              const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
                headline: this.modaTranslateService.translate('NEW_CATTLE.MESSAGE.CONFIRMATION_DIALOG.HEADLINE'),
                message: err.error.message + this.modaTranslateService.translate('NEW_CATTLE.MESSAGE.CONFIRMATION_DIALOG.QUESTION')
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.createCattle(cattle);
                }
              });
            }
          }
        }
      });
    } else {
      this.notificationService.showInfoNotification('NEW_CATTLE.MESSAGE.FALSCHEINGABE');
      this.lomForm.setErrors({ incorrect: true });
    }
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.checkLomSubscription);
    Utils.unsubscribeSubscription(this.createCattleSubscription);
  }

  /**
   * Speichert das neue Tier.
   *
   * @param cattle Cattle
   */
  private createCattle(cattle: Cattle) {
    this.createCattleSubscription = this.cattleService.create(cattle).subscribe(
      (response) => {
        this.dialogRef.close(response);
        this.notificationService.showSuccessNotification('NEW_CATTLE.MESSAGE.SPEICHERN.SUCCESS');
      },
      (error) => {
        if (error.error && error.error.message && error.error.message.startsWith('E11000 duplicate key error')) {
          this.showInfoNotificationVorhandeneLom();
        } else {
          this.customErrorHandlerService.handleError(error);
          this.notificationService.showErrorNotification('NEW_CATTLE.MESSAGE.SPEICHERN.ERROR');
        }
      }
    );
  }

  /**
   * Zeigt die Infomeldung zu einer bereits vorhandenen Lom an und resettet die Eingabe.
   */
  private showInfoNotificationVorhandeneLom() {
    this.notificationService.showInfoNotification('NEW_CATTLE.MESSAGE.VORHANDENE_LOM', {
      lom: this.lomForm.value
    });
    this.lomForm.reset();
    this.lomForm.markAsTouched();
  }
}
