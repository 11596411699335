import { AfterViewInit, Directive } from '@angular/core';
import { AbstractServerPaginationTable } from '../table/abstract-server-pagination-table';

@Directive()
export abstract class AbstractDialog<T> extends AbstractServerPaginationTable<T> implements AfterViewInit {
  ngAfterViewInit(): void {
    // Fix fuer den Tabellenbreite Bug
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);
  }
}
