import { Moment } from 'moment';
import { AuftragspositionType } from '../enumeration/auftragsposition-type.enum';
import { Auftragspositionsstatus } from '../enumeration/auftragspositionsstatus.enum';
import { OrderSaleType } from '../enumeration/order-sale-type.enum';
import { SpeciesType } from '../enumeration/species-type.enum';
import { Suchfokus } from '../enumeration/suchfokus.enum';
import { Adresse } from './adresse';
import { Besamung } from './besamung';
import { Breed } from './breed';
import { Cattle } from './cattle';
import { CattleKey } from './cattleKey';
import { Spermaartikel } from './spermaartikel';
import { TechnikerKey } from './techniker-key';

export class Auftragsposition {
  type: AuftragspositionType;

  id: string;

  auftragsnummer: string;

  bulle: Spermaartikel;

  betriebsschluessel: string;

  cattle: Cattle;

  cattleKey: CattleKey;

  alphaLom: string;

  receiptNumber: string;

  servitNumber: number;

  inseminationDate: Date;

  inseminationCount: number;

  lotNumber: string;

  ejaculateNumber: number;

  productionNumber: number;

  specialCharge: Array<string>;

  specialMark: string;

  itemNumber: string;

  externalItemNumber: string;

  comment: string;

  orderNumber: string;

  mainBranch: string;

  deviceNumber: string;

  notificationUnit: number;

  species: SpeciesType;

  specialFee: string;

  paymentMethod: number;

  farmunit: number;

  collectionTime: Moment;

  reportingTime: Moment;

  transmissionTime: Moment;

  depotInseminator: string;

  wvb: number;

  besamungsDienstleistungen: string[];

  ersteBesamung: Besamung;

  letzteBesamung: Besamung;

  bestandBetriebssperma: number;

  anrechtssperma: boolean;

  berechneSpermapreis: boolean;

  auftragsdatum: Moment;

  auftragspositionsstatus: Auftragspositionsstatus;

  // Agrarartikel Attribute
  quantity: number;

  bookingType: OrderSaleType;

  anzahlDirektverkauf: number;

  anzahlBestellung: number;

  stallnummer: string;

  rasse: Breed;

  lom: string;

  technikerKey: TechnikerKey;

  adresse: Adresse;

  suchfokus: Suchfokus;

  shortNumber: string;

  bezeichnung: string;

  externalSperm: string;
}
