import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { Agrarartikel } from '../../model/agrarartikel';
import { ServerPaginationService } from '../serverPaginationService';

@Injectable({
  providedIn: 'root'
})
export class AgrarartikelService implements ServerPaginationService<Agrarartikel> {
  private url = '/api/v1/agrarartikel';

  constructor(private http: HttpClient) {}

  /**
   * Liefert alle Vitdienstleistungen
   */
  getVitDienstleistungen(): Observable<Array<Agrarartikel>> {
    return this.http.get<Array<Agrarartikel>>(this.url + '/vitdienstleistungen');
  }

  /**
   * Liefert alle fuer den angemeldeten Benutzer sichtbaren Artikel.
   *
   * @return Observable<Array<Agrarartikel>>
   */
  loadData(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Agrarartikel>> {
    return this.http.post<Page<Agrarartikel>>(this.url + '/listPage', searchFilterPagingResource);
  }

  /**
   * Liefert alle aktiven Artikel.
   *
   * @return Observable<Array<Agrarartikel>>
   */
  loadDataAktivArtikel(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Agrarartikel>> {
    return this.http.post<Page<Agrarartikel>>(this.url + '/listPageAll', searchFilterPagingResource);
  }

  /**
   * Liefert alle VostDienstleistungen
   */
  loadTUDienstleistungen(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Agrarartikel>> {
    return this.http.post<Page<Agrarartikel>>(this.url + '/vostdienstleistungen', searchFilterPagingResource);
  }
}
