import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountFilter'
})
export class AmountFilterPipe implements PipeTransform {
  transform(value: string): string {
    return value.substring(0, 4) === '0,01' ? '' : value;
  }
}
