<div class="[ panel warenkorb ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'NAVIGATION.AUFTRAGSUEBERSICHT' | translate }}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ betriebscontainer ]">
      <div class="[ c-inline ]">
        <div class="[ c-output-container ]">
          <label for="betriebsschluessel">{{ 'WARENKORB.BETRIEB' | translate }}</label>
          <output id="betriebsschluessel">{{ betriebsbezeichnung }}</output>
        </div>
        <portal-date (dateChange)="selectedAuftragsdatum=$event; auftrag.auftragsdatum=$event.date"
          [datePlaceholder]="('WARENKORB.AUFTRAGSDATUM' | translate)"
          [dateValue]="selectedAuftragsdatum.date" class="[ date-search date margin-right-20 ]"></portal-date>
        <button (click)="onArtikelHinzufuegen()" [disabled]="!selectedAuftragsdatum.isValid"
          class="[ c-mat-button green-button ]" mat-flat-button>
          <i aria-hidden="true" class="[ icon-plus ]" style="margin-right: 10px"></i>
          {{ 'BUTTON.POSITION_HINZUFUEGEN' | translate }}
        </button>
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ c-data-list ]">
        <ngx-datatable
            #warenkorbTabelle
          (activate)="onActivate($event)"
          (select)="onSelect($event)"
          [columnMode]="'force'"
          [count]="10"
          [cssClasses]="customClasses"
          [footerHeight]="57"
          [headerHeight]="57"
          [limit]="10"
          [messages]="tableMessages"
          [rowHeight]="49"
          [rowIdentity]="rowIdentity"
          [rows]="data"
          [selected]="selected"
          [selectionType]="'single'"
          [sorts]="sortField"
          class="[ c-data-list__table ] "
          style="height:604px">
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="60" name="{{'WARENKORB.TYP' | translate}}" prop="type">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span title="{{ value +'_TITLE' | translate }}">{{ value | translate }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="90"
            prop="stallnummer">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'WARENKORB.STALLNUMMER_TITLE' | translate}}">
              {{ 'WARENKORB.STALLNUMMER' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="80" name="{{'WARENKORB.RASSE' | translate}}" prop="rasse.shortLabel">
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="170"
            name="{{'WARENKORB.LOM' | translate}}" prop="alphaLom">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'WARENKORB.LOM' | translate}}">
              {{ 'WARENKORB.LOM' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="40">
            <ng-template ngx-datatable-header-template>
              <span title="{{'WARENKORB.ANFAHRT_TITLE' | translate}}">{{ 'WARENKORB.ANFAHRT' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.BESAMUNG' &&
              row.specialCharge?.includes(besamungsdienstleistungenService.getVitArtikelnummerAnfahrt())) {
                <i
                  class="[ icon-baseline-done-24px ]"></i>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="40">
            <ng-template ngx-datatable-header-template>
              <span title="{{'WARENKORB.SPERMAPREIS_BERECHNEN_TITLE' | translate}}">
              {{ 'WARENKORB.SPERMAPREIS_BERECHNEN' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.BESAMUNG' && row.berechneSpermapreis) {
                <i
                  class="[ icon-baseline-done-24px ]"></i>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="90"
            prop="itemNumber">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'WARENKORB.ARTIKELNUMMER_TITLE' | translate}}">
              {{ 'WARENKORB.ARTIKELNUMMER' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="250"
            name="{{'WARENKORB.BEZEICHNUNG' | translate}}" prop="bezeichnung">
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [width]="80"
            prop="bulle.label">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer" title="{{'WARENKORB.BEHANDLUNGSART_TITLE' | translate}}">
              {{ 'WARENKORB.BEHANDLUNGSART' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.bulle) {
                <span
                  title="{{ row.bulle.label | spermaart }}">{{ row.bulle.label | spermaart }}</span>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [sortable]="false" [width]="100" name="{{'WARENKORB.LAGER' | translate}}" prop="anrechtssperma">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header" title="{{'WARENKORB.LAGER' | translate}}">
              {{ 'WARENKORB.LAGER' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.BESAMUNG' && row.anrechtssperma) {
                <div class="right-cell"
                  title="{{ row.shortNumber }}">{{ row.shortNumber }}
                </div>
              }
              @if (row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.BESAMUNG' && !row.anrechtssperma) {
                <div class="right-cell"
                  title="{{ technikernummer }}">{{ technikernummer }}
                </div>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="100" name="{{'WARENKORB.CHARGE' | translate}}" prop="lotNumber">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'WARENKORB.CHARGE' | translate}}">
              {{ 'WARENKORB.CHARGE' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="100" name="{{'WARENKORB.MENGE' | translate}}" prop="quantity">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'WARENKORB.MENGE' | translate}}">
              {{ 'WARENKORB.MENGE' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              @if (row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.TRAECHTIGKEITSUNTERSUCHUNG') {
                <div
                  class="[ decrementIncrementCell ]">
                  @if (row !== selectedElement
                  || row.auftragspositionsstatus !== 'ENUM.AUFTRAGSPOSITIONSSTATUS.IM_WARENKORB') {
                    <span
                      title="{{value}}">{{ value }}</span>
                  }
                  @if (row === selectedElement
                  && row.auftragspositionsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.IM_WARENKORB') {
                    <div
                    >
                      <button (click)="decrementQuantity(row)" [disabled]="row.quantity <= 0"
                        mat-icon-button>
                        <i class="[ icon-plus-circle-1 ]"></i>
                      </button>
                      <input [(ngModel)]="row.quantity"
                        [validateRegex]="'^\\d{1,3}$'">
                      <button (click)="incrementQuantity(row)"
                        [disabled]="row.quantity >= 999"
                        mat-icon-button>
                        <i class="[ icon-plus-circle ]"></i>
                      </button>
                    </div>
                  }
                </div>
              }
              @if (row.type !== 'ENUM.AUFTRAGSPOSITION_TYPE.TRAECHTIGKEITSUNTERSUCHUNG') {
                <div class="right-cell">
                  {{ row.quantity }}
                </div>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [minWidth]="110" [sortable]="false" [width]="110" prop="anzahlDirektverkauf">
            <ng-template ngx-datatable-header-template>
              <span class="center-cell-header"
                title="{{'WARENKORB.ANZAHL_D_TITLE' | translate}}">{{ 'WARENKORB.ANZAHL_D' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              @if (row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.AGRARARTIKEL') {
                <div
                  class="[ decrementIncrementCell ]">
                  @if (row !== selectedElement
                  || row.auftragspositionsstatus !== 'ENUM.AUFTRAGSPOSITIONSSTATUS.IM_WARENKORB') {
                    <div
                      class="right-cell"
                      title="{{value}}">{{ value }}
                    </div>
                  }
                  @if (row === selectedElement
                  && row.auftragspositionsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.IM_WARENKORB') {
                    <div
                    >
                      <button (click)="decrementAnzahl(row, true)" [disabled]="row.anzahlDirektverkauf <= 0"
                        mat-icon-button>
                        <i class="[ icon-plus-circle-1 ]"></i>
                      </button>
                      <input (blur)="setAnzahl($event.srcElement.value, row, true)"
                        [(ngModel)]="row.anzahlDirektverkauf"
                        [validateRegex]="'^\\d{1,3}$'">
                      <button (click)="incrementAnzahl(row, true)"
                        [disabled]="row.anzahlDirektverkauf >= 999"
                        mat-icon-button>
                        <i class="[ icon-plus-circle ]"></i>
                      </button>
                    </div>
                  }
                </div>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [minWidth]="110" [sortable]="false" [width]="110" name="{{'WARENKORB.ANZAHL_B' | translate}}"
            prop="anzahlBestellung">
            <ng-template ngx-datatable-header-template>
              <span class="center-cell-header"
                title="{{'WARENKORB.ANZAHL_B_TITLE' | translate}}">{{ 'WARENKORB.ANZAHL_B' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              @if (row.type === 'ENUM.AUFTRAGSPOSITION_TYPE.AGRARARTIKEL') {
                <div
                  class="[ decrementIncrementCell ]">
                  @if (row !== selectedElement
                  || row.auftragspositionsstatus !== 'ENUM.AUFTRAGSPOSITIONSSTATUS.IM_WARENKORB') {
                    <span
                      title="{{value}}">{{ value }}</span>
                  }
                  @if (row === selectedElement
                  && row.auftragspositionsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.IM_WARENKORB') {
                    <div
                    >
                      <button (click)="decrementAnzahl(row, false)"
                        [disabled]="row.anzahlBestellung <= 0" mat-icon-button>
                        <i class="[ icon-plus-circle-1 ]"></i>
                      </button>
                      <input (blur)="setAnzahl($event.srcElement.value, row, false)"
                        [(ngModel)]="row.anzahlBestellung"
                        [validateRegex]="'^\\d{1,3}$'">
                      <button (click)="incrementAnzahl(row, false)"
                        [disabled]="row.anzahlBestellung >= 999"
                        mat-icon-button>
                        <i class="[ icon-plus-circle ]"></i>
                      </button>
                    </div>
                  }
                </div>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
            [width]="70" name="">
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.auftragspositionsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.ERSTELLT'
              || row.auftragspositionsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.IM_WARENKORB') {
                <i (click)="showPositions(row)"
                  aria-hidden="true"
                  class="[ icon-baseline-create-24px action-icon ]"
                  style="margin-right: 10px"></i>
              }
              @if (row.auftragspositionsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.ERSTELLT'
              || row.auftragspositionsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.IM_WARENKORB') {
                <i (click)="onDeletePosition(row)"
                  aria-hidden="true"
                  class="[ icon-baseline-delete-24px action-icon ]"></i>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
              <portal-table-client-pagination-footer #paginationFooter
                [rows]="data" [selected]="selected" [table]="warenkorbTabelle"
                class="[ table-footer ]"></portal-table-client-pagination-footer>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
    <div class="[ form-content ]">
      <button (click)="onZurueck()" class="[ c-mat-button green-button margin-right-20 ]" mat-button>
        <i aria-hidden="true" class="[ icon-baseline-chevron_left-24px ]"></i>
        {{ 'BUTTON.BETRIEBSAUSWAHL' | translate }}
      </button>
      <button (click)="onAlleLoeschen()" [disabled]="auftrag?.positionen?.length === 0"
        class="[ c-mat-button no-color-button ]"
        mat-button>
        <i aria-hidden="true" class="[ icon-baseline-delete-24px ]" style="font-size: 24px"></i>
        {{ 'BUTTON.ALLE_LOESCHEN' | translate }}
      </button>
      <button (click)="onAbbrechen()" class="[ c-mat-button no-color-button right-button margin-right-20 ]" mat-button>
        <i aria-hidden="true"></i>
        {{ 'BUTTON.ABBRECHEN' | translate }}
      </button>
      <button (click)="onBestaetigen()" class="[ c-mat-button green-button ]" mat-flat-button>
        <i aria-hidden="true" class="[ icon-baseline-done-24px ]" style="font-size: 24px"></i>
        {{ 'BUTTON.BESTAETIGEN' | translate }}
      </button>
    </div>
  </div>
</div>
