<div class="[ panel farmuebersicht ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'NEUER_AUFTRAG.BETRIEB_AUSWAEHLEN' | translate }}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
        @if (!selectionOnlyMode && !this.leistungsprotokollLandwirt && !this.editAuftrag) {
          <portal-date (dateChange)="selectedAuftragsdatum=$event" (dateClear)="selectedAuftragsdatum.date=null"
            [datePlaceholder]="('NEUER_AUFTRAG.AUFTRAGSDATUM' | translate)"
            [dateValue]="selectedAuftragsdatum.date" [required]="true"
            class="[ date-search margin-right-left-auto ]"></portal-date>
        }
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]">
          <ngx-datatable
              #betriebsTabelle
            (activate)="onActivate($event)"
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ] "
            style="height:604px">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="90" name="{{'NEUER_AUFTRAG.KURZNUMMER' | translate}}" prop="oldShortNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'NEUER_AUFTRAG.KURZNUMMER' | translate}}">
                {{ 'NEUER_AUFTRAG.KURZNUMMER' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="10" name="{{'NEUER_AUFTRAG.BETRIEBSSTAETTE' | translate}}" prop="betriebsschluessel">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [width]="20" name="{{'NEUER_AUFTRAG.NAME' | translate}}" prop="name">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span title="{{ row.adresse.nachname}}, {{ row.adresse.vorname }}">
                  {{ row.adresse.nachname }}, {{ row.adresse.vorname }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="100" name="{{'NEUER_AUFTRAG.ADRESSE' | translate}}" prop="adresse">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span
                  title="{{ row.adresse.strasseHausnummer}}, {{ row.adresse.zip }} {{ row.adresse.ort}}, {{row.adresse.ortsteil}}">
                  {{ row.adresse.strasseHausnummer }}, {{ row.adresse.zip }} {{ row.adresse.ort }}
                  , {{ row.adresse.ortsteil }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
              [width]="40" name="{{'FARMCATEGORY.TITLE' | translate}}" prop="farmCategory">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span title="{{'FARMCATEGORY.ART-' + row.farmCategory |translate: { default: 'unbekannt' } }}">
                  {{ 'FARMCATEGORY.ART-' + row.farmCategory |translate: { default: 'unbekannt' } }}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="100" name="{{'NEUER_AUFTRAG.STAMMBESAMUNGSTECHNIKER' | translate}}" prop="stammtechnikername">
            </ngx-datatable-column>
            @if (!selectionOnlyMode) {
              <ngx-datatable-column [draggable]="false"
                [headerClass]="'c-data-list__header--default'" [maxWidth]="50"
                [sortable]="false" name="" prop="id">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <i (click)="showBetriebDetails(row)" aria-hidden="true" class="[ icon-ic_inspect icon-btn ]"></i>
                </ng-template>
              </ngx-datatable-column>
            }
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="betriebsTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    @if (!this.editAuftrag && !this.leistungsprotokollLandwirt) {
      <div [hidden]="true" class="[ buttons-right ]">
        @if (!selectionOnlyMode) {
          <button (click)="abbrechen($event)"
            class="[ c-mat-button margin-right-20 no-color-button ]" mat-button>
            <i aria-hidden="true"></i>
            {{ 'BUTTON.ABBRECHEN' | translate }}
          </button>
        }
        <button (click)="weiter()"
          [disabled]="!(selected.length && selectedAuftragsdatum.isValid) || selectedAuftragsdatum.date === null"
          class="[ c-mat-button green-button ]"
          mat-flat-button>
          {{ 'BUTTON.WEITER' | translate }}
          <i aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]" style="margin-left: 10px"></i>
        </button>
      </div>
    }
    @if (this.leistungsprotokollLandwirt) {
      <div class="[ buttons-right ]">
        <button (click)="weiter()"
          [disabled]="!selected.length" class="[ c-mat-button green-button ]"
          mat-flat-button>{{ 'BUTTON.WEITER' | translate }}
          <i aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]" style="margin-left: 10px"></i>
        </button>
      </div>
    }
  </div>
</div>
