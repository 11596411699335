import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AbstractClientPaginationTable } from '../../../../core/component/table/abstract-client-pagination-table';
import { FilterType } from '../../../../core/filter/filterType';
import { Job } from '../../../../model/job';
import { ClientPaginationService } from '../../../../service/client-pagination-service';
import { CustomErrorHandlerService } from '../../../../service/custom-error-handler/custom-error-handler.service';
import { Jobervice } from '../../../../service/job/job.service';
import { ModaTranslateService } from '../../../../service/moda-translate.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { DialogUtils } from '../../../../utils/dialog-utils';
import { Utils } from '../../../../utils/utils';
import { SearchFieldDefinition } from '../../../search/search-field-definition';
import { JobDetailComponent } from '../job-detail/job-detail.component';

@Component({
  selector: 'portal-jobuebersicht-component',
  templateUrl: './jobuebersicht.component.html',
  styleUrls: ['./jobuebersicht.component.scss']
})
export class JobuebersichtComponent extends AbstractClientPaginationTable<Job> implements OnInit, OnDestroy {
  sortField = [
    {
      prop: 'jobType',
      dir: 'asc'
    }
  ];

  private stopJobSubscription: Subscription;

  private startJobSubscription: Subscription;

  private stopAllJobSubscription: Subscription;

  private startAllJobSubscription: Subscription;

  private startJobDialogSubscription: Subscription;

  private stopJobDialogSubscription: Subscription;

  constructor(
    customErrorHandlerService: CustomErrorHandlerService,
    modaTranslateService: ModaTranslateService,
    private readonly jobService: Jobervice,
    private readonly notificationService: NotificationService,
    private readonly dialog: MatDialog
  ) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Aktualisiert die Job-Cron-Expression.
   * @param selectedRow Job
   */
  changeCronExpression(selectedRow: Job) {
    this.dialog.open(JobDetailComponent, {
      minWidth: '900px',
      panelClass: 'vost-modal-dialog',
      data: {
        job: selectedRow
      }
    });
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen.
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [new SearchFieldDefinition('jobType', FilterType.ENUM)];
  }

  /**
   * {@inheritDoc}
   */
  public getService(): ClientPaginationService<Job> {
    return this.jobService;
  }

  ngOnDestroy(): void {
    Utils.unsubscribeSubscription(this.startJobSubscription);
    Utils.unsubscribeSubscription(this.startAllJobSubscription);
    Utils.unsubscribeSubscription(this.stopJobSubscription);
    Utils.unsubscribeSubscription(this.stopAllJobSubscription);
    Utils.unsubscribeSubscription(this.startJobDialogSubscription);
    Utils.unsubscribeSubscription(this.stopJobDialogSubscription);
  }

  ngOnInit() {
    this.loadData();
  }

  /**
   * {@inheritDoc}
   */
  rowIdentity(row: Job) {
    return row.jobType;
  }

  /**
   * Startet alle Jobs.
   *
   */
  startAllJobs() {
    this.startAllJobSubscription = this.jobService.startAllJobs().subscribe(
      () => {
        this.loadData();
        this.notificationService.showSuccessNotification('JOBUEBERSICHT.MESSAGE.START_ALL.SUCCESS');
      },
      (error) => {
        this.notificationService.showErrorNotification('JOBUEBERSICHT.MESSAGE.START_ALL.ERROR');
        this.customErrorHandlerService.handleError(error);
      }
    );
  }

  /**
   * Startet einen Job.
   *
   * @param selectedRow Job
   *
   */
  startJob(selectedRow: Job) {
    const jobname = this.modaTranslateService.translate(selectedRow.jobType);
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('JOBUEBERSICHT.MESSAGE.START.CONFIRMATION_DIALOG.HEADLINE', { jobname }),
      message: this.modaTranslateService.translate('JOBUEBERSICHT.MESSAGE.START.CONFIRMATION_DIALOG.MESSAGE', { jobname })
    });

    this.startJobDialogSubscription = dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.startJobSubscription = this.jobService.startJob(selectedRow).subscribe(
            () => {
              this.loadData();
              this.notificationService.showSuccessNotification('JOBUEBERSICHT.MESSAGE.START.SUCCESS');
            },
            (error) => {
              this.notificationService.showErrorNotification('JOBUEBERSICHT.MESSAGE.START.ERROR');
              this.customErrorHandlerService.handleError(error);
            }
          );
        }
      },
      (error) => {
        this.customErrorHandlerService.handleError(error);
      }
    );
  }

  /**
   * Starten einen Job sofort.
   *
   * @param selectedRow Job
   *
   */
  startJobImmediate(selectedRow: Job) {
    const jobname = this.modaTranslateService.translate(selectedRow.jobType);
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('JOBUEBERSICHT.MESSAGE.START_IMMEDIATE.CONFIRMATION_DIALOG.HEADLINE', { jobname }),
      message: this.modaTranslateService.translate('JOBUEBERSICHT.MESSAGE.START_IMMEDIATE.CONFIRMATION_DIALOG.MESSAGE', { jobname })
    });

    this.startJobDialogSubscription = dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.startJobSubscription = this.jobService.startJobImmediate(selectedRow).subscribe(
            () => {
              this.loadData();
              this.notificationService.showSuccessNotification('JOBUEBERSICHT.MESSAGE.START_IMMEDIATE.SUCCESS');
            },
            (error) => {
              this.notificationService.showErrorNotification('JOBUEBERSICHT.MESSAGE.START_IMMEDIATE.ERROR');
              this.customErrorHandlerService.handleError(error);
            }
          );
        }
      },
      (error) => {
        this.customErrorHandlerService.handleError(error);
      }
    );
  }

  /**
   * Stoppt alle Jobs.
   *
   */
  stopAllJobs() {
    this.stopAllJobSubscription = this.jobService.stopAllJobs().subscribe(
      () => {
        this.loadData();
        this.notificationService.showSuccessNotification('JOBUEBERSICHT.MESSAGE.STOP_ALL.SUCCESS');
      },
      (error) => {
        this.notificationService.showErrorNotification('JOBUEBERSICHT.MESSAGE.STOP_ALL.ERROR');
        this.customErrorHandlerService.handleError(error);
      }
    );
  }

  /**
   * Stoppt einen Job.
   *
   * @param selectedRow Job
   *
   */
  stopJob(selectedRow: Job) {
    const jobname = this.modaTranslateService.translate(selectedRow.jobType);
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('JOBUEBERSICHT.MESSAGE.STOP.CONFIRMATION_DIALOG.HEADLINE', { jobname }),
      message: this.modaTranslateService.translate('JOBUEBERSICHT.MESSAGE.STOP.CONFIRMATION_DIALOG.MESSAGE', { jobname })
    });

    this.stopJobDialogSubscription = dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.startJobSubscription = this.jobService.stopJob(selectedRow).subscribe(
            () => {
              this.loadData();
              this.notificationService.showSuccessNotification('JOBUEBERSICHT.MESSAGE.STOP.SUCCESS');
            },
            (error) => {
              this.notificationService.showErrorNotification('JOBUEBERSICHT.MESSAGE.STOP.ERROR');
              this.customErrorHandlerService.handleError(error);
            }
          );
        }
      },
      (error) => {
        this.customErrorHandlerService.handleError(error);
      }
    );
  }
}
