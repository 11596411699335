import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumKeys'
})
export class EnumKeysPipe implements PipeTransform {
  transform(data: object) {
    const keys = Object.keys(data);
    return keys;
  }
}
