import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { FilterCriteria } from '../../../core/filter/filterCriteria';
import { FilterCriteriaResource } from '../../../core/filter/filterCriteriaResource';
import { FilterType } from '../../../core/filter/filterType';
import { SearchFilterPagingResource } from '../../../core/filter/searchFilterPagingResource';
import { SpermaartPipe } from '../../../core/pipe/spermaart.pipe';
import { Aenderungstyp } from '../../../enumeration/aenderungstyp.enum';
import { DateValidation } from '../../../model/dateValidation';
import { Farm } from '../../../model/farm';
import { FarmInfo } from '../../../model/farmInfo';
import { Spermaartikel } from '../../../model/spermaartikel';
import { SpermabestandTechniker } from '../../../model/spermabestand-techniker';
import { Spermatausch } from '../../../model/spermatausch';
import { Verlustgrund } from '../../../model/Verlustgrund';
import { CustomErrorHandlerService } from '../../../service/custom-error-handler/custom-error-handler.service';
import { FarmService } from '../../../service/farm/farm.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { SpermaartikelService } from '../../../service/spermaartikel/spermaartikel.service';
import { SpermatauschService } from '../../../service/spermatausch/spermatausch.service';
import { Utils } from '../../../utils/utils';
import { FarmuebersichtModalComponent } from '../../farmuebersicht-modal/farmuebersicht-modal.component';
import { SearchFieldDefinition } from '../../search/search-field-definition';
import { SearchComponent } from '../../search/search.component';
import { SpermaauswahlModalComponent } from '../../spermaauswahl-modal/spermaauswahl-modal.component';
import { VerlustuebersichtComponent } from '../verlustuebersicht.component';

@Component({
  selector: 'portal-verlustauswahl',
  templateUrl: './verlustauswahl.component.html',
  styleUrls: ['./verlustauswahl.component.scss']
})
export class VerlustauswahlComponent implements OnInit, OnDestroy {
  selectableChargen: Array<SpermabestandTechniker>;

  selectedBulle: Spermaartikel;

  verlustForm: UntypedFormGroup;

  verlustgruende: Array<Verlustgrund>;

  searchBulleSubscription: Subscription;

  searchBetriebSubscription: Subscription;

  selectedDate: DateValidation;

  selectedBetrieb: Farm;

  @ViewChild('searchBulleInput') searchBulleInput: SearchComponent;

  @ViewChild('searchBetriebInput') searchBetriebInput: SearchComponent;

  constructor(
    public dialogRef: MatDialogRef<VerlustuebersichtComponent>,
    @Inject(MAT_DIALOG_DATA) readonly dialogData,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService,
    private readonly spermaartPipe: SpermaartPipe,
    private readonly form: UntypedFormBuilder,
    private readonly spermatauschService: SpermatauschService,
    private readonly customErrorHandlerService: CustomErrorHandlerService,
    private readonly spermaartikelService: SpermaartikelService,
    private readonly farmService: FarmService
  ) {}

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Funktion um das Validierungsformular zu bauen und in welcher Form die Validierung stattfindet.
   * Es muessen alle Felder die angegeben werden, welche das formControlName Attribute aufweisen.
   * Zusaetzlich wird hier bestimmt mit welchem Event (blur, change oder submit) die Validierung ausgeführt wird.
   */
  buildForm() {
    this.verlustForm = this.form.group({
      selectedBulleLabel: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ),
      charge: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [Validators.required, Validators.minLength(4)],
          updateOn: 'change'
        }
      ),
      verlustgrund: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [Validators.required],
          updateOn: 'change'
        }
      ),
      anzahl: new UntypedFormControl(
        {
          value: 1,
          disabled: false
        },
        {
          validators: [Validators.required, Validators.min(1)],
          updateOn: 'blur'
        }
      ),
      selectedBetriebLabel: new UntypedFormControl()
    });
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldDefinitionsBetrieb(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('oldShortNumber', FilterType.STRING),
      new SearchFieldDefinition('vorname', FilterType.STRING),
      new SearchFieldDefinition('nachname', FilterType.STRING),
      new SearchFieldDefinition('strasseHausnummer', FilterType.STRING),
      new SearchFieldDefinition('companyNumber', FilterType.STRING),
      new SearchFieldDefinition('shortNumber', FilterType.STRING)
    ];
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldDefinitionsBulle(): SearchFieldDefinition[] {
    return [new SearchFieldDefinition('name', FilterType.STRING), new SearchFieldDefinition('herdbookNumber', FilterType.STRING)];
  }

  /**
   * Schliesst den Modalen Dialog und uebergibt den Verlust.
   */
  hinzufuegen() {
    if (this.verlustForm.valid) {
      if (+this.verlustForm.value.charge === 0) {
        this.notificationService.showInfoNotification('VERLUSTAUSWAHL.MESSAGE.NULL_CHARGE');
        this.verlustForm.controls.charge.setErrors({ incorrect: true });
      } else {
        const newVerlust: Spermatausch = new Spermatausch();
        newVerlust.aenderungstyp = Aenderungstyp.LOSS;
        newVerlust.itemNumber = this.selectedBulle.itemNumber;
        newVerlust.anzahl = this.verlustForm.value.anzahl;
        newVerlust.auftragsdatum = this.selectedDate.date;
        newVerlust.name = this.selectedBulle.name;
        newVerlust.rasse = this.selectedBulle.rasse;
        newVerlust.behandlungsart = this.selectedBulle.label;
        newVerlust.comment = this.verlustForm.value.verlustgrund.name;
        newVerlust.lotNumber = this.verlustForm.value.charge;
        newVerlust.herdbookNumber = this.selectedBulle.herdbookNumber;
        // Anrechtspermaverlust
        if (this.selectedBetrieb) {
          const farmInfo: FarmInfo = new FarmInfo();
          farmInfo.adresse = this.selectedBetrieb.adresse;
          farmInfo.betriebsschluessel = this.selectedBetrieb.betriebsschluessel;
          farmInfo.farmCategory = this.selectedBetrieb.farmCategory;
          farmInfo.oldShortNumber = this.selectedBetrieb.oldShortNumber;
          farmInfo.shortNumber = this.selectedBetrieb.shortNumber;
          farmInfo.stammtechnikername = this.selectedBetrieb.stammtechnikername;
          newVerlust.farmInfo = farmInfo;
        }
        this.spermatauschService.create(newVerlust).subscribe(
          (verlust) => {
            this.notificationService.showSuccessNotification('VERLUSTAUSWAHL.MESSAGE.VERLUST.SUCCESS');
            this.dialogRef.close(verlust);
          },
          (error) => {
            this.notificationService.showErrorNotification('VERLUSTAUSWAHL.MESSAGE.VERLUST.ERROR');
            this.customErrorHandlerService.handleError(error);
          }
        );
      }
    } else {
      this.notificationService.showInfoNotification('VERLUSTAUSWAHL.MESSAGE.PFLICHTFELDER');
    }
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.searchBulleSubscription);
    Utils.unsubscribeSubscription(this.searchBetriebSubscription);
  }

  ngOnInit() {
    this.spermatauschService.findAllVerlustgruende().subscribe(
      (gruende) => {
        this.verlustgruende = gruende;
      },
      (error) => this.customErrorHandlerService.handleError(error)
    );
    this.buildForm();
    this.selectedDate = new DateValidation(moment(), true);
  }

  /**
   * Stoesst die Suche eines Betriebes an.
   *
   * @param value Value
   */
  searchBetrieb(value: FilterCriteria) {
    const searchFilterPagingResource = new SearchFilterPagingResource();
    if (value) {
      searchFilterPagingResource.orFilterCriteriaResourceList = value.getFilterCriteria();
      this.searchBulleSubscription = this.farmService.loadDataForUser(searchFilterPagingResource).subscribe(
        (result) => {
          if (result.content.length === 1) {
            this.selectedBetrieb = result.content[0];
            this.setBetriebsdaten();
          } else {
            this.sucheBetrieb(searchFilterPagingResource.orFilterCriteriaResourceList);
          }
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    } else {
      searchFilterPagingResource.orFilterCriteriaResourceList = [];
    }
  }

  /**
   * Stoesst die Suche eines Bullen an.
   *
   * @param value Value
   */
  searchBulle(value: FilterCriteria) {
    const searchFilterPagingResource = new SearchFilterPagingResource();
    if (value) {
      searchFilterPagingResource.orFilterCriteriaResourceList = value.getFilterCriteria();
      this.searchBulleSubscription = this.spermaartikelService.loadData(searchFilterPagingResource).subscribe(
        (result) => {
          if (result.content.length === 1) {
            this.selectedBulle = result.content[0];
            this.setBullendaten();
          } else {
            this.sucheBulle(searchFilterPagingResource.orFilterCriteriaResourceList);
          }
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    } else {
      searchFilterPagingResource.orFilterCriteriaResourceList = [];
    }
  }

  /**
   * Setzt die Bullendaten vom selektierten Bullen.
   */
  setBetriebsdaten() {
    const name =
      (this.selectedBetrieb.adresse.nachname ? this.selectedBetrieb.adresse.nachname : '') +
      (this.selectedBetrieb.adresse.vorname ? ', ' + this.selectedBetrieb.adresse.vorname : '');
    const adresse = this.selectedBetrieb.adresse.strasseHausnummer + ', ' + this.selectedBetrieb.adresse.ort;
    this.verlustForm.patchValue({
      selectedBetriebLabel: this.selectedBetrieb.oldShortNumber + ' - ' + name + ' - ' + adresse
    });
  }

  /**
   * Setzt die Bullendaten vom selektierten Bullen.
   */
  setBullendaten() {
    this.verlustForm.patchValue({ charge: '' });
    this.verlustForm.patchValue({
      selectedBulleLabel: `${this.selectedBulle.name}, ${this.spermaartPipe.transform(this.selectedBulle.label)} (${this.selectedBulle.herdbookNumber})`
    });
    if (this.selectedBulle.spermabestandTechnikerList.length !== 0) {
      this.selectableChargen = this.selectedBulle.spermabestandTechnikerList.filter(
        (charge) => charge.amount > 0 && !isNaN(+charge.spermabestandTechnikerKey.lotNumber)
      );
      if (this.selectableChargen.length === 1) {
        this.verlustForm.patchValue({ charge: this.selectableChargen[0].spermabestandTechnikerKey.lotNumber });
      }
    }
    if (this.selectedBulle.bestandAnrechtssperma === 0 && this.selectedBulle.bestandStationssperma === 0 && this.selectedBulle.bestandBetriebssperma === 0) {
      this.notificationService.showInfoNotification('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.SPERMA_BESTAND');
    }
  }

  /**
   * Oeffnet einen Modalen Dialog, mit der Betriebsliste, zum hinzufuegen eines Betriebes.
   *
   * @param filterCriteriaResourceList FilterCriteriaResourceList (optional)
   */
  sucheBetrieb(filterCriteriaResourceList?: FilterCriteriaResource[]) {
    const betriebDialogRef = this.dialog.open(FarmuebersichtModalComponent, {
      width: '1216px',
      panelClass: 'vost-modal-dialog',
      data: {
        filterCriteriaResourceList
      }
    });

    betriebDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchBetriebInput.searchField.nativeElement.value = '';
        this.selectedBetrieb = result;
        this.setBetriebsdaten();
      }
    });
  }

  /**
   * Oeffnet einen Modalen Dialog, mit der Bullenliste, zum hinzufuegen eines Bullen.
   *
   * @param filterCriteriaResourceList FilterCriteriaResourceList (optional)
   */
  sucheBulle(filterCriteriaResourceList?: FilterCriteriaResource[]) {
    const bulleDialogRef = this.dialog.open(SpermaauswahlModalComponent, {
      width: '1216px',
      panelClass: 'vost-modal-dialog',
      data: {
        filterCriteriaResourceList
      }
    });

    bulleDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchBulleInput.searchField.nativeElement.value = '';
        this.selectedBulle = result;
        this.setBullendaten();
      }
    });
  }
}
