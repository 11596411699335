import { Directive, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServerPaginationService } from '../../../service/serverPaginationService';
import { SortUtils } from '../../../utils/sort-utils';
import { Utils } from '../../../utils/utils';
import { FilterCriteria } from '../../filter/filterCriteria';
import { FilterCriteriaResource } from '../../filter/filterCriteriaResource';
import { Page } from '../../filter/page';
import { SearchFilterPagingResource } from '../../filter/searchFilterPagingResource';
import { AbstractTable } from './abstract-table';
import { TableServerPaginationFooterComponent } from './footer/table-server-pagination-footer/table-server-pagination-footer.component';

@Directive()
export abstract class AbstractServerPaginationTable<T> extends AbstractTable {
  pageSize = 10;

  currentPage: Page<T>;

  searchFilterPagingResource: SearchFilterPagingResource = new SearchFilterPagingResource();

  tableIsLoading = true;

  data: Array<T> = [];

  selected: Array<T> = [];

  selectedElement: T;

  loadDataSubscription: Subscription;

  selectAllOnPage: any[] = [];

  @ViewChild(TableServerPaginationFooterComponent) footer: TableServerPaginationFooterComponent;

  /**
   * Fuehrt die Berechnung der Attribute, die nicht aus dem Backend kommen, aber in der View angezeigt werden sollen.
   *
   * @param page Page
   */
  calculateData(page: Page<T>) {}

  /**
   * Vergleicht zwei Entity, ob die gleich sind oder nicht.
   * @param item1 Entity
   * @param item2 Entity
   */
  compareEntity(item1: T, item2: T) {}

  /**
   * Erstelle die Filter-Kriterien.
   */
  createFilterCriteriaResources(): FilterCriteriaResource[] {
    return [];
  }

  /**
   * Gibt das Sort-Field zurueck.
   *
   * @param event Event
   */
  determineSortField(event): string {
    return event.column.prop;
  }

  /**
   * Updatet die Tabelle.
   *
   * @param data PageData
   */
  doAfterLoadData(data: Page<T>) {
    this.onSelect(null);
    this.calculateData(data);
    this.updatePage(data);
    this.tableIsLoading = false;
  }

  /**
   * Fuehrt die Aktionwn nach dem Selektion..
   *
   * @param selectedRow Selected Row
   */
  doAfterSelect(selectedRow: T) {}

  /**
   * And-Search mit dem eingegebenen Filter.
   * @param value Value
   */
  public doAndSearch(value?: FilterCriteria) {
    this.searchFilterPagingResource.andFilterCriteriaResourceList = [];
    if (value) {
      this.searchFilterPagingResource.andFilterCriteriaResourceList = value.getFilterCriteria();
    }
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   *  Laedt die Daten.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    this.loadDataSubscription = this.getService()
      .loadData(searchFilterPagingResource)
      .subscribe(
        (data) => {
          this.doAfterLoadData(data);
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
  }

  /**
   * Or-Search mit dem eingegebenen Filter.
   * @param value Filtercriteria
   */
  public doOrSearch(value: FilterCriteria) {
    this.searchFilterPagingResource.orFilterCriteriaResourceList = [];
    if (value) {
      this.searchFilterPagingResource.orFilterCriteriaResourceList = value.getFilterCriteria();
    }
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Selektiert eine Zeile.
   *
   * @param selectedElement Selektierte Zeile
   */
  doSelect(selectedElement: T) {
    // Make sure we are no longer selecting all
    this.selectAllOnPage[this.currentPage.number] = false;
    this.selectedElement = selectedElement;
    this.selected = [];
    this.selected.push(selectedElement);
  }

  /**
   * Unsubscribe.
   */
  doUnsubscribe() {
    Utils.unsubscribeSubscription(this.loadDataSubscription);
    this.unsubscribeMessageSubscription();
  }

  /**
   * Liefert den Service, der die Daten ermittelt.
   */
  abstract getService(): ServerPaginationService<T>;

  /**
   * Liefert die Sort-Fields zurueck.
   */
  abstract getSortFields(): any;

  /**
   * Initialisiert die Pagination.
   */
  initPagination() {
    this.currentPage = new Page<T>();
    this.currentPage.number = 0;
    this.currentPage.numberOfElements = 0;
    this.currentPage.size = this.pageSize;

    this.searchFilterPagingResource = new SearchFilterPagingResource();
    this.searchFilterPagingResource.orFilterCriteriaResourceList = [];
    this.searchFilterPagingResource.page = 0;
    this.searchFilterPagingResource.pageSize = this.pageSize;
  }

  /**
   * Laedt die Daten.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   */
  loadData(searchFilterPagingResource: SearchFilterPagingResource) {
    this.tableIsLoading = true;
    this.doLoadData(searchFilterPagingResource);
  }

  /**
   * Aktiviert bzw. Selektiert die Zeile.
   *
   * @param event Event
   */
  onActivate(event) {
    if (event.type === 'dblclick') {
      this.onSelect({ selected: [event.row] });
      this.onDoubleClick(event.row);
    }
  }

  /**
   * Aktiviert bzw. Selektiert die Zeile bei Doppelklick.
   * Diese Methode ueberschreiben und (activate)="onActivate($event)" zu ngx-datatable hinzufuegen.
   *
   * @param row Selektierte Zeile
   */
  onDoubleClick(row: T) {
    console.error('Doppelklick is not implemented!');
  }

  /**
   * Die selektierte Tabellenzeile wird zum Detail-Objekt.
   *
   * @param event Tabellenzeile
   */
  onSelect(event) {
    if (event == null) {
      this.selected = [];
      this.selectedElement = null;
    } else {
      const element = this.selected[0];
      this.doSelect(element);
      this.doAfterSelect(element);
    }
  }

  /**
   * Die Liste wird mit den neuen Sortierkriterium geladen.
   *
   * @param event Event
   */
  onSort(event) {
    const sortField = [
      {
        prop: this.determineSortField(event),
        dir: event.newValue
      }
    ];
    SortUtils.addToSortResourceList(this.searchFilterPagingResource.sortResourceList, sortField, true);

    this.searchFilterPagingResource.page = 0;
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row selektierte Zeile
   */
  abstract rowIdentity(row: any);

  /**
   * Die Methode stellt die Selektion der aktuellen Seite wieder her.
   */
  selectAll() {
    if (!this.selectAllOnPage[this.currentPage.number]) {
      // Unselect all so we dont get duplicates.
      if (this.selected.length > 0) {
        this.data.forEach((item) => {
          this.selected = this.selected.filter((selected) => this.compareEntity(selected, item));
        });
      }
      // Select all again
      this.selected.push(...this.data);
      this.selectAllOnPage[this.currentPage.number] = true;
    } else {
      // Unselect all
      this.data.forEach((item) => {
        this.selected = this.selected.filter((selected) => this.compareEntity(selected, item));
      });
      this.selectAllOnPage[this.currentPage.number] = false;
    }

    this.data = [...this.data];
  }

  /**
   * Setzt die Standardsortierung fuer die Uebersicht nach der gewaehlten Criteria und der SortDirection.
   */
  setDefaultSortCriteria() {
    SortUtils.addToSortResourceList(this.searchFilterPagingResource.sortResourceList, this.getSortFields());
  }

  /**
   * Die Liste wird mit den neuen Page geladen.
   *
   * @param pageInfo PageInfo
   */
  setPage(pageInfo) {
    if (this.currentPage.number !== pageInfo.offset) {
      this.searchFilterPagingResource.page = pageInfo.offset;
      this.loadData(this.searchFilterPagingResource);
    }
  }

  /**
   * Wechselt die Ergebnisse pro Seite ueber eine Checkbox.
   *
   * @param size Size
   */
  setPageSize(size: number) {
    this.searchFilterPagingResource.pageSize = size;
    this.tableIsLoading = true;
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Unsubscribe.
   */
  unsubscribe() {
    this.doUnsubscribe();
  }

  /**
   * Updatemethode für die Paginierung.
   *
   * @param page Page
   */
  updatePage(page: Page<T>): void {
    this.searchFilterPagingResource.page = page.number;
    this.data = [...page.content];
    const selectpage: boolean = this.currentPage.number !== page.number;
    this.currentPage = page;
    if (selectpage && this.footer) {
      this.footer.getDataPager().selectPage(page.number + 1);
    }
    this.tableIsLoading = false;
  }
}
