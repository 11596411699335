import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'datexpipe'
})
export class DatexPipe implements PipeTransform {
  public transform(value: any, format: string = ''): string {
    if (!value || value === '') {
      return '';
    }
    return moment(value).format(format);
  }
}
