<div class="[ panel cattle-detail]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'CATTLE_DETAIL.STAMMDATEN_FUER' | translate }} {{ dialogData?.cattle?.alphaLom }}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="row">
      <div class="cattle-information">
        <div class="headline">
          <label>{{ 'CATTLE_DETAIL.INFO.INFORMATIONEN' | translate }}</label>
        </div>
        <div class="content-panel">
          <table>
            <tr>
              <td>
                <div class="data-label">
                  <i class="icon-tag"></i>
                  {{ 'CATTLE_DETAIL.INFO.LOM' | translate }}
                </div>
              </td>
              <td>
                <div class="data-value">{{
                        dialogData?.cattle?.alphaLom
                            ? dialogData?.cattle?.alphaLom
                            : dialogData?.cattle?.cattleKey.lom
                  }}
                </div>
              </td>
              <td>
                <div class="data-label">
                  <i class="icon-cow-1"></i>
                  {{ 'CATTLE_DETAIL.INFO.RASSE' | translate }}
                </div>
              </td>
              <td>
                <div class="data-value">{{ dialogData?.cattle?.rasse?.shortLabel }}</div>
              </td>
              <td>
                <div class="data-label">
                  <i class="icon-gender-male"></i>
                  {{ 'CATTLE_DETAIL.INFO.VATER' | translate }}
                </div>
              </td>
              <td>
                @if (dialogData?.cattle?.vorfahren) {
                  <div class="data-value">{{ getVaterLabel() }}</div>
                }
              </td>
            </tr>
            <tr>
              <td>
                <div class="data-label">
                  <i class="icon-cow"></i>
                  {{ 'CATTLE_DETAIL.INFO.NAME' | translate }}
                </div>
              </td>
              <td>
                <div class="data-value">{{ dialogData?.cattle?.name }}</div>
              </td>
              <td>
                <div class="data-label">
                  <i class="icon-plus-circle"></i>
                  {{ 'CATTLE_DETAIL.INFO.STATUS' | translate }}
                </div>
              </td>
              <td>
                <div class="data-value">{{ dialogData?.cattle?.status }}</div>
              </td>
              <td>
                <div class="data-label">
                  <i class="icon-gender-male"></i>
                  {{ 'CATTLE_DETAIL.INFO.MV' | translate }}
                </div>
              </td>
              <td>
                @if (dialogData?.cattle?.vorfahren) {
                  <div class="data-value">{{ getMVLabel() }}</div>
                }
              </td>
            </tr>
            <tr>
              <td>
                <div class="data-label">
                  <i class="icon-barn"></i>
                  {{ 'CATTLE_DETAIL.INFO.STALL_NR' | translate }}
                </div>
              </td>
              <td>
                <div class="data-value">{{ dialogData?.cattle?.stallNummer }}</div>
              </td>
              <td>
                <div class="data-label">
                  <i class="icon-settings"></i>
                  {{ 'CATTLE_DETAIL.INFO.GEBURT' | translate }}
                </div>
              </td>
              <td>
                <div class="data-value">{{ dialogData?.cattle?.animalBirthdate | datexpipe: 'L' }}</div>
              </td>
              <td>
                <div class="data-label">
                  <i class="icon-ic_eb-lb"></i>
                  {{ 'CATTLE_DETAIL.INFO.LETZTE_KALBUNG' | translate }}
                </div>
              </td>
              <td>
                <div class="data-value">{{ dialogData?.cattle?.lastCalvingDate | datexpipe: 'L' }}
                  {{ dialogData?.cattle?.lastCalvingDate ? '(' + dialogData?.cattle?.calvingCount + ')' : '' }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="cattle-anpaarungsvorschlag">
        <div class="headline">
          <label>{{ 'CATTLE_DETAIL.ANPAARUNGS_VORSCHLAEGE' | translate }}</label>
        </div>
        <table class="table-anpaarung" width="100%">
          @if (dialogData?.cattle?.anpaarungen) {
            <tbody>
              @for (anpaarung of (dialogData.cattle?.anpaarungen); track anpaarung; let i = $index) {
                <tr
                >
                  <td class="data-label">{{ i + 1 }}</td>
                  <td class="data-value">{{ anpaarung?.name }}</td>
                  <td>
                    <span class="data-label">{{ 'CATTLE_DETAIL.HERDBUCHNUMMER' | translate }}:</span>
                    <span class="data-value">{{ anpaarung?.herdbookNumber }}</span>
                  </td>
                </tr>
              }
            </tbody>
          }
        </table>
      </div>
    </div>
    <div class="row subtitle">
      <div class="headline">
        <label>{{ 'CATTLE_DETAIL.BISHERIGE_BESAMUNGEN_UND_DIENSTLEISTUNGEN' | translate }}</label>
      </div>
    </div>
    <div class="row">
      <div class="cattle-besamungen-dienstleistungen">
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ c-data-list ]" id="data-list">
        <ngx-datatable
            #tierbesamungenTabelle
          [columnMode]="ColumnMode.force"
          [cssClasses]="customClasses"
          [footerHeight]="57"
          [headerHeight]="57"
          [messages]="tableMessages"
          [reorderable]="true"
          [rowHeight]="47"
          [rows]="rows"
          [sortType]="SortType.multi"
          [sorts]="sortField"
          class="[ c-data-list__table ]"
          style="height: 400px">
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="90"
            [sortable]="false" name="{{'CATTLE_DETAIL.BESAMUNGEN.TECHNIKER' | translate}}"
            prop="technikerKey.number">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header"
                title="{{'CATTLE_DETAIL.BESAMUNGEN.TECHNIKER' | translate}}">
              {{ 'CATTLE_DETAIL.BESAMUNGEN.TECHNIKER' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
            name="{{'CATTLE_DETAIL.BESAMUNGEN.BESAMUNGSDATUM' | translate}}"
            prop="inseminationDate">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span title="{{value | datexpipe: 'L'}}">{{ value | datexpipe: 'L' }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="60"
            [sortable]="false" name="{{'CATTLE_DETAIL.BESAMUNGEN.INSEMINATION_COUNT' | translate}}"
            prop="inseminationCount">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header"
                title="{{'CATTLE_DETAIL.BESAMUNGEN.INSEMINATION_COUNT' | translate}}">
              {{ 'CATTLE_DETAIL.BESAMUNGEN.INSEMINATION_COUNT' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
            name="{{'CATTLE_DETAIL.BESAMUNGEN.RIND.NAME' | translate}}"
            prop="rind.herdbookNumber">
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.rind) {
                <span>{{ row.rind.name + ' (' + row.rind.herdbookNumber + ')' }}</span>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [sortable]="false"
            name="{{'CATTLE_DETAIL.BESAMUNGEN.RIND.BEHANDLUNGSART' | translate}}"
            prop="behandlung.label">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span title="{{ value | spermaart }} ">{{ value | spermaart }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
              <portal-table-footer-without-paging
                [rows]="rows"
                [table]="tierbesamungenTabelle"
                class="[ table-footer ]">
              </portal-table-footer-without-paging>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
    <div class="[ buttons-right button-panel ]">
      <button (click)="schliessen()" class="[ c-mat-button no-color-button ]" mat-button>
        <i aria-hidden="true"></i>
        {{ 'BUTTON.SCHLIESSEN' | translate }}
      </button>
    </div>
  </div>
</div>
