import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { DateSearchComponent } from '../../core/component/date-search/date-search.component';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterCriteriaResource } from '../../core/filter/filterCriteriaResource';
import { FilterType } from '../../core/filter/filterType';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { DatexPipe } from '../../core/pipe/datex.pipe';
import { Auftragspositionsstatus } from '../../enumeration/auftragspositionsstatus.enum';
import { Auftrag } from '../../model/auftrag';
import { Auftragsposition } from '../../model/auftragsposition';
import { AuftragspositionService } from '../../service/auftrag/auftragsposition.service';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { FarmService } from '../../service/farm/farm.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { DateUtils } from '../../utils/date-utils';
import { Utils } from '../../utils/utils';
import { SearchFieldDefinition } from '../search/search-field-definition';
import { AuftragDetailModalComponent } from './auftrag-detail-modal/auftrag-detail-modal.component';

@Component({
  selector: 'portal-auftragsuebersicht',
  templateUrl: './auftragsuebersicht.component.html',
  styleUrls: ['./auftragsuebersicht.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuftragsuebersichtComponent extends AbstractServerPaginationTable<Auftragsposition> implements OnInit, OnDestroy {
  static readonly DATE_KEY: string = 'auftragsdatum';

  sortField = [
    {
      prop: 'auftragsdatum',
      dir: SortDirection.DESC.frontendkuerzel
    }
  ];

  minDate: Moment = moment().subtract(6, 'months');

  nurEigene = false;

  isBesamungstechniker: boolean;

  isBapBerater: boolean;

  isAdmin: boolean;

  routeSubscription: Subscription;

  @ViewChild(DateSearchComponent, { static: true }) dateSearch: DateSearchComponent;

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    modaTranslateService: ModaTranslateService,
    customErrorHandlerService: CustomErrorHandlerService,
    private auftragspositionService: AuftragspositionService,
    private datexPipe: DatexPipe,
    private readonly modaKeycloakService: ModaKeycloakService,
    private readonly dialog: MatDialog,
    private readonly farmService: FarmService
  ) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Neue Auftrag erfassen
   */
  public auftragErfassen() {
    sessionStorage.setItem('auftrag', JSON.stringify(this.createAuftrag([])));
    this.router.navigate(['auftraege', 'farmuebersicht']);
  }

  /**
   * Laegt die Daten bei der Aenderugn der nurEigene Checkbox neu.
   */
  changeCheckbox() {
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Erstellt einen Auftrag
   * @param auftragspositionen Liste von Auftragspositionen
   */
  createAuftrag(auftragspositionen: Auftragsposition[]) {
    const auftragsposition = auftragspositionen && auftragspositionen.length ? auftragspositionen[0] : null;
    const auftrag = new Auftrag();
    auftrag.positionen = auftragspositionen || [];
    if (auftragsposition) {
      auftrag.auftragsnummer = auftragsposition.auftragsnummer;
      auftrag.technikerKey = auftragsposition.technikerKey;
      auftrag.betriebsschluessel = auftragsposition.betriebsschluessel;
      auftrag.adresse = auftragsposition.adresse;
      auftrag.auftragsdatum = auftragsposition.auftragsdatum;
      auftrag.suchfokus = auftragsposition.suchfokus;
      auftrag.shortNumber = auftragsposition.shortNumber;
    }
    return auftrag;
  }

  /**
   * Sortierung der Felder.
   * @param event Event
   */
  determineSortField(event): string {
    switch (event.column.prop) {
      case 'tnr':
        return 'technikerKey.number';
      case 'h':
        return 'auftragsnummer';
      case 'name':
        return 'adresse.nachname';
      default:
        return event.column.prop;
    }
  }

  /**
   * {@inheritDoc}
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    const filterCriteriaList = this.searchFilterPagingResource.andFilterCriteriaResourceList;
    this.initDateRange();
    for (const filter of filterCriteriaList) {
      if (filter.key === AuftragsuebersichtComponent.DATE_KEY && this.dateSearch.dateFromValue === null) {
        filter.value.from = this.minDate;
      }
    }
    if (this.nurEigene) {
      this.loadDataSubscription = this.auftragspositionService.loadData(searchFilterPagingResource).subscribe(
        (data) => {
          this.doAfterLoadData(data);
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    } else {
      this.loadDataSubscription = this.auftragspositionService.loadBetriebData(searchFilterPagingResource).subscribe(
        (data) => {
          this.doAfterLoadData(data);
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    }
  }

  /**
   * Liefert das Feld für die Datumsuche zurueck.
   */
  getSearchFieldDate() {
    return 'auftragsdatum';
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('auftragsnummer', FilterType.STRING),
      new SearchFieldDefinition('betriebsschluessel', FilterType.STRING),
      new SearchFieldDefinition('vorname', FilterType.STRING),
      new SearchFieldDefinition('nachname', FilterType.STRING)
    ];
  }

  /**
   * {@inheritDoc}
   */
  getService(): ServerPaginationService<Auftragsposition> {
    return this.auftragspositionService;
  }

  /**
   * {@inheritDoc}
   */
  getSortFields(): any {
    return [
      {
        prop: 'auftragsdatum',
        dir: SortDirection.DESC.frontendkuerzel
      },
      {
        prop: 'auftragsnummer',
        dir: SortDirection.DESC.frontendkuerzel
      }
    ];
  }

  /**
   * Initialisierung der Min- und Maxwerte fuer den Datepicker.
   */
  initDateRange() {
    const filtercriteriaResourcen = this.searchFilterPagingResource.andFilterCriteriaResourceList;
    for (const filtercriteria of filtercriteriaResourcen) {
      if (filtercriteria.key === AuftragsuebersichtComponent.DATE_KEY) {
        return;
      }
    }
    let fromDate = moment().startOf('day');
    if (this.dateSearch.dateFromValue && this.dateSearch.dateFromValue === DateUtils.getMinDate()) {
      fromDate = this.minDate;
    }
    const defaultDateFilter = new FilterCriteriaResource(
      this.getSearchFieldDate(),
      {
        from: fromDate,
        to: moment().endOf('day')
      },
      FilterType.STRING
    );
    this.searchFilterPagingResource.andFilterCriteriaResourceList.push(defaultDateFilter);
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.routeSubscription);
  }

  ngOnInit() {
    this.dateSearch.dateFromValue = moment();
    this.dateSearch.dateToValue = moment();
    this.routeSubscription = this.route.data.subscribe((value) => {
      if (value.neuerAuftrag) {
        this.auftragErfassen();
      }
    });
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.isBapBerater = this.modaKeycloakService.isBapBerater();
    if (this.isBesamungstechniker || this.isBapBerater) {
      this.nurEigene = true;
    }
    this.isAdmin = this.modaKeycloakService.isAdmin();
    this.initPagination();
    const defaultDateFilter = new FilterCriteriaResource(
      this.getSearchFieldDate(),
      {
        from: moment().startOf('day'),
        to: moment().endOf('day')
      },
      FilterType.STRING
    );
    this.searchFilterPagingResource.andFilterCriteriaResourceList.push(defaultDateFilter);
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * {@inheritDoc}
   */
  onDoubleClick(row: Auftragsposition) {
    this.showAuftragDetails(row);
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row Auftragsposition
   */
  rowIdentity(row: Auftragsposition) {
    return row.receiptNumber;
  }

  /**
   * Zeigt die Auftragsdetails an.
   *
   * @param auftragsposition Auftrag
   */
  showAuftragDetails(auftragsposition: Auftragsposition) {
    this.auftragspositionService.findAuftragPositionenByAuftragsNr(auftragsposition.auftragsnummer).subscribe({
      next: (poslist) => {
        const warenkorb = poslist.filter(
          (pos) => pos.auftragspositionsstatus === Auftragspositionsstatus.IM_WARENKORB || pos.auftragspositionsstatus === Auftragspositionsstatus.ERSTELLT
        );
        if (warenkorb && warenkorb.length && !this.isAdmin) {
          const auftrag = this.createAuftrag(poslist);
          this.farmService.loadByBetriebsschluessel(auftragsposition.betriebsschluessel).subscribe(
            (farm) => {
              auftrag.farmCategory = farm.farmCategory;
              sessionStorage.setItem('auftrag', JSON.stringify(auftrag));
              this.router.navigate(['auftraege', 'farmuebersicht', 'auftragsuebersicht']);
            },
            (error) => this.customErrorHandlerService.handleError(error)
          );
        } else {
          this.dialog.open(AuftragDetailModalComponent, {
            minWidth: '1261px',
            panelClass: 'vost-modal-dialog',
            data: {
              auftragspositionen: poslist
            }
          });
        }
      },
      error: this.customErrorHandlerService.handleError
    });
  }
}
