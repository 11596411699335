import { SortDirection } from '../core/filter/sort-direction';
import { SortResource } from '../core/filter/sort-resource';

export class SortUtils {
  /**
   * Erstellt eine SortResource mit dem uebergeben Field und Direction.
   *
   * @param field Field
   * @param direction Sort-Direction
   * @return SortResource
   */
  static createSortResource(field: string, direction: string): SortResource {
    let sortDirection: SortDirection = null;
    if (direction === SortDirection.DESC.frontendkuerzel) {
      sortDirection = SortDirection.DESC;
    } else if (direction === SortDirection.ASC.frontendkuerzel) {
      sortDirection = SortDirection.ASC;
    }
    if (sortDirection === null) {
      console.error('Unknown Direction: ', direction);
      return null;
    }
    return new SortResource(field, sortDirection.backendkuerzel);
  }

  /**
   *  Erstellt die SortResources und fuegt sie zu der uebergebenen Sort-Resource-List.
   * @param sortResourceList Sort-Resource-List
   * @param sortFields Sort-Fields
   * @param clear Bei True, wird die Sort-Resource-List geleert
   */
  static addToSortResourceList(sortResourceList: SortResource[], sortFields: any, clear: boolean = false) {
    if (clear) {
      sortResourceList.splice(0, sortResourceList.length);
    }
    for (const sortField of sortFields) {
      const index = sortResourceList.findIndex((value) => value.field === sortField.prop);
      if (index >= 0) {
        sortResourceList.splice(index);
      }
      const sortResource = this.createSortResource(sortField.prop, sortField.dir);
      if (sortResource === null) {
        console.error('Sort-Resource ist null');
      } else {
        sortResourceList.push(sortResource);
      }
    }
  }
}
