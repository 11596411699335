import { Pipe, PipeTransform } from '@angular/core';
import { OrderSaleType } from '../../enumeration/order-sale-type.enum';

@Pipe({
  name: 'bookingType'
})
export class BookingTypePipe implements PipeTransform {
  /**
   * Transformiert die uebergebene Bookingtype in B oder D raus.
   * Wenn in dem Feld bookingType ORDERING steht dann Bestellung, wenn SALE steht Dirtektverkauf gesetzt.
   *
   * @param value Bookingtype
   */
  transform(value: string): string {
    if (value && value.trim() !== null && value.trim() === OrderSaleType.ORDERING) {
      return 'B';
    } else if (value && value.trim() !== null && value.trim() === OrderSaleType.SALE) {
      return 'D';
    } else {
      return '';
    }
  }
}
