import { AnpaarungBap } from './anpaarung-bap';
import { Besamung } from './besamung';
import { Breed } from './breed';
import { CattleKey } from './cattleKey';
import { FarmInfo } from './farmInfo';
import { Vorfahre } from './vorfahre';

export class Cattle {
  id: string;

  cattleKey: CattleKey;

  name: string;

  alphaLom: string;

  status: string;

  stallNummer: string;

  lastCalvingDate: Date;

  rasse: Breed;

  calvingCount: number;

  comment: string;

  removalDate: Date;

  animalBirthdate: Date;

  besamungen: Array<Besamung> = [];

  anpaarungen: Array<AnpaarungBap> = [];

  vorfahren: Array<Vorfahre> = [];

  wvb: number;

  ersteBesamung: Besamung;

  letzteBesamung: Besamung;

  farmInfo: FarmInfo;
}
