import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[validateRegex]'
})
export class ValidateRegexDirective implements OnInit {
  @Input('validateRegex') regex = '';

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Control', 'Delete'];

  private lastEventType = 'default';

  private lastEventValue = '';

  private markedStartPosition = 0;

  private markedEndPosition = 0;

  private regexMatch: RegExp;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.regexMatch = new RegExp(this.regex);
  }

  /**
   * Prueft den kopierten String per RegEx.
   *
   * @param event Event
   */
  @HostListener('paste', ['$event']) pasteEvent(event) {
    const current: string = this.element.nativeElement.value ? this.element.nativeElement.value : '';
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    let tmpPasteString;
    if (this.lastEventType === 'select') {
      tmpPasteString =
        this.lastEventValue.substring(0, this.markedStartPosition) +
        clipboardData +
        this.lastEventValue.substring(this.markedEndPosition, this.lastEventValue.length);
    } else {
      tmpPasteString = current + clipboardData;
    }
    if (!tmpPasteString.match(this.regexMatch) || tmpPasteString.trim() === '') {
      event.preventDefault();
      return;
    }
    this.lastEventType = event.type;
    this.lastEventValue = tmpPasteString;
    this.markedStartPosition = 0;
    this.markedEndPosition = tmpPasteString.length;
  }

  /**
   * Prueft die Eingabe eines Inputfeldes per RegEx.
   *
   * @param event Event
   */
  @HostListener('keydown', ['$event']) @HostListener('select', ['$event']) @HostListener('click', ['$event']) validateRegex(event) {
    if (
      ((event.keyCode === 67 || event.keyCode === 86 || event.keyCode === 88 || event.keyCode === 65) && event.ctrlKey) ||
      this.specialKeys.indexOf(event.key) !== -1
    ) {
      return;
    }
    this.markedStartPosition = this.element.nativeElement.selectionStart;
    this.markedEndPosition = this.element.nativeElement.selectionEnd;

    if (event.type && event.type === 'select') {
      this.lastEventType = event.type;
    } else {
      this.lastEventType = 'default';
    }
    if (
      (event.keyCode === 32 && this.lastEventValue.length === this.markedStartPosition + this.markedEndPosition && this.markedStartPosition === 0) ||
      (event.keyCode === 32 && this.element.nativeElement.value.trim() === '')
    ) {
      event.preventDefault();
      return;
    } else {
      let current: string = this.element.nativeElement.value;
      if (this.markedEndPosition) {
        if (this.markedStartPosition > 0) {
          current = current.substr(0, this.markedStartPosition) + current.substr(this.markedEndPosition);
        } else {
          if (this.markedEndPosition === current.length) {
            current = '';
          } else {
            current = current.substr(this.markedEndPosition + 1);
          }
        }
      }

      const next: string = current.concat(event.key);
      if (!(this.lastEventType === 'select' && event.key && event.key.match(this.regexMatch)) && next && !String(next).match(this.regexMatch)) {
        event.preventDefault();
      }
    }
    this.lastEventValue = this.element.nativeElement.value;
  }
}
