<div class="headline">
  <label>{{ 'SPERMAAUSWAHL.TITLE' | translate }}</label>
</div>
<mat-dialog-content class="mat-typography">
  <div class="spermaauswahl-container">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search (search)="doOrSearch($event)"
          [fields]="getSearchFieldDefinitions()" [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
        <portal-range-search (search)="doAndSearchBestand($event)"
          [field]="getSearchFieldDefinitionBestand()"
          [maxPlaceholder]="('SPERMAAUSWAHL.BESTAND_BIS' | translate)"
          [minPlaceholder]="('SPERMAAUSWAHL.BESTAND_VON' | translate)"
          class="[ margin-left-20 ]"></portal-range-search>
        <portal-range-search (search)="doAndSearchSpermapreis($event)"
          [field]="getSearchFieldDefinitionSpermapreis()"
          [maxPlaceholder]="('BESTAND_BETRIEBSSPERMA.SPERMAPREIS_BIS' | translate)"
          [minPlaceholder]="('BESTAND_BETRIEBSSPERMA.SPERMAPREIS_VON' | translate)"
          class="[ margin-left-20 ] [ date-search ]"></portal-range-search>
        <portal-search #searchRasseField (search)="doAndSearchRasse($event)"
          [fields]="getSearchFieldDefinitionRasse()" [placeholder]="('BULLENUEBERSICHT.RASSE' | translate)"
          class="[ margin-left-20 ]"></portal-search>
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ row ]">
        <div class="[ col-12 ]  [ c-data-list ]">
          <ngx-datatable
              #spermauswahlTabelle
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
              name="{{'SPERMAAUSWAHL.HERDBUCHNUMMER' | translate}}" prop="herdbookNumber">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'SPERMAAUSWAHL.HERDBUCHNUMMER' | translate}}">
                {{ 'SPERMAAUSWAHL.HERDBUCHNUMMER' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              name="{{'SPERMAAUSWAHL.NAME' | translate}}" prop="name">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="true"
              name="{{'SPERMAAUSWAHL.RASSE' | translate}}" prop="rasse.shortLabel">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [sortable]="false"
              name="{{'SPERMAAUSWAHL.BEHANDLUNGSART' | translate}}" prop="label">
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span [title]="value | spermaart">{{ value | spermaart }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="100" [sortable]="false"
              name="{{'SPERMAAUSWAHL.BESTAND_VS' | translate}}" prop="bestandStationssperma">
              <ng-template ngx-datatable-header-template>
                <span class="right-cell-header" title="{{'SPERMAAUSWAHL.BESTAND_VS' | translate}}">
                {{ 'SPERMAAUSWAHL.BESTAND_VS' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="100" [sortable]="false"
              name="{{'SPERMAAUSWAHL.BESTAND_AS' | translate}}" prop="bestandAnrechtssperma">
              <ng-template ngx-datatable-header-template>
                <span class="right-cell-header" title="{{'SPERMAAUSWAHL.BESTAND_AS' | translate}}">
                {{ 'SPERMAAUSWAHL.BESTAND_AS' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
              name="{{'SPERMAAUSWAHL.PREIS' | translate}}" prop="price">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header" title="{{'SPERMAAUSWAHL.PREIS' | translate}}">
                {{ 'SPERMAAUSWAHL.PREIS' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value.amount | currency:value.currency | amountFilter }}">
                  {{ value.amount | currency:value.currency | amountFilter }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="110" [sortable]="false" name="{{'SPERMAAUSWAHL.ANZAHL' | translate}}"
              prop="anzahl">
              <ng-template ngx-datatable-header-template>
                <span class="center-cell-header" title="{{'SPERMAAUSWAHL.ANZAHL' | translate}}">
                {{ 'SPERMAAUSWAHL.ANZAHL' | translate }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div class="[ decrementIncrementCell ]">
                  @if (row !== selectedElement) {
                    <span title="{{value}}">{{ value }}</span>
                  }
                  @if (row === selectedElement) {
                    <div>
                      <button (click)="decrementAnzahl(row)" [disabled]="row.anzahl <= 0" mat-icon-button>
                        <i class="[ icon-plus-circle-1 ]"></i>
                      </button>
                      <input (input)="setAnzahl(row)" [(ngModel)]="row.anzahl"
                        [validateRegex]="'^\\d{1,3}$|^\\d{1,3},\\d{1,1}$|^$'">
                      <button (click)="incrementAnzahl(row)" [disabled]="row.anzahl >= 999" mat-icon-button>
                        <i class="[ icon-plus-circle ]"></i>
                      </button>
                    </div>
                  }
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="spermauswahlTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <div class="[ form-content ]">
      <button (click)="abbrechen()" class="[ c-mat-button no-color-button ]" mat-button>
        <i aria-hidden="true"></i>
        {{ 'BUTTON.ABBRECHEN' | translate }}
      </button>
      <label
        class="[ margin-right-left-auto ausgewaehlt-label ]">
        {{ determineAnzahlSpermaartikel() }} {{ 'SPERMAAUSWAHL.ARTIKEL_AUSGEWAEHLT' | translate }}
      </label>
      <button (click)="hinzufuegen()" class="[ c-mat-button green-button ]" mat-flat-button>
        <i aria-hidden="true" class="[ icon-plus ]" style="margin-right: 10px"></i>
        {{ 'BUTTON.HINZUFUEGEN' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
