export class TechnikerKey {
  number: number;

  aiStationNumber: number;

  constructor(technikernummer: number, aiStationNumber: number) {
    this.number = technikernummer;
    this.aiStationNumber = aiStationNumber;
  }
}
