import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as numeral from 'numeral';
import locales from 'numeral/locales';

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'portal';

  constructor(private readonly translateService: TranslateService) {
    this.customizeTranslateService();
    registerLocaleData(localeDe, 'de');
  }

  /**
   * Konfiguriert die TranslateService.
   *
   */
  private customizeTranslateService() {
    this.translateService.addLangs(['de', 'en']);
    this.translateService.setDefaultLang('de');
    const browserLang = this.translateService.getBrowserLang();
    const lang = browserLang.match(/de/) ? browserLang : 'de';
    this.translateService.use(lang);
    // This is a workaround to keep the import !!!!, removing the import looses all locale formats except 'en'
    const local = locales;
    numeral.locale(lang);
    moment.locale(lang);
  }
}
