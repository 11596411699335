import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DateSearchComponent } from '../../core/component/date-search/date-search.component';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterCriteriaResource } from '../../core/filter/filterCriteriaResource';
import { FilterType } from '../../core/filter/filterType';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { Spermatausch } from '../../model/spermatausch';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SpermatauschService } from '../../service/spermatausch/spermatausch.service';
import { AuftragspositionenUtils } from '../../utils/auftragspositionen-utils';
import { DialogUtils } from '../../utils/dialog-utils';
import { Utils } from '../../utils/utils';
import { SearchFieldDefinition } from '../search/search-field-definition';
import { VerlustauswahlComponent } from './verlustauswahl/verlustauswahl.component';

@Component({
  selector: 'portal-verlustuebersicht',
  templateUrl: './verlustuebersicht.component.html',
  styleUrls: ['./verlustuebersicht.component.scss']
})
export class VerlustuebersichtComponent extends AbstractServerPaginationTable<Spermatausch> implements OnInit, OnDestroy {
  sortField = [
    {
      prop: 'auftragsdatum',
      dir: SortDirection.DESC.frontendkuerzel
    }
  ];

  isBesamungstechniker: boolean;

  @ViewChild(DateSearchComponent, { static: true }) dateSearch: DateSearchComponent;

  routeQueryParamsSubscription: Subscription;

  constructor(
    modaTranslateService: ModaTranslateService,
    customErrorHandlerService: CustomErrorHandlerService,
    private readonly spermatauschService: SpermatauschService,
    private readonly modaKeycloakService: ModaKeycloakService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
    super(modaTranslateService, customErrorHandlerService);
    this.routeQueryParamsSubscription = route.queryParams.subscribe((params) => {
      if (params.neuerVerlust) {
        this.neuerVerlust();
      }
    });
  }

  /**
   * {@inheritDoc}
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    if (!this.searchFilterPagingResource.andFilterCriteriaResourceList.find((value) => value.key === 'aenderungstyp')) {
      this.searchFilterPagingResource.andFilterCriteriaResourceList.push(new FilterCriteriaResource('aenderungstyp', 'LOSS', FilterType.STRING));
    }
    this.loadDataSubscription = this.spermatauschService.loadData(searchFilterPagingResource).subscribe(
      (data) => {
        this.doAfterLoadData(data);
        if (this.searchFilterPagingResource.andFilterCriteriaResourceList.find((value) => value.key === AuftragspositionenUtils.AUFTRAGSPOSITIONSSTATUS)) {
          this.searchFilterPagingResource.andFilterCriteriaResourceList = this.searchFilterPagingResource.andFilterCriteriaResourceList.filter(
            (value) => value.key !== AuftragspositionenUtils.AUFTRAGSPOSITIONSSTATUS
          );
          const defaultDateFilter = new FilterCriteriaResource(
            this.getSearchFieldDate(),
            {
              from: moment().startOf('day'),
              to: moment().endOf('day')
            },
            FilterType.STRING
          );
          this.searchFilterPagingResource.andFilterCriteriaResourceList.push(defaultDateFilter);
        }
      },
      (error) => this.customErrorHandlerService.handleError(error)
    );
  }

  /**
   * Liefert das Feld für die Datumsuche zurueck.
   */
  public getSearchFieldDate() {
    return 'auftragsdatum';
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [new SearchFieldDefinition('receiptNumber', FilterType.STRING), new SearchFieldDefinition('name', FilterType.STRING)];
  }

  /**
   *  {@inheritDoc}.
   */
  getService(): ServerPaginationService<Spermatausch> {
    return this.spermatauschService;
  }

  /**
   *  {@inheritDoc}.
   */
  public getSortFields(): any {
    return [
      {
        prop: 'auftragsdatum',
        dir: SortDirection.DESC.frontendkuerzel
      },
      {
        prop: 'receiptNumber',
        dir: SortDirection.DESC.frontendkuerzel
      }
    ];
  }

  /**
   * Neue Bestellung erfassen.
   */
  neuerVerlust() {
    const dialogRef = this.dialog.open(VerlustauswahlComponent, {
      minWidth: '600px',
      panelClass: 'vost-modal-dialog',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData(this.searchFilterPagingResource);
      }
      this.router.navigate(['.'], { relativeTo: this.route });
    });
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.routeQueryParamsSubscription);
  }

  ngOnInit() {
    this.dateSearch.dateFromValue = moment();
    this.dateSearch.dateToValue = moment();
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.initPagination();
    const defaultStatusFilter = new FilterCriteriaResource(AuftragspositionenUtils.AUFTRAGSPOSITIONSSTATUS, 'ERSTELLT', FilterType.STRING);
    this.searchFilterPagingResource.andFilterCriteriaResourceList.push(defaultStatusFilter);
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Loescht einen Verlust mit Sicherheitsabfrage.
   *
   * @param spermatausch Spermatausch
   */
  onDeletePosition(spermatausch: Spermatausch) {
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('VERLUSTUEBERSICHT.DELETE_DIALOG.HEADLINE'),
      message: this.modaTranslateService.translate('VERLUSTUEBERSICHT.DELETE_DIALOG.MESSAGE')
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spermatauschService.delete(spermatausch).subscribe(
          () => this.loadData(this.searchFilterPagingResource),
          (error) => this.customErrorHandlerService.handleError(error)
        );
      }
    });
  }

  /**
   *  {@inheritDoc}.
   */
  public rowIdentity(row: Spermatausch) {
    return row.id;
  }
}
