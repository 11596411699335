export class SortDirection {
  static DESC = new SortDirection('DESC', 'desc');

  static ASC = new SortDirection('ASC', 'asc');

  backendkuerzel: string;

  frontendkuerzel: string;

  constructor(backendkuerzel: string, frontendkuerzel: string) {
    this.backendkuerzel = backendkuerzel;
    this.frontendkuerzel = frontendkuerzel;
  }
}
