import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as numeral from 'numeral';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterCriteria } from '../../core/filter/filterCriteria';
import { FilterType } from '../../core/filter/filterType';
import { SearchFieldDefinition } from './search-field-definition';

@Component({
  selector: 'portal-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  searchTextChanged = new Subject<string>();

  @ViewChild('searchField', { static: true }) searchField: ElementRef;

  @Input() fields: SearchFieldDefinition[];

  @Input() placeholder: string;

  @Input() automaticSearch = true;

  @Output() public search = new EventEmitter<FilterCriteria>();

  searchCtrlSub: Subscription;

  /**
   * Konstruktor.
   */
  constructor() {}

  /**
   * Leert den Suchfeld.
   */
  public clear() {
    this.searchField.nativeElement.value = '';
  }

  public clearSearchField($event: MouseEvent, searchField: HTMLInputElement) {
    $event.preventDefault();
    $event.stopPropagation();
    searchField.value = '';
    ($event.target as HTMLElement).style.visibility = 'hidden';
    this.doSearch(searchField.value);
  }

  /**
   * Suche nach criteria
   * @param value Value
   */
  doSearch(value: string) {
    const searchValue = value.trim();
    if (searchValue.length > 0) {
      const filterCriteria = this.createFilterCriteria(searchValue);
      this.search.emit(filterCriteria);
    } else {
      this.search.emit(null);
    }
  }

  public hasValue(searchField: HTMLInputElement) {
    return searchField.value.length > 0;
  }

  ngOnDestroy() {
    if (this.automaticSearch) {
      this.searchCtrlSub.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.automaticSearch) {
      this.searchCtrlSub = this.searchTextChanged.pipe(debounceTime(1000)).subscribe(() => {
        const text = this.searchField.nativeElement.value;
        if (text.length === 0 || text.length >= 3) {
          this.doSearch(text);
        }
      });
    }
  }

  /**
   * Event auf Enter
   * @param $event Keyboardevent
   * @param value Value
   */
  public onKeyDown($event: KeyboardEvent, value: string) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  /**
   * Event auf Enter Taste
   * @param $event KeyboarEvent
   * @param value Value
   */
  public onKeyUp($event: KeyboardEvent, value: string) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      $event.stopPropagation();
      this.doSearch(value);
    } else {
      this.searchTextChanged.next(value);
    }
  }

  /**
   * Erstellt Filtercriteria
   * @param value Filtercriteria
   */
  private createFilterCriteria(value: string): FilterCriteria {
    const criteria: FilterCriteria = new FilterCriteria();
    for (const field of this.fields) {
      switch (field.type) {
        case FilterType.STRING:
          criteria.addFilter(field.name, value, FilterType.STRING);
          break;
        case FilterType.ENUM:
          criteria.addFilter(field.name, value, FilterType.ENUM);
          break;
        case FilterType.NUMBER:
          const numVal = numeral(value);
          if (!isNaN(numVal.value())) {
            criteria.addFilter(field.name, numVal.value(), FilterType.NUMBER);
          }
          break;
        case FilterType.DATE:
        case FilterType.INTERVAL:
        case FilterType.SEPARATED_STRING:
          break;
      }
    }
    return criteria;
  }
}
