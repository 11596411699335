import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanTranslation'
})
export class BooleanTranslationPipe implements PipeTransform {
  /**
   * Gibt die Übersetzungskey fuer Boolean zurueck.
   * @param value Value
   * @param args Args
   */
  transform(value: any, args?: any): any {
    if (value === null) {
      return 'BOOLEAN_TRANSLATION_PIPE.NULL';
    }
    return value ? 'BOOLEAN_TRANSLATION_PIPE.TRUE' : 'BOOLEAN_TRANSLATION_PIPE.FALSE';
  }
}
