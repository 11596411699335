<div class="headline">
  <label>{{ 'JOB_DETAIL.HEADLINE' | translate }} {{ job.jobType | translate }}</label>
</div>
<div class="boxed-area">
  <mat-form-field>
    <input [(ngModel)]="job.cronExpression" matInput placeholder="{{ 'JOB_DETAIL.CRON_EXPRESSION' | translate }}"
      type="text">
  </mat-form-field>
</div>
<div class="[ form-content-modal ]">
  <button (click)="clickAbbrechenButton()" class="[ c-mat-button no-color-button ]" mat-button>
    <i aria-hidden="true"></i>
    {{ 'BUTTON.ABBRECHEN' | translate }}
  </button>
  <button (click)="clickBestaetigenButton()" class="[ c-mat-button green-button right-button ]" mat-flat-button>
    <i aria-hidden="true" class="[ icon-baseline-done-24px ]" style="font-size: 24px"></i>
    {{ 'BUTTON.BESTAETIGEN' | translate }}
  </button>
</div>
