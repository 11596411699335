<mat-dialog-content class="mat-typography">
  <div class="warenauswahl-container">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <portal-search #searchArtikel (search)="doOrSearch($event)" [fields]="getSearchFieldDefinitions()"
          [placeholder]="('TOOLBAR.SUCHBEGRIFF' | translate)"></portal-search>
      </div>
    </div>
    <div class="[ row ]">
      <div class="[ col-12 ]  [ c-data-list ]">
        <div class="[ content-table ]">
          <ngx-datatable
              #warenauswahlNonSpermaTabelle
            (page)="setPage($event)"
            (select)="onSelect($event)"
            (sort)="onSort($event)"
            [columnMode]="'force'"
            [count]="currentPage.totalElements"
            [cssClasses]="customClasses"
            [externalPaging]="true"
            [externalSorting]="true"
            [footerHeight]="57"
            [headerHeight]="57"
            [limit]="currentPage.size"
            [loadingIndicator]="tableIsLoading"
            [messages]="tableMessages"
            [offset]="currentPage.number"
            [rowHeight]="49"
            [rowIdentity]="rowIdentity"
            [rows]="data"
            [selectAllRowsOnPage]="false"
            [selected]="selected"
            [selectionType]="'single'"
            [sorts]="sortField"
            class="[ c-data-list__table ]"
            style="height: 604px;">
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="140" [minWidth]="140" [sortable]="true" [width]="140"
              name="{{'WARENAUSWAHL.NON_SPERMA_TAB.ARTIKEL_NR' | translate}}" prop="artikelnummer">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'WARENAUSWAHL.NON_SPERMA_TAB.ARTIKEL_NR' | translate}}">
                {{ 'WARENAUSWAHL.NON_SPERMA_TAB.ARTIKEL_NR' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value }}">{{ value }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="true"
              [width]="200"
              name="{{'WARENAUSWAHL.NON_SPERMA_TAB.NAME' | translate}}" prop="bezeichnung">
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="120" [minWidth]="110" [sortable]="true"
              name="{{'WARENAUSWAHL.NON_SPERMA_TAB.BESTAND' | translate}}" prop="agrarartikelbestand.istBestand">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'WARENAUSWAHL.NON_SPERMA_TAB.BESTAND' | translate}}">
                {{ 'WARENAUSWAHL.NON_SPERMA_TAB.BESTAND' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value > 0 ? value : 0 }}">{{ value > 0 ? value : 0 }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="120"
              [sortable]="true" name="{{'WARENAUSWAHL.NON_SPERMA_TAB.SOLL_BESTAND' | translate}}"
              prop="agrarartikelbestand.sollBestand">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'WARENAUSWAHL.NON_SPERMA_TAB.SOLL_BESTAND' | translate}}">
                {{ 'WARENAUSWAHL.NON_SPERMA_TAB.SOLL_BESTAND' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value > 0 ? value : 0 }}">{{ value > 0 ? value : 0 }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="60"
              [sortable]="true" prop="staffelpreis">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer"
                  title="{{'WARENAUSWAHL.NON_SPERMA_TAB.STAFFELPREIS_TITLE' | translate}}">
                {{ 'WARENAUSWAHL.NON_SPERMA_TAB.STAFFELPREIS' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span
                  title="{{value | booleanTranslation | translate}}">{{ value | booleanTranslation | translate }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="120" [minWidth]="120" [sortable]="true" [width]="120"
              name="{{'WARENAUSWAHL.NON_SPERMA_TAB.PREIS' | translate}}" prop="vkPreis">
              <ng-template let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer right-cell-header"
                  title="{{'WARENAUSWAHL.NON_SPERMA_TAB.PREIS' | translate}}">
                {{ 'WARENAUSWAHL.NON_SPERMA_TAB.PREIS' | translate }}</span>
              </ng-template>
              <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="right-cell" title="{{ value.amount | currency:value.currency }}">
                  {{ value.amount | currency:value.currency }}
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
              [maxWidth]="125" [minWidth]="125" [sortable]="false" [width]="125"
              name="{{'WARENAUSWAHL.NON_SPERMA_TAB.ANZAHL' | translate}}"
              prop="anzahl">
              <ng-template ngx-datatable-header-template>
                <span class="center-cell-header" title="{{'WARENAUSWAHL.NON_SPERMA_TAB.ANZAHL' | translate}}">
                {{ 'WARENAUSWAHL.NON_SPERMA_TAB.ANZAHL' | translate }}</span>
              </ng-template>
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div class="[ decrementIncrementCell ]">
                  @if (row !== selectedElement || (row.auftragsstatus ?
                      (row.auftragsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.VERSANDBEREIT'
                          || row.auftragsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.VERSENDET') : false)) {
                    <span
                      title="{{value}}">{{ value }}</span>
                  }
                  @if (row === selectedElement && (row.auftragsstatus ?
                      row.auftragsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.IM_WARENKORB' ||
                      row.auftragsstatus === 'ENUM.AUFTRAGSPOSITIONSSTATUS.ERSTELLT' : true)) {
                    <div
                    >
                      <button (click)="decrementAnzahl(row)" [disabled]="row.anzahl <= 0" mat-icon-button>
                        <i class="[ icon-plus-circle-1 ]"></i>
                      </button>
                      <input (input)="setAnzahl(row)" [(ngModel)]="row.anzahl"
                        [validateRegex]="'^\\d{1,3}$|^\\d{1,3},\\d{1,1}$|^$'">
                      <button (click)="incrementAnzahl(row)" [disabled]="row.anzahl >= 999" mat-icon-button>
                        <i class="[ icon-plus-circle ]"></i>
                      </button>
                    </div>
                  }
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
                <portal-table-server-pagination-footer #paginationFooter (changeEvent)="setPageSize($event)"
                  [currentPage]="currentPage"
                  [selected]="selected" [table]="warenauswahlNonSpermaTabelle"
                  class="[ table-footer ]"></portal-table-server-pagination-footer>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <div class="[ form-content ]">
      <button (click)="abbrechen()" class="[ c-mat-button no-color-button ]" mat-button>
        <i aria-hidden="true"></i>
        {{ 'BUTTON.ABBRECHEN' | translate }}
      </button>
      <label
        class="[ margin-right-left-auto ausgewaehlt-label ]">
        {{ determineAnzahlNonSpermaArtikel() }} {{ 'WARENAUSWAHL.NON_SPERMA_TAB.ARTIKELAUSWAHL' | translate }}
      </label>
      <button (click)="hinzufuegen()" class="[  c-mat-button dark-green-button margin-left-25 ]" mat-flat-button>
        <i aria-hidden="true" class="[ icon-ic_save_done ]" style="margin-right: 10px"></i>
        {{ 'BUTTON.FERTIG' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
