<div class="headline">
  <label>{{ 'VERLUSTAUSWAHL.TITLE' | translate }}</label>
</div>
<mat-dialog-content class="mat-typography">
  <div class="verlustauswahl-container">
    @if (verlustForm) {
      <form [formGroup]="verlustForm" novalidate>
        <div class="box">
          <div class="headline">
            <label>{{ 'VERLUSTAUSWAHL.SPERMA' | translate }}</label>
            <button (click)="sucheBulle()" class="[ c-mat-button no-color-button right ]" mat-button>
              {{ 'BUTTON.ALLE_ANZEIGEN' | translate }}
              <i aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]"></i>
            </button>
          </div>
          <div class="bulle-container">
            <portal-search #searchBulleInput (search)="searchBulle($event)" [automaticSearch]="false"
              [fields]="getSearchFieldDefinitionsBulle()"
              [placeholder]="('VERLUSTAUSWAHL.BULLE_SEARCH' | translate)"></portal-search>
            <portal-date #dateComponent (dateChange)="selectedDate=$event"
              (dateClear)="selectedDate.date=null"
              [datePlaceholder]="('VERLUSTAUSWAHL.DATUM' | translate)"
              [dateValue]="selectedDate && selectedDate.date"
              [required]="true"
              class="[ right ]"></portal-date>
          </div>
          <div class="bulle-container">
            <mat-form-field class="bulle-name-field">
              <i class="[ icon-cow-1 ]" matPrefix></i>
              <mat-label>{{ 'VERLUSTAUSWAHL.BULLE' | translate }}</mat-label>
              <input formControlName="selectedBulleLabel" matInput readonly>
            </mat-form-field>
            <i class="dummy"></i>
            <mat-form-field class="charge-selection">
              <mat-label>{{ 'VERLUSTAUSWAHL.CHARGE' | translate }}</mat-label>
              <input #charge [matAutocomplete]="chargen" [validateRegex]="'^\\d+$'" formControlName="charge"
                id="charge" matInput>
              <mat-autocomplete #chargen="matAutocomplete">
                @for (charge of selectableChargen; track charge) {
                  <mat-option
                    [value]="charge.spermabestandTechnikerKey.lotNumber">
                    {{ charge.spermabestandTechnikerKey.lotNumber }}
                  </mat-option>
                }
              </mat-autocomplete>
              <i class="[ icon-baseline-arrow_drop_down-24px ]" matSuffix></i>
            </mat-form-field>
          </div>
          <div class="bulle-container">
            <mat-form-field>
              <mat-label>{{ 'VERLUSTAUSWAHL.GRUND' | translate }}</mat-label>
              <mat-select formControlName="verlustgrund">
                <mat-option></mat-option>
                @for (verlustgrund of verlustgruende; track verlustgrund) {
                  <mat-option [value]="verlustgrund">
                    {{ verlustgrund.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field class="right" style="width: 60px">
              <mat-label>{{ 'VERLUSTAUSWAHL.ANZAHL' | translate }}</mat-label>
              <input [validateRegex]="'^([1-9]|[1-9][0-9])$'" formControlName="anzahl" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="box">
          <div class="headline">
            <label>{{ 'VERLUSTAUSWAHL.BETRIEB' | translate }}</label>
            <button (click)="sucheBetrieb()" class="[ c-mat-button no-color-button right ]" mat-button>
              {{ 'BUTTON.ALLE_ANZEIGEN' | translate }}
              <i aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]"></i>
            </button>
          </div>
          <div class="bulle-container">
            <portal-search #searchBetriebInput (search)="searchBetrieb($event)" [automaticSearch]="false"
              [fields]="getSearchFieldDefinitionsBetrieb()"
              [placeholder]="('VERLUSTAUSWAHL.BETRIEB_SEARCH' | translate)"></portal-search>
          </div>
          <div class="bulle-container">
            <mat-form-field class="betrieb-name-field">
              <i class="[ icon-barn ]" matPrefix></i>
              <input formControlName="selectedBetriebLabel" matInput
                placeholder="{{ 'VERLUSTAUSWAHL.BETRIEB' | translate }}"
                readonly>
            </mat-form-field>
          </div>
        </div>
        <div class="[ form-content ]">
          <button (click)="abbrechen()" class="[ c-mat-button no-color-button ]" mat-button>
            <i aria-hidden="true"></i>
            {{ 'BUTTON.ABBRECHEN' | translate }}
          </button>
          <button (click)="hinzufuegen()" class="[ c-mat-button green-button right ]" mat-flat-button>
            <i aria-hidden="true" class="[ icon-plus ]" style="margin-right: 10px"></i>
            {{ 'BUTTON.HINZUFUEGEN' | translate }}
          </button>
        </div>
      </form>
    }
  </div>
</mat-dialog-content>
