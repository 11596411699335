<div class="[ panel ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.TITLE_HTML' | translate }}
      {{ selectedFarm?.oldShortNumber }} - {{ selectedFarm?.adresse?.nachname }}, {{ selectedFarm?.adresse?.vorname }} -
      {{ selectedFarm?.adresse?.ort }}, {{ selectedFarm?.adresse?.ortsteil }}
    </div>
  </div>
  <div class="[ panel-content ]">
    <div class="[ toolbar ]">
      <div class="[ toolbar-left ]">
        <div class="[ buttons ]">
          <button (click)="exportPdf()" [disabled]="this.selected.length === 0"
            class="[ c-mat-button green-button ]" mat-flat-button>
            <i aria-hidden="true" style="margin-right: 10px"></i>
            {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.PRINT' | translate }}
          </button>
        </div>
        <mat-label class="[ label ]">{{ 'LEISTUNGSPROTOKOLL_LANDWIRT.TYP' | translate }}:</mat-label>
        <div id="type">
          <mat-checkbox (change)="loadData(searchFilterPagingResource)" [(ngModel)]="typeBesamung"
            checked="true">{{ 'ENUM.AUFTRAGSPOSITION_TYPE.BESAMUNG' | translate }}
          </mat-checkbox>
          <mat-checkbox (change)="loadData(searchFilterPagingResource)" [(ngModel)]="typeDienstleistung"
            checked="true">{{ 'ENUM.AUFTRAGSPOSITION_TYPE.DIENSTLEISTUNG' | translate }}
          </mat-checkbox>
          <mat-checkbox (change)="loadData(searchFilterPagingResource)" [(ngModel)]="typeAgrarartikel"
            checked="true">{{ 'ENUM.AUFTRAGSPOSITION_TYPE.AGRARARTIKEL' | translate }}
          </mat-checkbox>
        </div>
        <portal-search #searchTechnikerInput (search)="doAndSearch($event)" [fields]="getSearchFieldTechniker()"
          [placeholder]="'TOOLBAR.SUCHBEGRIFF_TECHNIKER_NR' | translate"
          class="[ date-search ]"></portal-search>
        <portal-date-search (search)="doAndSearch($event)" [field]="getSearchFieldDate()" [interval]="true"
          [minDateFrom]="minDate" [takeMinDateOnClear]="true"
          class="[ date-search ]"></portal-date-search>
        @if (isBesamungstechniker || isBapBerater) {
          <div class="[ checkbox ]">
            <mat-checkbox (change)="changeCheckbox()"
              [(ngModel)]="nurEigene">{{ 'LEISTUNGSPROTOKOLL_LANDWIRT.EIGENE_ANZEIGEN' | translate }}
            </mat-checkbox>
          </div>
        }
      </div>
    </div>
    <div class="[ content-table ]">
      <div class="[ c-data-list ]">
        <ngx-datatable
            #leistungsprotokollLandwirtTabelle
          (page)="setPage($event)"
          (sort)="onSort($event)"
          [columnMode]="'force'"
          [count]="currentPage.totalElements"
          [cssClasses]="customClasses"
          [externalPaging]="true"
          [externalSorting]="true"
          [footerHeight]="57"
          [headerHeight]="57"
          [limit]="currentPage.size"
          [loadingIndicator]="tableIsLoading"
          [messages]="tableMessages"
          [offset]="currentPage.number"
          [rowHeight]="25.68"
          [rowIdentity]="rowIdentity"
          [rows]="data"
          [selectAllRowsOnPage]="false"
          [selected]="selected"
          [selectionType]="'multiClick'"
          [sorts]="sortField"
          class="[ c-data-list__table ] "
          style="height:604px">
          <ngx-datatable-column [canAutoResize]="false" [cellClass]="'c-data-list__column-first'" [draggable]="false"
            [resizeable]="false" [sortable]="true" [width]="40">
            <ng-template let-value="value" ngx-datatable-header-template>
              <div class="[ c-data-list__checkbox-column--header ]">
                <span class="[ c-data-list__checkbox-label ]"
                  title="{{ 'LEISTUNGSPROTOKOLL_LANDWIRT.ALL' | translate }}"
                  translate="LEISTUNGSPROTOKOLL_LANDWIRT.ALL"></span>
                <input (change)="selectAll()" [checked]="selectAllOnPage[currentPage.number]" type="checkbox" />
              </div>
            </ng-template>
            <ng-template
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
                let-value="value"
              ngx-datatable-cell-template>
              <input
                (change)="onCheckboxChangeFn($event)"
                [checked]="isSelected"
                type="checkbox"
              />
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [maxWidth]="55" name="{{'LEISTUNGSPROTOKOLL_LANDWIRT.TYP' | translate}}" prop="type">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span
                title="{{value +'_TITLE' | translate }}">{{ value | translate }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
            name="{{'LEISTUNGSPROTOKOLL_LANDWIRT.POSITIONSNUMMER' | translate}}" prop="receiptNumber">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.POSITIONSNUMMER_TITLE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.POSITIONSNUMMER' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [maxWidth]="85" name="{{'LEISTUNGSPROTOKOLL_LANDWIRT.TECHNIKER' | translate}}">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.TECHNIKER_TITLE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.TECHNIKER' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="right-cell" title="{{row.technikerKey.number}}">{{ row.technikerKey.number }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="70" name="{{'LEISTUNGSPROTOKOLL_LANDWIRT.DATUM' | translate}}" prop="auftragsdatum">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <span title="{{value | datexpipe: 'L'}}">{{ value | datexpipe: 'L' }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [maxWidth]="85"
            [sortable]="false" name="{{'LEISTUNGSPROTOKOLL_LANDWIRT.STALL_NR' | translate}}" prop="stallnummer">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.STALL_NR' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.STALL_NR' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [width]="70" name="{{'LEISTUNGSPROTOKOLL_LANDWIRT.LOM' | translate}}" prop="cattleKey.lom">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span (click)="sort()" class="pointer right-cell-header"
                title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.LOM' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.LOM' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="55"
            [sortable]="false" prop="wvb">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.WVB_TITLE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.WVB' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="40"
            [sortable]="false" name="{{'LEISTUNGSPROTOKOLL_LANDWIRT.BOOKING_TYPE' | translate}}">
            <ng-template let-sort="sortFn" ngx-datatable-header-template>
              <span title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.BOOKING_TYPE_TITLE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.BOOKING_TYPE' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.bookingType) {
                <span
                  title="{{ row.bookingType | bookingType }}">{{ row.bookingType | bookingType }}</span>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="40"
            [sortable]="false">
            <ng-template ngx-datatable-header-template>
              <span title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.ANFAHRT_TITLE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.ANFAHRT' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.specialCharge?.includes(besamungsdienstleistungenService.getVitArtikelnummerAnfahrt())) {
                <i
                  class="[ icon-baseline-done-24px ]"></i>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="40"
            [sortable]="false">
            <ng-template ngx-datatable-header-template>
              <span title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.SPERMAPREIS_BERECHNEN_TITLE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.SPERMAPREIS_BERECHNEN' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.berechneSpermapreis) {
                <i
                  class="[ icon-baseline-done-24px ]"></i>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
            [sortable]="false" prop="itemNumber">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.ARTIKELNUMMER_TITLE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.ARTIKELNUMMER' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [sortable]="false"
            [width]="160" prop="bezeichnung">
            <ng-template ngx-datatable-header-template>
              <span title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.BEZEICHNUNG_TITLE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.BEZEICHNUNG' | translate }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'"
            [maxWidth]="85" prop="bulle.label">
            <ng-template ngx-datatable-header-template>
              <span title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.BEHANDLUNGSART_TITLE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.BEHANDLUNGSART' | translate }}</span>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              @if (row.bulle) {
                <span
                  title="{{ row.bulle.label | spermaart }}">{{ row.bulle.label | spermaart }}</span>
              }
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [headerClass]="'c-data-list__header--default'" [maxWidth]="100"
            [sortable]="false"
            name="{{'LEISTUNGSPROTOKOLL_LANDWIRT.MENGE' | translate}}" prop="quantity">
            <ng-template ngx-datatable-header-template>
              <span class="right-cell-header" title="{{'LEISTUNGSPROTOKOLL_LANDWIRT.MENGE' | translate}}">
              {{ 'LEISTUNGSPROTOKOLL_LANDWIRT.MENGE' | translate }}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="right-cell" title="{{ value }}">{{ value }}</div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template>
              <portal-table-footer-without-paging [rows]="data" [selected]="selected"
                [table]="leistungsprotokollLandwirtTabelle" class="[ table-footer ]">
              </portal-table-footer-without-paging>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
        <div class="position-counter">
          <span class="counter-item">{{ 'LEISTUNGSPROTOKOLL_LANDWIRT.GESAMTE_BESAMUNGEN' | translate }}: {{
                  positionCounter
                      ? (positionCounter.besamungEB + positionCounter.besamungWB) : 0
            }}
            (EB:{{ positionCounter?.besamungEB }};WB:{{ positionCounter?.besamungWB }})</span>
          <span class="counter-item">{{ 'LEISTUNGSPROTOKOLL_LANDWIRT.AGRAR_ARTIKEL'|translate }}
            : {{ positionCounter?.agrar }}</span>
          <span class="counter-item">{{ 'LEISTUNGSPROTOKOLL_LANDWIRT.DIENSTLEISTUNGEN'|translate }}:
            {{ positionCounter?.dienstleistung }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
