<header class="[ c-header ]">
  <div class="[ c-header__logo-container ]">
    <img alt="SVG" class="[ c-header__logo ]" src="{{ logoPath }}" />
  </div>
  <div class="[ c-header__text ]">
    <label>{{ 'HEADER.TITLE' | translate }}</label>
  </div>
  <nav class="[ c-header__nav-bar ]">
    <ul class="[ c-header__nav-container ]">
      @for (menuItem of navigation; track menuItem; let index = $index) {
        @if (checkAuthentication(menuItem['roles'])) {
          <li (mouseenter)="mouseHovering('subMenu-' + index, 1)"
            (mouseleave)="mouseLeft(1)"
            class="[ c-header__menu-item ]">
            <a [ngClass]="{'c-header__item-link--noClick': !menuItem['link']}"
              class="[ c-header__item-link ]"
              routerLink="{{ menuItem['link'] }}"
              translate="{{ menuItem['item'] }}">
            </a>
            @if (menuItem['subMenu'] && hover && openSubMenu === 'subMenu-' + index) {
              <ul
                appResizeSubMenu
                class="[ c-header__sub-nav-container ]"
                id="subMenu-{{index}}">
                @for (subMenuItem of menuItem['subMenu']; track subMenuItem) {
                  <li
                    [hidden]="!checkAuthentication(subMenuItem['roles'])"
                    class="[ c-header__sub-menu-item ]">
                    <a [ngClass]="{'c-header__item-link--noClick': !subMenuItem['link']}"
                      [queryParams]="subMenuItem['queryParams']?subMenuItem['queryParams']:null"
                      [routerLink]="subMenuItem['link']"
                      class="[ c-header__item-link ]"
                      translate="{{ subMenuItem['item'] }}">
                    </a>
                  </li>
                }
              </ul>
            }
          </li>
        }
      }
    </ul>
    @if (isBesamungstechniker && user) {
      <div class="[ c-header__text ]">
        <label>{{
                (this.vertreter && this.vertreter.length) ?
                    'V: ' + (this.vertreter.length <= 3
                        ? this.vertreter
                        : this.vertreter.slice(0, 3) + ',...') : ''
          }}
        </label>
      </div>
    }
    @if (!authenticated) {
      <button (click)="login()" class="[ c-header__button ]"
        title="{{ 'HEADER.LOGIN' | translate }}">
        <i aria-hidden="true" class="[ icon-account ]  [ c-header__icon ]"></i>
      </button>
    }
    @if (authenticated) {
      <button (click)="logout()" class="[ c-header__button ]"
        title="{{ 'HEADER.LOGOUT' | translate }}">
        <i aria-hidden="true" class="[ icon-account ]  [ c-header__icon ]"></i>
      </button>
    }
    @if (user) {
      <div class="[ c-header__text ]">
        <label>{{ user }}</label>
      </div>
    }
  </nav>
</header>
