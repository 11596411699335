import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { Inseminator } from '../../model/Inseminator';
import { TechnikerKey } from '../../model/techniker-key';
import { ServerPaginationService } from '../serverPaginationService';

@Injectable({
  providedIn: 'root'
})
export class InseminatorService implements ServerPaginationService<Inseminator> {
  private url = '/api/v1/inseminator';

  constructor(private http: HttpClient) {}

  /**
   * Setzt oder entfernt den angemeldeten Benutzer, als Vertretung, bei den uebergebenen Technikern.
   *
   * @param technikerKeys Array<TechnikerKey>
   */
  changeVertretung(technikerKeys: Array<TechnikerKey>): Observable<void> {
    return this.http.put<void>(this.url + '/changeVertretung', technikerKeys);
  }

  /**
   * Liefert alle Techniker bei denen der angemeldete Techniker Vertreter ist.
   */
  findAllVertreterTechniker(): Observable<Array<TechnikerKey>> {
    return this.http.get<Array<TechnikerKey>>(this.url + `/findAllVertreterTechniker`);
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource die Betriebe.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return Observable<Page<Cattle>>
   */
  loadData(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Inseminator>> {
    return this.http.post<Page<Inseminator>>(this.url + '/listPageWithoutSelf', searchFilterPagingResource);
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource die Techniker.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return Observable<Page<Cattle>>
   */
  loadDataForUser(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Inseminator>> {
    return this.http.post<Page<Inseminator>>(this.url + '/listPageWithoutSelfForUser', searchFilterPagingResource);
  }
}
