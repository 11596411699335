import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { DateComponent } from '../../../../../core/component/date/date.component';
import { FilterCriteria } from '../../../../../core/filter/filterCriteria';
import { FilterCriteriaResource } from '../../../../../core/filter/filterCriteriaResource';
import { FilterType } from '../../../../../core/filter/filterType';
import { Page } from '../../../../../core/filter/page';
import { SearchFilterPagingResource } from '../../../../../core/filter/searchFilterPagingResource';
import { DatexPipe } from '../../../../../core/pipe/datex.pipe';
import { EnumKeysPipe } from '../../../../../core/pipe/enum-keys.pipe';
import { SpermaartPipe } from '../../../../../core/pipe/spermaart.pipe';
import { AuftragspositionType } from '../../../../../enumeration/auftragsposition-type.enum';
import { Auftragspositionsstatus } from '../../../../../enumeration/auftragspositionsstatus.enum';
import { MatchMode } from '../../../../../enumeration/match-mode.enum';
import { SpeciesType } from '../../../../../enumeration/species-type.enum';
import { Suchfokus } from '../../../../../enumeration/suchfokus.enum';
import { Auftrag } from '../../../../../model/auftrag';
import { Auftragsposition } from '../../../../../model/auftragsposition';
import { Besamungskommentar } from '../../../../../model/besamungskommentar';
import { Cattle } from '../../../../../model/cattle';
import { DateValidation } from '../../../../../model/dateValidation';
import { Spermaartikel } from '../../../../../model/spermaartikel';
import { SpermabestandTechniker } from '../../../../../model/spermabestand-techniker';
import { BesamungsdienstleistungenService } from '../../../../../service/besamungsdienstleistungen/besamungsdienstleistungen.service';
import { BesamungskommentarService } from '../../../../../service/besamungskommentar/besamungskommentar.service';
import { CattleService } from '../../../../../service/cattle/cattle.service';
import { CustomErrorHandlerService } from '../../../../../service/custom-error-handler/custom-error-handler.service';
import { ModaKeycloakService } from '../../../../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../../../../service/moda-translate.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { SpermaartikelService } from '../../../../../service/spermaartikel/spermaartikel.service';
import { DateUtils } from '../../../../../utils/date-utils';
import { DialogUtils } from '../../../../../utils/dialog-utils';
import { Utils } from '../../../../../utils/utils';
import { BulleModalComponent } from '../../../../bulle-modal/bulle-modal.component';
import { CattleDetailModalComponent } from '../../../../cattle-detail-modal/cattle-detail-modal.component';
import { CattleModalComponent } from '../../../../cattle-modal/cattle-modal.component';
import { FarmDetailModalComponent } from '../../../../farm/farm-detail-modal/farm-detail-modal.component';
import { NewCattleComponent } from '../../../../new-cattle/new-cattle.component';
import { SearchFieldDefinition } from '../../../../search/search-field-definition';
import { SearchComponent } from '../../../../search/search.component';
import { WarenauswahlComponent } from '../warenauswahl.component';

class AuftragspositionResult {
  pos: Auftragsposition;

  isNewPosition: boolean;
}

@Component({
  selector: 'portal-besamung',
  templateUrl: './besamung.component.html',
  styleUrls: ['./besamung.component.scss']
})
export class BesamungComponent implements OnInit, OnDestroy, AfterViewInit {
  selectedDate: DateValidation;

  selectedCattle: Cattle;

  selectedBulle: Spermaartikel;

  cattleDialogRef;

  bulleDialogRef;

  cattleDetailDialogRef;

  besamungskommentare: Array<Besamungskommentar>;

  besamungskommentarSubscription: Subscription;

  @Input() auftrag: Auftrag;

  @ViewChild('searchKuhInput') searchKuhInput: SearchComponent;

  @ViewChild('searchBulleInput') searchBulleInput: SearchComponent;

  @ViewChild('auftragsdatum') auftragsdatumInput: ElementRef;

  @ViewChild('dateComponent') dateComponent: DateComponent;

  checkAnpaarungsvorschlaegeFirstSubscription: Subscription;

  checkAnpaarungsvorschlaegeSecondSubscription: Subscription;

  letzterBulleSubscription: Subscription;

  searchBulleSubscription: Subscription;

  searchKuhSubscription: Subscription;

  selectAnpaarungsvorschlagSubscription: Subscription;

  minDate: Moment;

  editingAuftragsposition: Auftragsposition;

  selectedRow = [];

  besamungForm: UntypedFormGroup;

  selectableChargen: Array<SpermabestandTechniker>;

  anrechtssperma = false;

  constructor(
    private readonly dialogRef: MatDialogRef<WarenauswahlComponent>,
    private readonly dialog: MatDialog,
    private readonly cattleService: CattleService,
    private readonly datexPipe: DatexPipe,
    private readonly enumKeysPipe: EnumKeysPipe,
    private readonly besamungskommentarService: BesamungskommentarService,
    private readonly customErrorhandlerService: CustomErrorHandlerService,
    private readonly spermaartikelService: SpermaartikelService,
    private readonly spermaartPipe: SpermaartPipe,
    private readonly notificationService: NotificationService,
    private readonly modaTranslateService: ModaTranslateService,
    private readonly form: UntypedFormBuilder,
    private readonly modaKeycloakService: ModaKeycloakService,
    readonly besamungsdienstleistungenService: BesamungsdienstleistungenService
  ) {}

  /**
   * Schliesst den Modalen Dialog.
   */
  abbrechen() {
    this.dialogRef.close();
  }

  /**
   * Uebernehmen der Werte aus der Auftragsposition.
   * @param element Auftragsposition
   * @param besamungskommentare Liste von Besamungskommentare
   */
  public assignFromAuftragspositionToForm(element: Auftragsposition, besamungskommentare: Array<Besamungskommentar>) {
    this.editingAuftragsposition = element;
    this.selectedBulle = element.bulle;
    this.selectedCattle = element.cattle;
    this.besamungForm.patchValue({ wvb: element.wvb });
    this.dateComponent.setDate(moment(element.inseminationDate));
    if (besamungskommentare !== null) {
      this.besamungskommentare = besamungskommentare;
    }
    if (element.comment && element.comment !== '') {
      for (const comment of this.besamungskommentare || []) {
        if (comment.wert.toString() === element.comment + '') {
          this.besamungForm.patchValue({ besamungskommentar: comment });
          break;
        }
      }
    } else {
      this.besamungForm.patchValue({ besamungskommentar: null });
    }

    this.besamungsdienstleistungenService.getVitDienstleistungen().subscribe((besamungdienstleistungen) => {
      for (const besamungdienstleistung of besamungdienstleistungen) {
        this.besamungForm.patchValue({ [besamungdienstleistung.vitArtikelnummer]: false });
      }
    });
    if (element.specialCharge) {
      for (const dienstleistung of element.specialCharge) {
        this.besamungForm.patchValue({ ['' + dienstleistung]: true });
      }
    }

    if (element.specialMark) {
      for (const dienstleistung of element.specialMark) {
        this.besamungForm.patchValue({ [dienstleistung]: true });
      }
    }

    this.setBullendaten();
    this.besamungForm.patchValue({ berechneSpermapreis: element.berechneSpermapreis });
    if (element && element.lotNumber) {
      this.resetChoosenAnpaarungsvorschlaege();
      this.besamungForm.patchValue({ charge: element.lotNumber });
    }
    this.selectedDate.date = moment(element.inseminationDate);
    this.selectedRow.push(element);
  }

  /**
   * Funktion um das Validierungsformular zu bauen und in welcher Form die Validierung stattfindet.
   * Es muessen alle Felder die angegeben werden, welche das formControlName Attribute aufweisen.
   * Zusaetzlich wird hier bestimmt mit welchem Event (blur, change oder submit) die Validierung ausgeführt wird.
   */
  public buildForm() {
    const formOptions = {
      selectedCattle: new UntypedFormControl(
        {
          value: this.selectedCattle
        },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ),
      selectedBulleLabel: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ),
      charge: new UntypedFormControl(
        {
          value: '',
          disabled: false
        },
        {
          validators: [Validators.required, Validators.minLength(4)],
          updateOn: 'change'
        }
      ),
      wvb: new UntypedFormControl(
        {
          value: this.selectedCattle && this.selectedCattle.wvb,
          disabled: false
        },
        {
          validators: [Validators.required],
          updateOn: 'blur'
        }
      ),
      berechneSpermapreis: new UntypedFormControl(),
      besamungskommentar: new UntypedFormControl()
    };

    this.besamungsdienstleistungenService.getVitDienstleistungen().subscribe((besamungsdienstleistungen) => {
      for (const besamungdienstleistung of besamungsdienstleistungen) {
        formOptions[besamungdienstleistung.vitArtikelnummer] = new UntypedFormControl({
          value: null,
          disabled: besamungdienstleistung.vitArtikelnummer === this.besamungsdienstleistungenService.getVitArtikelnummerDurchfuehrung()
        });
      }
      this.besamungForm = this.form.group(formOptions);
    });
  }

  /**
   * Wenn der erste Besamung erfasst wird, soll der Anfahrt true sein.
   * Deswegen pruefen wir hier, ob der Anfahrt gecheckt werden soll oder nicht.
   */
  checkAnfahrtCondition(): boolean {
    for (const pos of this.auftrag.positionen) {
      if (pos.type === AuftragspositionType.BESAMUNG) {
        return false;
      }
    }
    return true;
  }

  /**
   * Prueft, ob fuer die Anpaarungsvorschlaege ein eindeutiges Sperma vorhanden ist, falls ja wird dieses ausgewaehlt.
   */
  checkAnpaarungsvorschlaege() {
    if (this.selectedCattle.anpaarungen.length > 0) {
      this.resetChoosenAnpaarungsvorschlaege();
      const searchFilterPagingResource0 = new SearchFilterPagingResource();
      searchFilterPagingResource0.orFilterCriteriaResourceList.push(
        new FilterCriteriaResource('herdbookNumber', this.selectedCattle.anpaarungen[0].herdbookNumber, FilterType.STRING)
      );
      this.checkAnpaarungsvorschlaegeFirstSubscription = this.spermaartikelService
        .loadDataByBetriebsschluessel(this.auftrag.betriebsschluessel, searchFilterPagingResource0)
        .subscribe((result) => {
          if (result.content.length === 1) {
            this.selectedBulle = result.content[0];
            this.setBullendaten();
            (document.getElementById('anpaarungsvorschlag0') as HTMLButtonElement).classList.add('bulle-button-active');
          } else {
            if (this.selectedCattle.anpaarungen.length > 1) {
              this.selectedCattle.anpaarungen.sort((anp1, anp2) => {
                if (anp1.adviceNumber > anp2.adviceNumber) {
                  return 1;
                } else if (anp1.adviceNumber < anp2.adviceNumber) {
                  return -1;
                } else {
                  return 0;
                }
              });
              const searchFilterPagingResource1 = new SearchFilterPagingResource();
              searchFilterPagingResource1.orFilterCriteriaResourceList.push(
                new FilterCriteriaResource('herdbookNumber', this.selectedCattle.anpaarungen[1].herdbookNumber, FilterType.STRING)
              );
              this.checkAnpaarungsvorschlaegeSecondSubscription = this.spermaartikelService
                .loadDataByBetriebsschluessel(this.auftrag.betriebsschluessel, searchFilterPagingResource1)
                .subscribe((result1) => {
                  if (result1.content.length === 1) {
                    this.selectedBulle = result1.content[0];
                    this.setBullendaten();
                    (document.getElementById('anpaarungsvorschlag1') as HTMLButtonElement).classList.add('bulle-button-active');
                  } else {
                    if (this.selectedCattle.anpaarungen.length > 2) {
                      this.selectAnpaarungsvorschlag(2, true);
                    }
                  }
                });
            }
          }
        });
    }
  }

  /**
   * Ueberprueft, ob Aenderungen in der Maske vorhanden sind.
   */
  public checkHasFormDataChanged() {
    const hasData = this.selectedBulle || this.selectedCattle || this.besamungForm.touched;
    if (!hasData) {
      return false;
    }
    if (this.editingAuftragsposition) {
      const commentDifferent = (): boolean => {
        const existingComment = this.editingAuftragsposition.comment || null;
        const formComment = this.besamungForm.value.besamungskommentar;
        const newComment = formComment ? formComment.wert : null;
        return existingComment !== newComment;
      };

      let selectedDienstleistungen = this.editingAuftragsposition.specialCharge;
      if (this.editingAuftragsposition.specialMark !== '') {
        selectedDienstleistungen = selectedDienstleistungen.concat(this.editingAuftragsposition.specialMark.split(','));
      }
      if (
        this.editingAuftragsposition.bulle !== this.selectedBulle ||
        this.editingAuftragsposition.cattle !== this.selectedCattle ||
        !this.compareSelectedDienstleistungenWithAuftragsposition(selectedDienstleistungen) ||
        this.editingAuftragsposition.wvb !== this.besamungForm.value.wvb ||
        !moment(this.editingAuftragsposition.inseminationDate).isSame(moment(this.selectedDate.date), 'day') ||
        commentDifferent() ||
        this.editingAuftragsposition.lotNumber !== this.besamungForm.value.charge ||
        this.editingAuftragsposition.berechneSpermapreis !== this.besamungForm.controls.berechneSpermapreis.value
      ) {
        return true;
      }
    } else {
      return hasData;
    }
    return false;
  }

  /**
   * Erstellt eine Auftragsposition.
   */
  createAuftragsposition(besamungsDienstleistungen: string[], auftragPositionType: AuftragspositionType): AuftragspositionResult {
    const pos: Auftragsposition = this.editingAuftragsposition || new Auftragsposition();
    pos.type = auftragPositionType;
    if (this.auftrag.auftragsnummer) {
      pos.auftragsnummer = this.auftrag.auftragsnummer;
    }
    pos.cattle = this.selectedCattle;
    pos.cattleKey = this.selectedCattle.cattleKey;
    pos.alphaLom = this.selectedCattle.alphaLom;
    pos.bulle = this.selectedBulle;
    pos.itemNumber = this.selectedBulle.itemNumber ? this.selectedBulle.itemNumber : this.editingAuftragsposition.itemNumber;
    pos.rasse = this.selectedCattle.rasse;
    pos.lom = this.selectedCattle.alphaLom;
    pos.bezeichnung = this.selectedBulle.name + '(' + this.selectedBulle.herdbookNumber + ')';
    pos.species = SpeciesType.CATTLE;
    pos.stallnummer = this.selectedCattle.stallNummer;
    pos.wvb = this.besamungForm.value.wvb;
    pos.inseminationDate = moment(this.selectedDate.date).startOf('day').add(moment.duration(10, 'hours')).toDate();
    pos.lotNumber = this.besamungForm.value.charge;
    pos.anrechtssperma = this.anrechtssperma;
    pos.comment = this.besamungForm.value.besamungskommentar ? this.besamungForm.value.besamungskommentar.wert : '';
    pos.berechneSpermapreis = this.besamungForm.controls.berechneSpermapreis.value;
    pos.auftragsdatum = this.auftrag.auftragsdatum;
    pos.auftragspositionsstatus = Auftragspositionsstatus.IM_WARENKORB;
    pos.shortNumber = this.auftrag.shortNumber;
    pos.specialCharge = [];
    pos.specialMark = '';
    const specialmarks = [];
    const gruppe = this.besamungsdienstleistungenService.getArtikelGruppeMap().get(205);
    for (const besamungdienstleistung of besamungsDienstleistungen) {
      if (gruppe && gruppe.includes(besamungdienstleistung)) {
        specialmarks.push(besamungdienstleistung);
      } else {
        pos.specialCharge.push(besamungdienstleistung);
      }
    }
    pos.specialMark = specialmarks.join(',');

    if (this.editingAuftragsposition) {
      this.editingAuftragsposition = null;
      return {
        pos,
        isNewPosition: false
      };
    }
    return {
      pos,
      isNewPosition: true
    };
  }

  /**
   * Loescht die selektierte Zeile aus der erfassten Besamungstabelle.
   * @param element zu loeschende Auftragsposition
   */
  public delete(element: Auftragsposition) {
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.BESAMUNGSDATEN_DELETE.CONFIRMATION_DIALOG.HEADLINE'),
      message: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.BESAMUNGSDATEN_DELETE.CONFIRMATION_DIALOG.MESSAGE')
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const i = this.auftrag.positionen.indexOf(element);
        if (i > -1) {
          this.auftrag.positionen.splice(i, 1);
          this.auftrag.positionen = [...this.auftrag.positionen]; // refresh the dataSource
          if (this.editingAuftragsposition === element) {
            this.editingAuftragsposition = null;
            this.resetFields();
          }
        }
      }
    });
  }

  /**
   * Ermittelt die Anzahl der Besamungen.
   */
  determineAnzahlBesamungen(): number {
    return this.auftrag.positionen.filter((value) => value.type === AuftragspositionType.BESAMUNG).length;
  }

  /**
   * Liefert alle Auftragspositionen fuer Besamung.
   * @param auftrag Auftrag
   */
  determineAuftragspositionForBesamung(auftrag: Auftrag): Array<Auftragsposition> {
    return this.auftrag.positionen.filter((value) => value.type === AuftragspositionType.BESAMUNG);
  }

  /**
   * Bearbeitet die selektierte Zeile aus der erfassten Besamungstabelle.
   * @param element Auftragsposition
   */
  editAuftragsposition(element: Auftragsposition) {
    if (this.checkHasFormDataChanged()) {
      if (this.isRequiredFormDataAvailable()) {
        const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
          headline: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.BESAMUNGSDATEN_EDIT.CONFIRMATION_DIALOG.HEADLINE'),
          message: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.BESAMUNGSDATEN_EDIT.CONFIRMATION_DIALOG.MESSAGE')
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.saveAuftragsposition();
          }
          this.assignFromAuftragspositionToForm(element, null);
        });
      } else {
        const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
          headline: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.BESAMUNGSDATEN_EDIT.CONFIRMATION_DIALOG.HEADLINE'),
          message: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.BESAMUNGSDATEN_EDIT.CONFIRMATION_DIALOG.MESSAGE_VERWERFEN')
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.assignFromAuftragspositionToForm(element, null);
          }
        });
      }
    } else {
      this.assignFromAuftragspositionToForm(element, null);
    }
  }

  /**
   * Loescht die bisherigen Eingaben auf dieser Seite.
   */
  felderLeeren() {
    const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
      headline: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.FELDER_LEEREN.CONFIRMATION_DIALOG.HEADLINE'),
      message: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.FELDER_LEEREN.CONFIRMATION_DIALOG.MESSAGE')
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.resetFields();
      }
    });
  }

  /**
   * Lom Kennzeichen ohne die ersten 4 Ziffern fuer die Erfassungstabelle
   * OM (ohne die ersten 4 Ziffern) Es wird statt *DE 03 552 99987 "55299987" angezeigt werden .
   */
  public getLomKnz(row: Auftragsposition) {
    return row && row.cattle ? row.cattle.cattleKey.lom.substring(row.cattle.cattleKey.lom.length - 8, row.cattle.cattleKey.lom.length) : '';
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitionsBulle(): SearchFieldDefinition[] {
    return [new SearchFieldDefinition('name', FilterType.STRING), new SearchFieldDefinition('herdbookNumber', FilterType.STRING)];
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitionsKuh(): SearchFieldDefinition[] {
    if (this.auftrag.suchfokus === Suchfokus.STALLNUMMER) {
      return [new SearchFieldDefinition('name', FilterType.STRING), new SearchFieldDefinition('stallNummer', FilterType.STRING)];
    } else if (this.auftrag.suchfokus === Suchfokus.LOM) {
      return [
        new SearchFieldDefinition('name', FilterType.STRING),
        new SearchFieldDefinition('alphaLom', FilterType.STRING),
        new SearchFieldDefinition('cattleKey.lom', FilterType.STRING)
      ];
    } else {
      return [
        new SearchFieldDefinition('name', FilterType.STRING),
        new SearchFieldDefinition('alphaLom', FilterType.STRING),
        new SearchFieldDefinition('cattleKey.lom', FilterType.STRING),
        new SearchFieldDefinition('stallNummer', FilterType.STRING)
      ];
    }
  }

  /**
   * Initialisierung der Min- und Maxwerte fuer den Datepicker.
   */
  initDateRange() {
    const letzteBesamung =
      this.selectedCattle && this.selectedCattle.besamungen.length !== 0 && this.selectedCattle.letzteBesamung
        ? moment(this.selectedCattle.letzteBesamung.inseminationDate)
        : DateUtils.getMinDate();
    this.minDate = letzteBesamung.add(moment.duration(24, 'hours'));
  }

  /**
   * Check Pflichtfelder.
   */
  isRequiredFormDataAvailable() {
    return (
      this.selectedCattle &&
      this.selectedBulle &&
      this.selectedBulle.label &&
      this.besamungForm.value.wvb &&
      this.besamungForm.value.charge.trim() !== '' &&
      this.besamungForm.value.charge.length > 3 &&
      this.selectedDate &&
      this.selectedDate.date &&
      this.selectedDate.isValid
    );
  }

  /**
   * Waehlt das Bullensperma der letzten durchgefuehrten Besamung fuer die ausgewaehlte Kuh.
   */
  letzterBulle() {
    if (this.selectedCattle && this.selectedCattle.letzteBesamung) {
      const searchFilterPagingResource = new SearchFilterPagingResource();
      searchFilterPagingResource.andFilterCriteriaResourceList.push(
        new FilterCriteriaResource('herdbookNumber', this.selectedCattle.letzteBesamung.rind.herdbookNumber, FilterType.STRING),
        new FilterCriteriaResource('itemNumber', this.selectedCattle.letzteBesamung.itemNumber, FilterType.STRING)
      );
      this.letzterBulleSubscription = this.spermaartikelService
        .loadDataByBetriebsschluessel(this.auftrag.betriebsschluessel, searchFilterPagingResource)
        .subscribe(
          (result) => {
            if (result && result.content && result.content.length > 0) {
              this.selectedBulle = result.content[0];
              this.resetChoosenAnpaarungsvorschlaege();
              this.setBullendaten(true);
            } else {
              this.notificationService.showInfoNotification('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.LETZTER_KEIN_SPERMAARTIKEL_VORHANDEN');
            }
          },
          (error) => this.customErrorhandlerService.handleError(error)
        );
    } else {
      this.notificationService.showInfoNotification('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.LETZTER');
    }
  }

  /**
   * Markiert den Anpaarungsvorschlag wenn dieser gesucht und ausgewaehlt wurde.
   */
  markAnpaarungsvorschlag(index: number) {
    if (this.selectedCattle && this.selectedCattle.anpaarungen.length !== 0) {
      const vorschlagIsBulleIndex = this.selectedCattle.anpaarungen.findIndex(
        (anpaarung) => anpaarung && this.selectedBulle && anpaarung.herdbookNumber === this.selectedBulle.herdbookNumber
      );
      return vorschlagIsBulleIndex === index;
    }
    return false;
  }

  /**
   * Oeffnet den Dialog um ein neues Tier anzulegen.
   */
  neuesTier() {
    const dialogRef = this.dialog.open(NewCattleComponent, {
      minWidth: '330px',
      panelClass: 'vost-modal-dialog',
      data: {
        betriebsschluessel: this.auftrag.betriebsschluessel
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedCattle = result;
        this.besamungForm.patchValue({ wvb: null });
        this.besamungForm.patchValue({ besamungskommentar: null });
        this.besamungForm.patchValue({ [this.besamungsdienstleistungenService.getVitArtikelnummerDurchfuehrung()]: true });
        if (this.checkAnfahrtCondition()) {
          this.besamungForm.patchValue({ [this.besamungsdienstleistungenService.getVitArtikelnummerAnfahrt()]: true });
        }
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const selector = document.getElementById('searchKuhId');
      if (selector) {
        const searchElement: HTMLElement = selector.querySelector('input');
        searchElement.focus();
      }
    }, 500);
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.besamungskommentarSubscription);
    Utils.unsubscribeSubscription(this.checkAnpaarungsvorschlaegeFirstSubscription);
    Utils.unsubscribeSubscription(this.checkAnpaarungsvorschlaegeSecondSubscription);
    Utils.unsubscribeSubscription(this.letzterBulleSubscription);
    Utils.unsubscribeSubscription(this.searchBulleSubscription);
    Utils.unsubscribeSubscription(this.searchKuhSubscription);
    Utils.unsubscribeSubscription(this.selectAnpaarungsvorschlagSubscription);
  }

  ngOnInit() {
    this.initDateRange();
    this.buildForm();
    this.selectedDate = new DateValidation(this.auftrag.auftragsdatum, true);
    this.besamungskommentarSubscription = this.besamungskommentarService.loadData().subscribe(
      (data) => {
        this.besamungskommentare = data;
      },
      (error) => this.customErrorhandlerService.handleError(error)
    );
    for (const pos of this.auftrag.positionen) {
      if (pos.type === AuftragspositionType.BESAMUNG) {
        this.cattleService.loadByCattleKey(pos.cattleKey).subscribe((cattle) => {
          pos.cattle = cattle;
          if (pos === this.editingAuftragsposition) {
            this.selectedCattle = cattle;
          }
        });
      }
    }
  }

  /**
   * Erstellen der Auftragsposition beenden.
   */
  onFertig() {
    const hasData = this.selectedBulle || this.selectedCattle || this.besamungForm.touched;
    if (!hasData) {
      this.dialogRef.close(this.auftrag);
    }
    if (this.isRequiredFormDataAvailable()) {
      if (this.checkHasFormDataChanged()) {
        const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
          headline: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.BESAMUNGSDATEN_EDIT_FERTIG.CONFIRMATION_DIALOG.HEADLINE'),
          message: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.BESAMUNGSDATEN_EDIT_FERTIG.CONFIRMATION_DIALOG.MESSAGE')
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.saveAuftragsposition().subscribe((data) => {
              if (data) {
                this.dialogRef.close(this.auftrag);
              }
            });
          } else {
            this.dialogRef.close(this.auftrag);
          }
        });
      } else {
        this.dialogRef.close(this.auftrag);
      }
    }
  }

  onRowSelected({ selected }) {
    this.selectedRow.splice(0, this.selectedRow.length);
    this.selectedRow.push(...selected);
  }

  /**
   * Oeffnet den TierdetailView
   */
  public openCattleInfoDialog() {
    this.cattleDetailDialogRef = this.dialog.open(CattleDetailModalComponent, {
      width: '1216px',
      panelClass: 'vost-modal-dialog',
      data: {
        cattle: this.selectedCattle
      }
    });
  }

  /**
   * Oeffnet den Betriebdetail-Dialog.
   */
  openFarmDetailDialog() {
    const farmDetailDialogRef = this.dialog.open(FarmDetailModalComponent, {
      width: '1261px',
      panelClass: 'vost-modal-dialog',
      data: {
        betriebsschluessel: this.auftrag.betriebsschluessel
      }
    });
    farmDetailDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.auftrag.suchfokus = result;
      }
    });
  }

  /**
   * Nimmt die Selektion der Anpaarungsvorschlaege raus, falls eine vorhanden ist.
   */
  resetChoosenAnpaarungsvorschlaege() {
    if (document.getElementById('anpaarungsvorschlag0') as HTMLButtonElement) {
      (document.getElementById('anpaarungsvorschlag0') as HTMLButtonElement).classList.remove('bulle-button-active');
    }
    if (document.getElementById('anpaarungsvorschlag1') as HTMLButtonElement) {
      (document.getElementById('anpaarungsvorschlag1') as HTMLButtonElement).classList.remove('bulle-button-active');
    }
    if (document.getElementById('anpaarungsvorschlag2') as HTMLButtonElement) {
      (document.getElementById('anpaarungsvorschlag2') as HTMLButtonElement).classList.remove('bulle-button-active');
    }
  }

  /**
   * {@inheritDoc}
   */
  rowIdentity(row: Auftragsposition) {
    return row.id || row.cattleKey;
  }

  /**
   * Erstellen der Auftragsposition.
   */
  saveAuftragsposition(): Observable<boolean> {
    if (this.checkSameLomExists()) {
      return of(false);
    }
    const charge = this.besamungForm.value.charge;
    if (!this.isRequiredFormDataAvailable()) {
      this.notificationService.showInfoNotification('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.PFLICHTFELDER');
      return of(false);
    } else {
      if (+charge === 0) {
        this.notificationService.showInfoNotification('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.NULL_CHARGE');
        this.besamungForm.controls.charge.setErrors({ incorrect: true });
        return of(false);
      }
      if (!this.chargeExists(charge)) {
        const subject = new Subject<boolean>();
        const dialogRef = DialogUtils.openConfirmationDialog(this.dialog, {
          headline: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.CHARGEN_EDIT.CONFIRMATION_DIALOG.HEADLINE'),
          message: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.CHARGEN_EDIT.CONFIRMATION_DIALOG.MESSAGE')
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            subject.next(true);
            this.createOrUpdateAuftragsposition();
          } else {
            subject.next(false);
          }
        });
        return subject;
      } else {
        this.createOrUpdateAuftragsposition();
      }
      return of(true);
    }
  }

  /**
   * Stoesst die Suche eines Bullen an.
   *
   * @param value Value
   */
  searchBulle(value: FilterCriteria) {
    const searchFilterPagingResource = new SearchFilterPagingResource();
    if (value) {
      searchFilterPagingResource.orFilterCriteriaResourceList = value.getFilterCriteria();
      this.searchBulleSubscription = this.spermaartikelService
        .loadDataByBetriebsschluessel(this.auftrag.betriebsschluessel, searchFilterPagingResource)
        .subscribe(
          (result) => {
            if (result.content.length === 1) {
              this.selectedBulle = result.content[0];
              this.resetChoosenAnpaarungsvorschlaege();
              this.setBullendaten();
            } else {
              this.sucheBulle(searchFilterPagingResource.orFilterCriteriaResourceList);
            }
          },
          (error) => this.customErrorhandlerService.handleError(error)
        );
    } else {
      searchFilterPagingResource.orFilterCriteriaResourceList = [];
    }
  }

  /**
   * Stoesst die Suche einer Kuh an.
   *
   * @param value Value
   */
  searchKuh(value: FilterCriteria) {
    const searchFilterPagingResource = new SearchFilterPagingResource();
    if (value) {
      value.getFilterCriteria().forEach((filtercriteria) => (filtercriteria.matchMode = MatchMode.EXACT));
      searchFilterPagingResource.orFilterCriteriaResourceList = value.getFilterCriteria();
      this.searchKuhSubscription = this.cattleService.loadDataWithPath([this.auftrag.betriebsschluessel], searchFilterPagingResource, 'Kuh aktiv').subscribe(
        (result) => {
          if (!result.content || (result.content && !result.content.length)) {
            value.getFilterCriteria().forEach((filtercriteria) => (filtercriteria.matchMode = MatchMode.CONTAINING));
            this.cattleService.loadDataWithPath([this.auftrag.betriebsschluessel], searchFilterPagingResource, 'Kuh aktiv').subscribe(
              (resultCattle) => {
                this.processData(resultCattle, searchFilterPagingResource);
              },
              (error) => this.customErrorhandlerService.handleError(error)
            );
          } else {
            this.processData(result, searchFilterPagingResource);
          }
        },
        (error) => this.customErrorhandlerService.handleError(error)
      );
    } else {
      searchFilterPagingResource.orFilterCriteriaResourceList = [];
    }
  }

  /**
   * Selektiert den ausgewaehlten Anpaarungsvorschlag und ermittelt die weiteren Daten.
   *
   * @param index Index
   * @param doNothingByMoreThenOneResult Flag, ob was bei mehreren Ergebnissen getan werden soll oder nicht
   */
  selectAnpaarungsvorschlag(index: number, doNothingByMoreThenOneResult?) {
    this.resetChoosenAnpaarungsvorschlaege();
    const searchFilterPagingResource = new SearchFilterPagingResource();
    const filterCriteriaResource = new FilterCriteriaResource('herdbookNumber', this.selectedCattle.anpaarungen[index].herdbookNumber, FilterType.STRING);
    searchFilterPagingResource.orFilterCriteriaResourceList.push(filterCriteriaResource);
    this.selectAnpaarungsvorschlagSubscription = this.spermaartikelService
      .loadDataByBetriebsschluessel(this.auftrag.betriebsschluessel, searchFilterPagingResource)
      .subscribe(
        (result) => {
          if (result.content.length === 1) {
            this.selectedBulle = result.content[0];
            this.setBullendaten();
            switch (index) {
              case 0:
                (document.getElementById('anpaarungsvorschlag0') as HTMLButtonElement).classList.add('bulle-button-active');
                break;
              case 1:
                (document.getElementById('anpaarungsvorschlag1') as HTMLButtonElement).classList.add('bulle-button-active');
                break;
              case 2:
                (document.getElementById('anpaarungsvorschlag2') as HTMLButtonElement).classList.add('bulle-button-active');
                break;
              default:
                console.error('Unbekannter Index ', index);
                break;
            }
          } else {
            if (!doNothingByMoreThenOneResult) {
              this.notificationService.showInfoNotification('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.ANPAARUNGSVORSCHLAEGE');
              this.sucheBulle([filterCriteriaResource]);
            }
          }
        },
        (error) => this.customErrorhandlerService.handleError(error)
      );
  }

  /**
   * Setzt die Bullendaten vom selektierten Bullen.
   * @param isLetzter true, wenn der Button Letzter gedrueckt wurde, sonst false
   */
  setBullendaten(isLetzter: boolean = false) {
    this.besamungForm.patchValue({ charge: '' });
    this.besamungForm.patchValue({
      selectedBulleLabel: `${this.selectedBulle.name}, ${this.spermaartPipe.transform(this.selectedBulle.label)} (${this.selectedBulle.herdbookNumber})`
    });
    if (this.selectedBulle.spermabestandTechnikerList.length !== 0) {
      if (isLetzter) {
        this.selectableChargen = this.selectedBulle.spermabestandTechnikerList.filter(
          (charge) => charge.amount > 0 && !isNaN(+charge.spermabestandTechnikerKey.lotNumber)
        );
        const lastCharge = this.selectableChargen.find((charge) => charge.spermabestandTechnikerKey.lotNumber === this.selectedCattle.letzteBesamung.lotNumber);
        if (lastCharge) {
          this.besamungForm.patchValue({ charge: lastCharge.spermabestandTechnikerKey.lotNumber });
        }
      } else {
        this.selectableChargen = this.selectedBulle.spermabestandTechnikerList.filter(
          (charge) => charge.amount > 0 && !isNaN(+charge.spermabestandTechnikerKey.lotNumber)
        );
        if (this.selectableChargen.length === 1) {
          this.besamungForm.patchValue({ charge: this.selectableChargen[0].spermabestandTechnikerKey.lotNumber });
        }
      }
    }
    this.besamungForm.controls.berechneSpermapreis.enable();
    switch (this.auftrag.farmCategory) {
      case 1:
        if (this.selectedBulle.bestandBetriebssperma > 0) {
          this.besamungForm.patchValue({ berechneSpermapreis: false });
          this.anrechtssperma = true;
        } else {
          this.besamungForm.patchValue({ berechneSpermapreis: true });
          this.anrechtssperma = false;
        }
        this.besamungForm.controls.berechneSpermapreis.disable();
        break;
      case 2:
        this.besamungForm.patchValue({ berechneSpermapreis: true });
        this.anrechtssperma = false;
        break;
      case 4:
        if (this.selectedBulle.bestandBetriebssperma > 0) {
          this.besamungForm.patchValue({ berechneSpermapreis: false });
          this.besamungForm.controls.berechneSpermapreis.disable();
          this.anrechtssperma = true;
        } else {
          this.besamungForm.patchValue({ berechneSpermapreis: true });
          this.anrechtssperma = false;
        }
        break;
      case 3:
      default:
        console.error('Unbekannte Betriebsart!');
    }
    if (this.selectedBulle.bestandAnrechtssperma === 0 && this.selectedBulle.bestandStationssperma === 0 && this.selectedBulle.bestandBetriebssperma === 0) {
      this.notificationService.showInfoNotification('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.SPERMA_BESTAND');
    }
  }

  /**
   * Oeffnet einen Modalen Dialog, mit der Bullenliste, zum hinzufuegen eines Bullen.
   *
   * @param filterCriteriaResourceList FilterCriteriaResourceList (optional)
   */
  sucheBulle(filterCriteriaResourceList?: FilterCriteriaResource[]) {
    this.bulleDialogRef = this.dialog.open(BulleModalComponent, {
      width: '1216px',
      panelClass: 'vost-modal-dialog',
      data: {
        auftrag: this.auftrag,
        filterCriteriaResourceList
      }
    });

    this.bulleDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchBulleInput.searchField.nativeElement.value = '';
        this.selectedBulle = result;
        this.resetChoosenAnpaarungsvorschlaege();
        this.setBullendaten();
      }
    });
  }

  /**
   * Oeffnet einen Modalen Dialog, mit der Tierliste, zum hinzufuegen eines Artikels.
   *
   * @param filterCriteriaResourceList FilterCriteriaResourceList (optional)
   */
  sucheKuh(filterCriteriaResourceList?: FilterCriteriaResource[]) {
    this.cattleDialogRef = this.dialog.open(CattleModalComponent, {
      width: '1216px',
      panelClass: 'vost-modal-dialog',
      data: {
        betriebsschluessel: this.auftrag.betriebsschluessel,
        filterCriteriaResourceList
      }
    });

    this.cattleDialogRef.afterClosed().subscribe((result) => {
      this.searchKuhInput.searchField.nativeElement.value = '';
      if (result) {
        this.selectedCattle = result;
        this.besamungForm.patchValue({ wvb: this.selectedCattle.wvb });
        this.initDateRange();
        this.besamungForm.patchValue({ [this.besamungsdienstleistungenService.getVitArtikelnummerDurchfuehrung()]: true });
        if (this.checkAnfahrtCondition()) {
          this.besamungForm.patchValue({ [this.besamungsdienstleistungenService.getVitArtikelnummerAnfahrt()]: true });
        }
        if (this.selectedBulle === null || this.selectedBulle === undefined || this.selectedBulle.herdbookNumber === undefined) {
          this.checkAnpaarungsvorschlaege();
        }
      }
    });
  }

  /**
   * Ueberprueft, ob die eingegebene Charge in der Chargenliste von dem selektierten Bullen existiert.
   * @param charge Charge
   */
  private chargeExists(charge: string): boolean {
    if (this.editingAuftragsposition) {
      return true;
    }
    if (this.selectedBulle) {
      for (const chargeItem of this.selectedBulle.spermabestandTechnikerList) {
        if (chargeItem.spermabestandTechnikerKey.lotNumber === charge) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Ueberprueft, ob die gleiche LOM schon einmal in den Positionen existiert.
   */
  private checkSameLomExists() {
    if (this.selectedCattle) {
      const lom = this.selectedCattle.cattleKey.lom;
      for (const pos of this.auftrag.positionen) {
        if (pos.cattleKey && pos.cattleKey.lom === lom && pos !== this.editingAuftragsposition) {
          DialogUtils.openInformationDialog(this.dialog, {
            headline: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.SAME_LOM.INFORMATION_DIALOG.HEADLINE'),
            message: this.modaTranslateService.translate('WARENAUSWAHL.BESAMUNG_TAB.MESSAGE.SAME_LOM.INFORMATION_DIALOG.MESSAGE')
          });
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Ueberprueft, ob alle selektierten Checkboxen mit dem Auftrag uebereinstimmen.
   * @param besamungsDienstleistungenAuftragsposition Checkboxen
   */
  private compareSelectedDienstleistungenWithAuftragsposition(besamungsDienstleistungenAuftragsposition: string[]): boolean {
    const selectedFlags = this.getSelectedBesamungsDienstleistungen();
    const oldFlags = besamungsDienstleistungenAuftragsposition || [];
    if (selectedFlags.length !== oldFlags.length) {
      return false;
    }

    for (const flag of selectedFlags) {
      if (oldFlags.indexOf(flag) < 0) {
        return false;
      }
    }
    return true;
  }

  /**
   * Erstellt oder updatet eine Auftragsposition
   */
  private createOrUpdateAuftragsposition() {
    const besamungsDienstleistungen = this.getSelectedBesamungsDienstleistungen();
    let posResult: AuftragspositionResult = null;
    posResult = this.createAuftragsposition(besamungsDienstleistungen, AuftragspositionType.BESAMUNG);
    if (posResult.isNewPosition) {
      this.auftrag.positionen.push(posResult.pos);
    }

    this.auftrag.positionen = [...this.auftrag.positionen]; // refresh the dataSource
    this.resetFields();
  }

  /**
   * Liefert die selektierten Checkboxen als Liste zurueck.
   */
  private getSelectedBesamungsDienstleistungen(): any {
    const selectedFlags = [];
    this.besamungsdienstleistungenService.getVitDienstleistungen().subscribe((besamungsdienstleistungen) => {
      for (const besamungsDienstleistung of besamungsdienstleistungen) {
        if (this.besamungForm.value[besamungsDienstleistung.vitArtikelnummer]) {
          selectedFlags.push(besamungsDienstleistung.vitArtikelnummer);
        }
      }
    });
    selectedFlags.push(this.besamungsdienstleistungenService.getVitArtikelnummerDurchfuehrung());
    return selectedFlags;
  }

  /**
   *   /**
   * Suche nach Cattle: Beim ersten mal wird als Matchmode Exact-genau Suche verwendet.
   * Beim zweiten mal Contains fuer Query benutzt.
   * @param result Resultliste von Cattle
   * @param searchFilterPagingResource SearchFilterPagingResource
   */
  private processData(result: Page<Cattle>, searchFilterPagingResource: SearchFilterPagingResource) {
    if (result.content.length === 1) {
      this.selectedCattle = result.content[0];
      this.besamungForm.patchValue({ wvb: this.selectedCattle.wvb });
      this.initDateRange();
      if (this.selectedBulle === null || this.selectedBulle === undefined || this.selectedBulle.herdbookNumber === undefined) {
        this.checkAnpaarungsvorschlaege();
      }
      this.besamungForm.patchValue({ [this.besamungsdienstleistungenService.getVitArtikelnummerDurchfuehrung()]: true });
      if (this.checkAnfahrtCondition()) {
        this.besamungForm.patchValue({ [this.besamungsdienstleistungenService.getVitArtikelnummerAnfahrt()]: true });
      }
    } else {
      this.sucheKuh(searchFilterPagingResource.orFilterCriteriaResourceList);
    }
  }

  /**
   * Reset Methode.
   */
  private resetFields() {
    this.selectedCattle = null;
    this.selectedBulle = null;
    this.selectableChargen = [];
    this.besamungForm.patchValue({ selectedBulleLabel: null });
    this.besamungForm.patchValue({ wvb: null });
    this.besamungForm.patchValue({ besamungskommentar: null });
    this.besamungForm.patchValue({ charge: null });
    this.besamungsdienstleistungenService.getVitDienstleistungen().subscribe((besamungsdienstleistungen) => {
      for (const besamungdienstleistung of besamungsdienstleistungen) {
        this.besamungForm.patchValue({ [besamungdienstleistung.vitArtikelnummer]: false });
      }
    });
    this.editingAuftragsposition = undefined;
    this.searchKuhInput.clear();
    this.searchBulleInput.clear();
    this.besamungForm.reset();

    Object.keys(this.besamungForm.controls).forEach((key) => {
      this.besamungForm.controls[key].setErrors(null);
    });
  }
}
