import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ToastrModule } from 'ngx-toastr';
import { appInitializerFactory } from './app-initilizer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuftragDetailModalComponent } from './component/auftrag/auftrag-detail-modal/auftrag-detail-modal.component';
import { AuftragsuebersichtComponent } from './component/auftrag/auftragsuebersicht.component';
import { BesamungComponent } from './component/auftrag/warenkorb/warenauswahl/besamung/besamung.component';
import { NonSpermaArtikelComponent } from './component/auftrag/warenkorb/warenauswahl/non-sperma-artikel/non-sperma-artikel.component';
import { TuDienstleistungenComponent } from './component/auftrag/warenkorb/warenauswahl/tu-dienstleistungen/tu-dienstleistungen.component';
import { WarenauswahlComponent } from './component/auftrag/warenkorb/warenauswahl/warenauswahl.component';
import { WarenkorbComponent } from './component/auftrag/warenkorb/warenkorb.component';
import { BestandAgrarartikelComponent } from './component/bestand-agrarartikel/bestand-agrarartikel.component';
import { BestandBetriebsspermaComponent } from './component/bestand-betriebssperma/bestand-betriebssperma.component';
import { BestandSpermaartikelComponent } from './component/bestand-spermaartikel/bestand-spermaartikel.component';
import { BestanduebersichtComponent } from './component/bestand/bestanduebersicht.component';
import { BestelluebersichtComponent } from './component/bestellung/bestelluebersicht.component';
import { SpermaauswahlComponent } from './component/bestellung/spermaauswahl/spermaauswahl.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { BulleModalComponent } from './component/bulle-modal/bulle-modal.component';
import { CattleDetailModalComponent } from './component/cattle-detail-modal/cattle-detail-modal.component';
import { CattleuebersichtGesamtComponent } from './component/cattle-gesamt/cattleuebersicht-gesamt.component';
import { CattleModalComponent } from './component/cattle-modal/cattle-modal.component';
import { CattleuebersichtComponent } from './component/cattle/cattleuebersicht.component';
import { DeviceConfigurationDetailComponent } from './component/configuration/device/device-configuration-detail/device-configuration-detail.component';
import { DeviceConfigurationUebersichtComponent } from './component/configuration/device/device-configuration-uebersicht/device-configuration-uebersicht.component';
import { JobDetailComponent } from './component/configuration/job/job-detail/job-detail.component';
import { JobuebersichtComponent } from './component/configuration/job/jobuebersicht-component/jobuebersicht.component';
import { DocumentuebersichtComponent } from './component/document/documentuebersicht.component';
import { FarmDetailModalComponent } from './component/farm/farm-detail-modal/farm-detail-modal.component';
import { FarmuebersichtComponent } from './component/farm/farmuebersicht.component';
import { FarmuebersichtModalComponent } from './component/farmuebersicht-modal/farmuebersicht-modal.component';
import { HeaderComponent } from './component/header/header.component';
import { LeistungsprotokollLandwirtComponent } from './component/leistungsprotokoll-landwirt/leistungsprotokoll-landwirt.component';
import { LeistungsprotokollComponent } from './component/leistungsprotokoll/leistungsprotokoll.component';
import { NewCattleComponent } from './component/new-cattle/new-cattle.component';
import { SearchComponent } from './component/search/search.component';
import { SpermaauswahlModalComponent } from './component/spermaauswahl-modal/spermaauswahl-modal.component';
import { TauschauswahlComponent } from './component/tauschuebersicht/tauschauswahl/tauschauswahl.component';
import { TauschuebersichtComponent } from './component/tauschuebersicht/tauschuebersicht.component';
import { TechnikeruebersichtModalComponent } from './component/technikeruebersicht-modal/technikeruebersicht-modal.component';
import { VerlustauswahlComponent } from './component/verlustuebersicht/verlustauswahl/verlustauswahl.component';
import { VerlustuebersichtComponent } from './component/verlustuebersicht/verlustuebersicht.component';
import { VertretungBetriebComponent } from './component/vertretung-betrieb/vertretung-betrieb.component';
import { VertretungTechnikerComponent } from './component/vertretung-techniker/vertretung-techniker.component';
import { ConfirmationDialogComponent } from './core/component/confirmation-dialog/confirmation-dialog.component';
import { DateSearchComponent } from './core/component/date-search/date-search.component';
import { DateComponent } from './core/component/date/date.component';
import { ErrorDialogCustomerComponent } from './core/component/error-dialog-customer/error-dialog-customer.component';
import { ErrorDialogComponent } from './core/component/error-dialog/error-dialog.component';
import { InformationDialogComponent } from './core/component/information-dialog/information-dialog.component';
import { RangeSearchComponent } from './core/component/range-search/range-search.component';
import { TableClientPaginationFooterComponent } from './core/component/table/footer/table-client-pagination-footer/table-client-pagination-footer.component';
import { TableFooterWithoutPagingComponent } from './core/component/table/footer/table-footer-without-paging/table-footer-without-paging.component';
import { TableServerPaginationFooterComponent } from './core/component/table/footer/table-server-pagination-footer/table-server-pagination-footer.component';
import { AmountFilterPipe } from './core/pipe/amount-filter.pipe';
import { BookingTypePipe } from './core/pipe/booking-type.pipe';
import { BooleanTranslationPipe } from './core/pipe/boolean-translation.pipe';
import { BulleLabelPipe } from './core/pipe/bulle-label.pipe';
import { DatexPipe } from './core/pipe/datex.pipe';
import { EnumKeysPipe } from './core/pipe/enum-keys.pipe';
import { MissingTranslationHelper } from './core/pipe/missing.translation-handler';
import { MonetaryPipe } from './core/pipe/monetary.pipe';
import { SpermaartPipe } from './core/pipe/spermaart.pipe';
import { ValidateRegexDirective } from './directive/validate-regex.directive';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    AppComponent,
    DatexPipe,
    MonetaryPipe,
    EnumKeysPipe,
    BestanduebersichtComponent,
    HeaderComponent,
    BreadcrumbComponent,
    SearchComponent,
    DateSearchComponent,
    AuftragsuebersichtComponent,
    CattleuebersichtComponent,
    CattleDetailModalComponent,
    TableClientPaginationFooterComponent,
    TableServerPaginationFooterComponent,
    FarmuebersichtComponent,
    WarenkorbComponent,
    WarenauswahlComponent,
    ValidateRegexDirective,
    BesamungComponent,
    CattleModalComponent,
    DeviceConfigurationUebersichtComponent,
    BooleanTranslationPipe,
    NonSpermaArtikelComponent,
    DeviceConfigurationDetailComponent,
    ErrorDialogComponent,
    BulleModalComponent,
    JobuebersichtComponent,
    JobDetailComponent,
    ConfirmationDialogComponent,
    InformationDialogComponent,
    DateComponent,
    SpermaartPipe,
    BulleLabelPipe,
    AuftragDetailModalComponent,
    BestelluebersichtComponent,
    SpermaauswahlComponent,
    AmountFilterPipe,
    FarmDetailModalComponent,
    TuDienstleistungenComponent,
    LeistungsprotokollComponent,
    TableFooterWithoutPagingComponent,
    RangeSearchComponent,
    NewCattleComponent,
    BestandAgrarartikelComponent,
    VertretungTechnikerComponent,
    VertretungBetriebComponent,
    BestandSpermaartikelComponent,
    ErrorDialogCustomerComponent,
    BestandBetriebsspermaComponent,
    BookingTypePipe,
    LeistungsprotokollLandwirtComponent,
    CattleuebersichtGesamtComponent,
    VerlustuebersichtComponent,
    VerlustauswahlComponent,
    FarmuebersichtModalComponent,
    SpermaauswahlModalComponent,
    TauschuebersichtComponent,
    TauschauswahlComponent,
    TechnikeruebersichtModalComponent,
    DocumentuebersichtComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    KeycloakAngularModule,
    MaterialModule,
    FormsModule,
    NgxDatatableModule,
    MatDatepickerModule,
    MatInputModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatDialogModule,
    MatGridListModule,
    MatSelectModule,
    MatAutocompleteModule,
    ToastrModule.forRoot(),
    MatMomentDateModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: MissingTranslationHandler,
      useClass: MissingTranslationHelper
    },
    DatexPipe,
    SpermaartPipe,
    BulleLabelPipe,
    EnumKeysPipe,
    BookingTypePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
