import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterType } from '../../core/filter/filterType';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { Cattle } from '../../model/cattle';
import { CattleService } from '../../service/cattle/cattle.service';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { CattleDetailModalComponent } from '../cattle-detail-modal/cattle-detail-modal.component';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-cattleuebersicht-gesamt',
  templateUrl: './cattleuebersicht-gesamt.component.html',
  styleUrls: ['./cattleuebersicht-gesamt.component.scss']
})
export class CattleuebersichtGesamtComponent extends AbstractServerPaginationTable<Cattle> implements OnInit {
  sortField = [
    {
      prop: 'name',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  /**
   * Konstruktor
   * @param modaTranslateService ModaTranslateService
   * @param customErrorHandlerService CustomErrorHandlerService
   * @param cattleService AuftragService
   */
  constructor(
    modaTranslateService: ModaTranslateService,
    customErrorHandlerService: CustomErrorHandlerService,
    private cattleService: CattleService,
    private readonly modaKeycloakService: ModaKeycloakService,
    private readonly dialog: MatDialog,
    private router: Router
  ) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   *  {@inheritDoc}.
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    this.loadDataSubscription = this.cattleService.loadData(searchFilterPagingResource).subscribe(
      (data) => {
        this.doAfterLoadData(data);
      },
      (error) => this.customErrorHandlerService.handleError(error)
    );
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('name', FilterType.STRING),
      new SearchFieldDefinition('alphaLom', FilterType.STRING),
      new SearchFieldDefinition('cattleKey.lom', FilterType.STRING),
      new SearchFieldDefinition('status', FilterType.STRING),
      new SearchFieldDefinition('rasse.label', FilterType.STRING),
      new SearchFieldDefinition('stallNummer', FilterType.STRING),
      new SearchFieldDefinition('farmInfo.adresse.vorname', FilterType.STRING),
      new SearchFieldDefinition('farmInfo.adresse.nachname', FilterType.STRING),
      new SearchFieldDefinition('farmInfo.adresse.ort', FilterType.STRING),
      new SearchFieldDefinition('farmInfo.adresse.ortsteil', FilterType.STRING),
      new SearchFieldDefinition('farmInfo.adresse.land', FilterType.STRING),
      new SearchFieldDefinition('farmInfo.shortNumber', FilterType.STRING)
    ];
  }

  /**
   * {@inheritDoc}
   */
  public getService(): ServerPaginationService<Cattle> {
    return this.cattleService;
  }

  /**
   * {@inheritDoc}
   */
  public getSortFields(): any {
    return this.sortField;
  }

  ngOnInit() {
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * {@inheritDoc}
   */
  onDoubleClick(row: Cattle) {
    this.showTierDetails(row);
  }

  /**
   * Setzt den Row-Identity.<br/>
   * Damit behalten wir die selektieren Zeilen.
   *
   * @param row Cattle
   */
  rowIdentity(row: Cattle) {
    return row.id;
  }

  /**
   * Zeigt den Tierdetails
   * @param row Cattle
   */
  showTierDetails(row: Cattle) {
    this.dialog.open(CattleDetailModalComponent, {
      width: '1216px',
      panelClass: 'vost-modal-dialog',
      data: {
        cattle: row
      }
    });
  }
}
