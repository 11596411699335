import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { Spermaartikel } from '../../model/spermaartikel';
import { ServerPaginationService } from '../serverPaginationService';

@Injectable({
  providedIn: 'root'
})
export class SpermaartikelService implements ServerPaginationService<Spermaartikel> {
  private url = '/api/v1/spermaartikel';

  constructor(private http: HttpClient) {}

  public loadData(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Spermaartikel>> {
    return this.http.post<Page<Spermaartikel>>(this.url + '/listPage', searchFilterPagingResource);
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource die Spermaartikel.
   *
   * @param betriebsschluessel Betriebsschluessel
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return Observable<Page<Spermaartikel>>
   */
  loadDataByBetriebsschluessel(betriebsschluessel: string, searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Spermaartikel>> {
    return this.http.post<Page<Spermaartikel>>(this.url + '/listPageByBetriebsschluessel', searchFilterPagingResource, {
      headers: new HttpHeaders().set('betriebsschluessel', betriebsschluessel)
    });
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource die Spermaartikel pro Betrieb.
   * @param searchFilterPagingResource SearchFilterPagingResource.
   * @return Observable<Page<Spermaartikel>>
   */
  public loadDataByBetriebssperma(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Spermaartikel>> {
    return this.http.post<Page<Spermaartikel>>(this.url + '/listPageSpermaBetriebssperma', searchFilterPagingResource);
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource die Spermaartikel.
   * @param searchFilterPagingResource SearchFilterPagingResource.
   * @return Observable<Page<Spermaartikel>>
   */
  public loadDataBySperma(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Spermaartikel>> {
    return this.http.post<Page<Spermaartikel>>(this.url + '/listPageSperma', searchFilterPagingResource);
  }
}
