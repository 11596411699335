<div class="headline">
  @if (headlineKey) {
    <label>{{ headlineKey | translate }}</label>
  }
  @if (headline) {
    <label>{{ headline }}</label>
  }
</div>
@if (messageKey) {
  <div class="dialog-content">
    {{ messageKey | translate }}
  </div>
}
@if (message) {
  <div class="dialog-content">
    {{ message }}
  </div>
}
<div class="[ form-content-modal ]">
  <button class="[ c-mat-button no-color-button ]" mat-button mat-dialog-close>
    <i aria-hidden="true"></i>
    {{ 'INFORMATION_DIALOG.BUTTON.CLOSE' | translate }}
  </button>
</div>