import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuftragsuebersichtComponent } from './component/auftrag/auftragsuebersicht.component';
import { WarenkorbComponent } from './component/auftrag/warenkorb/warenkorb.component';
import { BestandAgrarartikelComponent } from './component/bestand-agrarartikel/bestand-agrarartikel.component';
import { BestandBetriebsspermaComponent } from './component/bestand-betriebssperma/bestand-betriebssperma.component';
import { BestandSpermaartikelComponent } from './component/bestand-spermaartikel/bestand-spermaartikel.component';
import { BestanduebersichtComponent } from './component/bestand/bestanduebersicht.component';
import { BestelluebersichtComponent } from './component/bestellung/bestelluebersicht.component';
import { SpermaauswahlComponent } from './component/bestellung/spermaauswahl/spermaauswahl.component';
import { CattleuebersichtGesamtComponent } from './component/cattle-gesamt/cattleuebersicht-gesamt.component';
import { CattleuebersichtComponent } from './component/cattle/cattleuebersicht.component';
import { DeviceConfigurationUebersichtComponent } from './component/configuration/device/device-configuration-uebersicht/device-configuration-uebersicht.component';
import { JobuebersichtComponent } from './component/configuration/job/jobuebersicht-component/jobuebersicht.component';
import { DocumentuebersichtComponent } from './component/document/documentuebersicht.component';
import { FarmuebersichtComponent } from './component/farm/farmuebersicht.component';
import { LeistungsprotokollLandwirtComponent } from './component/leistungsprotokoll-landwirt/leistungsprotokoll-landwirt.component';
import { LeistungsprotokollComponent } from './component/leistungsprotokoll/leistungsprotokoll.component';
import { TauschuebersichtComponent } from './component/tauschuebersicht/tauschuebersicht.component';
import { VerlustuebersichtComponent } from './component/verlustuebersicht/verlustuebersicht.component';
import { VertretungBetriebComponent } from './component/vertretung-betrieb/vertretung-betrieb.component';
import { VertretungTechnikerComponent } from './component/vertretung-techniker/vertretung-techniker.component';
import { AppAuthGuardGuard } from './guard/app-auth-guard.guard';
import { Role } from './guard/role';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN]
    }
  },
  {
    path: 'artikelliste',
    component: BestanduebersichtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
      breadcrumb: 'NAVIGATION.STAMMDATEN_ARTIKELLISTE'
    }
  },
  {
    path: 'auftraege',
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
      breadcrumb: 'AUFTRAGSUEBERSICHT.AUFTRAG_HISTORIE',
      neuerAuftrag: false
    },
    children: [
      {
        path: '',
        component: AuftragsuebersichtComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'farmuebersicht',
        canActivate: [AppAuthGuardGuard],
        data: {
          roles: [Role.BESAMUNGSTECHNIKER],
          breadcrumb: 'NAVIGATION.NEUER_AUFTRAG',
          editAuftrag: false
        },
        children: [
          {
            path: '',
            component: FarmuebersichtComponent,
            data: {
              roles: [Role.BESAMUNGSTECHNIKER],
              breadcrumb: ''
            }
          },
          {
            path: 'auftragsuebersicht',
            component: WarenkorbComponent,
            canActivate: [AppAuthGuardGuard],
            data: {
              roles: [Role.BESAMUNGSTECHNIKER],
              breadcrumb: 'NAVIGATION.AUFTRAGSUEBERSICHT'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'neuerAuftrag',
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER],
      breadcrumb: 'NAVIGATION.AUFTRAEGE',
      neuerAuftrag: true
    },
    children: [
      {
        path: '',
        component: AuftragsuebersichtComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'farmuebersicht',
        canActivate: [AppAuthGuardGuard],
        data: {
          roles: [Role.BESAMUNGSTECHNIKER],
          breadcrumb: 'NAVIGATION.NEUER_AUFTRAG'
        },
        children: [
          {
            path: '',
            component: FarmuebersichtComponent,
            data: {
              roles: [Role.BESAMUNGSTECHNIKER],
              breadcrumb: ''
            }
          },
          {
            path: 'auftragsuebersicht',
            component: WarenkorbComponent,
            canActivate: [AppAuthGuardGuard],
            data: {
              roles: [Role.BESAMUNGSTECHNIKER],
              breadcrumb: 'NAVIGATION.AUFTRAGSUEBERSICHT'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'bestellungen',
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN],
      breadcrumb: 'NAVIGATION.BESTELLUNGEN'
    },
    children: [
      {
        path: '',
        component: BestelluebersichtComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'spermaauswahl',
        component: SpermaauswahlComponent,
        canActivate: [AppAuthGuardGuard],
        data: {
          roles: [Role.BESAMUNGSTECHNIKER],
          breadcrumb: 'NAVIGATION.SPERMAAUSWAHL'
        }
      }
    ]
  },
  {
    path: 'tierliste',
    component: CattleuebersichtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
      breadcrumb: 'NAVIGATION.STAMMDATEN_TIERLISTE'
    }
  },
  {
    path: 'devices',
    component: DeviceConfigurationUebersichtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.MODA_ADMIN],
      breadcrumb: 'NAVIGATION.DEVICE_CONFIGURATION_UEBERSICHT'
    }
  },
  {
    path: 'jobs',
    component: JobuebersichtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.MODA_ADMIN],
      breadcrumb: 'NAVIGATION.JOBUEBERSICHT'
    }
  },
  {
    path: 'leistungsprotokoll',
    component: LeistungsprotokollComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER],
      breadcrumb: 'NAVIGATION.LEISTUNGSPROTOKOLL'
    }
  },
  {
    path: 'betriebLeistungsprotokollLandwirt',
    component: FarmuebersichtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER],
      breadcrumb: 'NAVIGATION.LEISTUNGSPROTOKOLL_LANDWIRT',
      leistungsprotokollLandwirt: true
    }
  },
  {
    path: 'leistungsprotokollLandwirt',
    component: LeistungsprotokollLandwirtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER],
      breadcrumb: 'NAVIGATION.LEISTUNGSPROTOKOLL_LANDWIRT'
    }
  },
  {
    path: 'bestandagrarartikel',
    component: BestandAgrarartikelComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
      breadcrumb: 'NAVIGATION.BESTAND_AGRARARTIKEL'
    }
  },
  {
    path: 'verlustuebersicht',
    component: VerlustuebersichtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN],
      breadcrumb: 'NAVIGATION.VERLUST'
    }
  },
  {
    path: 'tauschuebersicht',
    component: TauschuebersichtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN],
      breadcrumb: 'NAVIGATION.TAUSCH'
    }
  },
  {
    path: 'vertretungTechniker',
    component: VertretungTechnikerComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER],
      breadcrumb: 'NAVIGATION.VERTRETUNG_TECHNIKER'
    }
  },
  {
    path: 'vertretungBetrieb',
    component: VertretungBetriebComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BAP_BERATER, Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER],
      breadcrumb: 'NAVIGATION.VERTRETUNG_BETRIEB'
    }
  },
  {
    path: 'bestandsperma',
    component: BestandSpermaartikelComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
      breadcrumb: 'NAVIGATION.BESTAND_SPERMA'
    }
  },
  {
    path: 'bestandbetriebssperma',
    component: BestandBetriebsspermaComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
      breadcrumb: 'NAVIGATION.BESTAND_BETRIEBSSPERMA'
    }
  },
  {
    path: 'betriebsliste',
    component: FarmuebersichtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
      breadcrumb: 'NAVIGATION.STAMMDATEN_BETRIEBLISTE',
      editAuftrag: true
    }
  },
  {
    path: 'tierlistegesamt',
    component: CattleuebersichtGesamtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN, Role.MODAREALMADMIN],
      breadcrumb: 'NAVIGATION.STAMMDATEN_TIERLISTE_GESAMT'
    }
  },
  {
    path: 'dokumentuebersicht',
    component: DocumentuebersichtComponent,
    canActivate: [AppAuthGuardGuard],
    data: {
      roles: [Role.BESAMUNGSSTATION, Role.BESAMUNGSTECHNIKER, Role.MODA_ADMIN],
      breadcrumb: 'NAVIGATION.DOKUMENTE'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
