export enum FilterType {
  // @formatter:off
  STRING = 'STRING' as any,
  NUMBER = 'NUMBER' as any,
  DATE = 'DATE' as any,
  INTERVAL = 'INTERVAL' as any,
  SEPARATED_STRING = 'SEPARATED_STRING' as any,
  RANGE = 'RANGE' as any,
  ENUM = 'ENUM' as any
  // @formatter:on
}
