import * as fileSaver from 'file-saver';
import { Subscription } from 'rxjs';

export class Utils {
  static NUMBER_0 = 0;

  /**
   * Unsubscribe.
   *
   * @param subscription Subscription
   */
  static unsubscribeSubscription(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  /**
   * Copy Object.
   *
   * @param object Object
   * @return Kopie des Objektes
   */
  static copy(object: any): any {
    if (object) {
      return { ...object };
    }
  }

  /**
   * Laedt ein File runter.
   *
   * @param response Response
   */
  static downloadFile(response) {
    const contentDispositionHeader: string = response.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    const filename = parts[1].split('=')[1].replace(/^"/, '').replace(/"$/, '');
    const blob = new Blob([response.body]);
    fileSaver.saveAs(blob, filename);
  }
}
