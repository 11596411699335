import { Moment } from 'moment';
import { Auftragspositionsstatus } from '../enumeration/auftragspositionsstatus.enum';
import { Suchfokus } from '../enumeration/suchfokus.enum';
import { Adresse } from './adresse';
import { Auftragsposition } from './auftragsposition';
import { TechnikerKey } from './techniker-key';

export class Auftrag {
  id: string;

  auftragsnummer: string;

  technikerKey: TechnikerKey;

  technikername: string;

  betriebsschluessel: string;

  adresse: Adresse;

  auftragsdatum: Moment;

  auftragsstatus: Auftragspositionsstatus;

  suchfokus: Suchfokus;

  positionen: Array<Auftragsposition> = [];

  shortNumber: string;

  farmCategory: number;
}
