import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Utils } from '../../../utils/utils';
import { FilterCriteria } from '../../filter/filterCriteria';
import { FilterType } from '../../filter/filterType';

@Component({
  selector: 'portal-range-search',
  templateUrl: './range-search.component.html',
  styleUrls: ['./range-search.component.scss']
})
export class RangeSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('minSearchField', { static: true }) minSearchField: ElementRef;

  @ViewChild('maxSearchField', { static: true }) maxSearchField: ElementRef;

  @Output() public search = new EventEmitter<FilterCriteria>();

  @Input() minPlaceholder: string;

  @Input() maxPlaceholder: string;

  @Input() field: string;

  @Input() minValue: string;

  @Input() maxValue: string;

  @Input() defMinValue = '';

  @Input() defMaxValue = '';

  minSearchCtrlSub: Subscription;

  maxSearchCtrlSub: Subscription;

  minSearchTextChanged = new Subject<string>();

  maxSearchTextChanged = new Subject<string>();

  constructor() {}

  /**
   * Suche nach Min-Max-Intervall.
   */
  doSearch() {
    this.search.emit(this.createFilterCriteria(this.minValue, this.maxValue));
  }

  /**
   * Prueft, ob das uebergebene HTML-Objekt leer ist.
   *
   * @param searchField SearchField
   */
  hasValue(searchField: HTMLInputElement) {
    return searchField.value.length > 0;
  }

  ngAfterViewInit(): void {
    if (this.defMinValue) {
      this.minValue = this.defMinValue;
    }
    if (this.defMaxValue) {
      this.maxValue = this.defMaxValue;
    }
  }

  ngOnDestroy() {
    Utils.unsubscribeSubscription(this.minSearchCtrlSub);
    Utils.unsubscribeSubscription(this.maxSearchCtrlSub);
  }

  ngOnInit() {
    this.minSearchCtrlSub = this.minSearchTextChanged.pipe(debounceTime(1000)).subscribe(() => {
      const text = this.minSearchField.nativeElement.value;
      if (text.length === 0 || text.length >= 1) {
        this.doSearch();
      }
    });
    this.maxSearchCtrlSub = this.maxSearchTextChanged.pipe(debounceTime(1000)).subscribe(() => {
      const text = this.maxSearchField.nativeElement.value;
      if (text.length === 0 || text.length >= 1) {
        this.doSearch();
      }
    });
  }

  /**
   * KeyDown Event.
   *
   * @param $event Keyboardevent
   */
  onKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  /**
   * Event auf Enter Taste.
   *
   * @param $event KeyboarEvent
   * @param value Value
   * @param isMin true, wenn min
   */
  onKeyUp($event: KeyboardEvent, value: string, isMin: boolean) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      $event.stopPropagation();
      this.doSearch();
    } else {
      if (isMin) {
        this.minSearchTextChanged.next(value);
        this.minValue = value;
      } else {
        this.maxSearchTextChanged.next(value);
        this.maxValue = value;
      }
    }
  }

  /**
   * Erstellt Filterkriteria fuer den Range-Filter.
   *
   * @param minValue Von
   * @param maxValue Bis
   */
  private createFilterCriteria(minValue: string, maxValue: string): FilterCriteria {
    const criteria: FilterCriteria = new FilterCriteria();
    let minNumber = null;
    let maxNumber = null;
    if (minValue === null || minValue === undefined || minValue === '' || minValue === '-') {
      minNumber = null;
    } else {
      minNumber = '' + minValue;
    }
    if (maxValue === null || maxValue === undefined || maxValue === '' || maxValue === '-') {
      maxNumber = null;
    } else {
      maxNumber = '' + maxValue;
    }
    criteria.addFilter(
      this.field,
      {
        min: minNumber,
        max: maxNumber
      },
      FilterType.RANGE
    );
    return criteria;
  }
}
