<div class="headline">
  <label>{{ 'TAUSCHAUSWAHL.TITLE' | translate }}</label>
</div>
<mat-dialog-content class="mat-typography">
  <div class="tauschauswahl-container">
    @if (tauschForm) {
      <form [formGroup]="tauschForm" novalidate>
        <div class="box">
          <div class="headline">
            <label>{{ 'TAUSCHAUSWAHL.SPERMA' | translate }}</label>
            <button (click)="sucheBulle()" class="[ c-mat-button no-color-button right ]" mat-button>
              {{ 'BUTTON.ALLE_ANZEIGEN' | translate }}
              <i aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]"></i>
            </button>
          </div>
          <div class="bulle-container">
            <portal-search #searchBulleInput (search)="searchBulle($event)" [automaticSearch]="false"
              [fields]="getSearchFieldDefinitionsBulle()"
              [placeholder]="('TAUSCHAUSWAHL.BULLE_SEARCH' | translate)"></portal-search>
            <portal-date #dateComponent (dateChange)="selectedDate=$event"
              (dateClear)="selectedDate.date=null"
              [datePlaceholder]="('TAUSCHAUSWAHL.DATUM' | translate)"
              [dateValue]="selectedDate && selectedDate.date"
              [minDate]="minDate"
              [required]="true"
              class="[ right ]"></portal-date>
          </div>
          <div class="bulle-container">
            <mat-form-field class="bulle-name-field">
              <i class="[ icon-cow-1 ]" matPrefix></i>
              <mat-label>{{ 'TAUSCHAUSWAHL.BULLE' | translate }}</mat-label>
              <input formControlName="selectedBulleLabel" matInput readonly>
            </mat-form-field>
            <i class="dummy"></i>
            <mat-form-field class="charge-selection">
              <mat-label>{{ 'TAUSCHAUSWAHL.CHARGE' | translate }}</mat-label>
              <input #charge [matAutocomplete]="chargen" [validateRegex]="'^\\d+$'" formControlName="charge"
                id="charge" matInput>
              <mat-autocomplete #chargen="matAutocomplete">
                @for (charge of selectableChargen; track charge) {
                  <mat-option
                    [value]="charge.spermabestandTechnikerKey.lotNumber">
                    {{ charge.spermabestandTechnikerKey.lotNumber }}
                  </mat-option>
                }
              </mat-autocomplete>
              <i class="[ icon-baseline-arrow_drop_down-24px ]" matSuffix></i>
            </mat-form-field>
          </div>
          <div class="bulle-container">
            <mat-form-field class="bemerkung-field">
              <mat-label>{{ 'TAUSCHAUSWAHL.BEMERKUNG' | translate }}</mat-label>
              <input [validateRegex]="'^.{1,150}$'" formControlName="bemerkung" matInput>
            </mat-form-field>
            <mat-form-field class="right" style="width: 60px">
              <mat-label>{{ 'TAUSCHAUSWAHL.ANZAHL' | translate }}</mat-label>
              <input [validateRegex]="'^([1-9]|[1-9][0-9])$'" formControlName="anzahl" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="box">
          <div class="headline">
            <label>{{ 'TAUSCHAUSWAHL.QUELLTECHNIKER' | translate }}</label>
            <button (click)="sucheTechniker()" class="[ c-mat-button no-color-button right ]" mat-button>
              {{ 'BUTTON.ALLE_ANZEIGEN' | translate }}
              <i aria-hidden="true" class="[ icon-Gruppe-maskieren-1 ]"></i>
            </button>
          </div>
          <div class="bulle-container">
            <portal-search #searchTechnikerInput (search)="searchTechniker($event)" [automaticSearch]="false"
              [fields]="getSearchFieldDefinitionsTechniker()"
              [placeholder]="('TAUSCHAUSWAHL.TECHNIKER_SEARCH' | translate)"></portal-search>
          </div>
          <div class="bulle-container">
            <mat-form-field class="techniker-name-field">
              <i class="[ icon-account ]" matPrefix></i>
              <mat-label>{{ 'TAUSCHAUSWAHL.ERHALTEN_VON' | translate }}</mat-label>
              <input formControlName="selectedTechnikerLabel" matInput readonly>
            </mat-form-field>
          </div>
        </div>
        <div class="[ form-content ]">
          <button (click)="abbrechen()" class="[ c-mat-button no-color-button ]" mat-button>
            <i aria-hidden="true"></i>
            {{ 'BUTTON.ABBRECHEN' | translate }}
          </button>
          <button (click)="hinzufuegen()" class="[ c-mat-button green-button right ]" mat-flat-button>
            <i aria-hidden="true" class="[ icon-plus ]" style="margin-right: 10px"></i>
            {{ 'BUTTON.HINZUFUEGEN' | translate }}
          </button>
        </div>
      </form>
    }
  </div>
</mat-dialog-content>
