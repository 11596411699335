<div class="[ panel farmdetail ]">
  <div class="[ titlebar ]">
    <div class="[ title ]">
      {{ 'FARM_DETAIL.UEBERSICHT' | translate }}
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="[ panel-content ]">
      <div class="row">
        <div class="[ farm-information ]">
          <div class="headline">
            <label>{{
                    'FARM_DETAIL.INFO' | translate:{
                        'name': farm?.adresse?.nachname ? farm?.adresse?.nachname : ''
                        + farm?.adresse?.vorname
                            ? ',' + farm?.adresse?.vorname : ''
                    }
              }}
            </label>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="shortNumber">{{ 'FARM_DETAIL.KUNDENNUMMER' | translate }}</div>
              <div class="output" id="shortNumber">{{
                      farm?.shortNumber > 0
                          ? farm?.shortNumber
                          : ''
                }}
              </div>
            </div>
            <div class="[ c-output-container-2 ]">
              <div class="label" for="betriebsschluessel">{{ 'FARM_DETAIL.BETRIEBSSCHLUESSEL' | translate }}</div>
              <div class="output" id="betriebsschluessel">{{ farm?.betriebsschluessel }}</div>
            </div>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="name">{{ 'FARM_DETAIL.NAME' | translate }}</div>
              <div class="output" id="name">
                {{ farm?.adresse?.nachname }}, {{ farm?.adresse?.vorname }}
              </div>
            </div>
            <div class="[ c-output-container-2 ]">
              <div class="label" for="art">{{ 'FARMCATEGORY.TITLE' | translate }}</div>
              <div class="output" id="farmCategory">{{
                      'FARMCATEGORY.ART-'
                      + farm?.farmCategory |translate: { default: 'FARMCATEGORY.DEFAULT' | translate }
                }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="strasse">{{ 'FARM_DETAIL.STRASSE' | translate }}</div>
              <div class="output" id="strasse">{{ farm?.adresse?.strasseHausnummer }}</div>
            </div>
            <div class="[ c-output-container-2 ]">
              <div class="label" for="zahlungsart">{{ 'FARM_DETAIL.ZAHLUNGSART' | translate }}</div>
              <div class="output" id="zahlungsart">{{ farm?.zahlungsart | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="plz">{{ 'FARM_DETAIL.PLZ' | translate }}</div>
              <div class="output" id="plz">{{ farm?.adresse?.zip }}</div>
            </div>
            <div class="[ c-output-container-2 ]">
              <div class="label" for="stammtechnikername">{{ 'FARM_DETAIL.STAMMTECHNIKERNAME' | translate }}</div>
              @if (farm?.stammtechnikername) {
                <div class="output" id="stammtechnikername">
                  {{ farm.stammtechnikername }}
                </div>
              }
            </div>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="ort">{{ 'FARM_DETAIL.ORT' | translate }}</div>
              <div class="output" id="ort">{{ farm?.adresse?.ort }}</div>
            </div>
            <div class="[ c-output-container-2 ]">
              <div class="label" for="tier">{{ 'FARM_DETAIL.TIERE' | translate }}</div>
              <div class="output" id="tier">{{ anzahlTiere }}</div>
            </div>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="ortsteil">{{ 'FARM_DETAIL.ORTSTEIL' | translate }}</div>
              <div class="output" id="ortsteil">{{ farm?.adresse?.ortsteil }}</div>
            </div>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="telefon">{{ 'FARM_DETAIL.TELEFON' | translate }}</div>
              <div class="output" id="telefon">{{ farm?.fon }}</div>
            </div>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="handy">{{ 'FARM_DETAIL.MOBIL' | translate }}</div>
              <div class="output" id="handy">{{ farm?.mobileNumber }}</div>
            </div>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="fax">{{ 'FARM_DETAIL.FAX' | translate }}</div>
              <div class="output" id="fax">{{ farm?.fax }}</div>
            </div>
          </div>
          <div class="row">
            <div class="[ c-output-container-1 ]">
              <div class="label" for="email">{{ 'FARM_DETAIL.EMAIL' | translate }}</div>
              <output class="output" id="email">{{ farm?.mailAddress }}</output>
            </div>
            <div class="[ c-output-container-2 ]">
              <div class="label" for="search">{{ 'FARM_DETAIL.SEARCH' | translate }}</div>
              <div class="output" id="search">
                <select [(ngModel)]="selectedSuchfokus">
                  @for (opt of suchfokusValues | enumKeys; track opt) {
                    <option
                      [ngValue]="'ENUM.SUCHFOKUS.' + opt">{{ 'FARM_DETAIL.' + opt | translate }}
                    </option>
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="farm-techniker">
          <div class="headline">
            <label>{{ 'FARM_DETAIL.TECHNIKER' | translate }}</label>
          </div>
          <table class="table-techniker">
            <tbody>
              @for (techniker of (farm?.technikerDetails); track techniker; let i = $index) {
                <tr
                >
                  <td class="data-value">{{ techniker?.firstname + ' ' + techniker?.lastname }}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <div class="[ form-content ]">
        <button (click)="schliessen()" class="[ c-mat-button no-color-button ]" mat-button>
          <i aria-hidden="true"></i>
          {{ 'BUTTON.SCHLIESSEN' | translate }}
        </button>
        <button (click)="speichern()" class="[ c-mat-button green-button right-button ]" mat-button>
          <i aria-hidden="true"></i>
          {{ 'BUTTON.BESTAETIGEN' | translate }}
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
