<div class="[ page-count ]">
  @if (!multiSelect) {
    <div>
      <span class="[ c-data-list__text-italic ]">{{ 'TABLE.PAGING.ANZAHL' | translate }} &nbsp;</span>
      <span class="[ c-data-list__text-bold ]">{{ currentPage.totalElements }}</span>
    </div>
  }
  @if (multiSelect) {
    <div>
      <span class="[ c-data-list__text-italic ]">{{ placeholder | translate }}
        &nbsp;</span>
      <span class="[ c-data-list__text-bold ]">{{ selectedLength }}</span>
    </div>
  }
</div>
<datatable-pager
    #datatablePager
  (change)="onFooterPage($event)"
  [count]="currentPage.totalElements"
  [pagerLeftArrowIcon]="'icon-baseline-chevron_left-24px'"
  [pagerNextIcon]="'icon-baseline-last_page-24px'"
  [pagerPreviousIcon]="'icon-baseline-first_page-24px'"
  [pagerRightArrowIcon]="'icon-Gruppe-maskieren-1'"
  [size]="selectedPageSize">
</datatable-pager>
<div class="[ result-changer ]">
  <span class="[ c-data-list__text-italic ]  [ c-data-list__result-text ]">{{ 'TABLE.PAGING.CHANGER' | translate }}
    &nbsp;</span>
  <div class="[ c-data-list__select-results-wrapper ]">
    <select (change)="setPageSize(selectedPageSize)" [(ngModel)]="selectedPageSize"
      class="[ c-data-list__select-results ]">
      @for (size of pageSizeList; track size) {
        <option [ngValue]="size">{{ size }}</option>
      }
    </select>
    <i aria-hidden="true" class="[ fa fa-caret-down ]  [ c-icon ]  [ c-data-list__footer-icon ]"></i>
  </div>
</div>
