<div class="headline">
  <label>{{ 'DEVICE_CONFIGURATION_DETAIL.HEADLINE' | translate }} {{ deviceConfiguration.devicename }}</label>
</div>
<div class="boxed-area">
  <label class="headline">{{ 'DEVICE_CONFIGURATION_DETAIL.INFORMATIONEN' | translate }}</label>
  <mat-grid-list cols="12" rowHeight="40px">
    <mat-grid-tile colspan="2" rowspan="1">
      <span>{{ 'DEVICE_CONFIGURATION_DETAIL.TECHNIKERNAME' | translate }}</span>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <output>{{ deviceConfiguration.technikername }}</output>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <span>{{ 'DEVICE_CONFIGURATION_DETAIL.TECHNIKER_KEY.NUMBER' | translate }}</span>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <output>{{ deviceConfiguration.technikerKey.number }}</output>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <mat-checkbox [(ngModel)]="deviceConfiguration.defaultConfiguration" disabled>
        {{ 'DEVICE_CONFIGURATION_DETAIL.DEFAULT_CONFIGURATION' | translate }}
      </mat-checkbox>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1"></mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <mat-checkbox [(ngModel)]="accountLocked">
        {{ 'DEVICE_CONFIGURATION_DETAIL.ACCOUNT_LOCKED' | translate }}
      </mat-checkbox>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <span>{{ 'DEVICE_CONFIGURATION_DETAIL.DEVICENAME' | translate }}</span>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <output>{{ deviceConfiguration.devicename }}</output>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <span>{{ 'DEVICE_CONFIGURATION_DETAIL.VERSIONSNUMMER' | translate }}</span>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" rowspan="1">
      <output>{{ deviceConfiguration.versionsnummer }}</output>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
      <mat-checkbox [(ngModel)]="enabled">
        {{ 'DEVICE_CONFIGURATION_DETAIL.ENABLED' | translate }}
      </mat-checkbox>
    </mat-grid-tile>
  </mat-grid-list>
</div>
@if (imageUrl) {
  <div class="boxed-area">
    <label class="headline">{{ 'DEVICE_CONFIGURATION_DETAIL.QR_CODE' | translate }}</label>
    <div>
      <img [src]="imageUrl" alt="image">
    </div>
    <button (click)="clickQRCodeAktualisierenButton()" class="c-mat-button dark-green-button" mat-flat-button>
      <i aria-hidden="true" class="[ icon-refresh ]"></i>
      {{ 'DEVICE_CONFIGURATION_DETAIL.BUTTON.QR_CODE_AKTUALISIEREN' | translate }}
    </button>
  </div>
}
<div>
  <ul>
    @for (attribute of deviceConfiguration.attributes; track attribute) {
      <li>
        {{ attribute }}
      </li>
    }
  </ul>
</div>
<div class="[ form-content-modal ]">
  <button (click)="clickAbbrechenButton()" class="[ c-mat-button no-color-button ]" mat-button>
    <i aria-hidden="true"></i>
    {{ 'BUTTON.ABBRECHEN' | translate }}
  </button>
  <button (click)="clickBestaetigenButton()" class="[ c-mat-button green-button right-button ]" mat-flat-button>
    <i aria-hidden="true" class="[ icon-baseline-done-24px ]" style="font-size: 24px"></i>
    {{ 'BUTTON.BESTAETIGEN' | translate }}
  </button>
</div>


