<div class="[ date-search ]">
  <portal-date (dateChange)="dateFromValue=$event.date; doSearch($event)"
    (dateClear)="clearFromField($event)"
    [datePlaceholder]="interval ? ('DATE_SEARCH.DATUM_VON' | translate) : dateLabel"
    [dateValue]="dateFromValue" [maxDate]="maxDateFrom" [minDate]="minDateFrom">
  </portal-date>
  @if (interval) {
    <portal-date (dateChange)="dateToValue=$event.date; doSearch($event)"
      (dateClear)="clearToField($event)"
      [datePlaceholder]="('DATE_SEARCH.DATUM_BIS' | translate)"
      [dateValue]="dateToValue" [maxDate]="maxDateTo" [minDate]="minDateTo" class="[ search-to ]">
    </portal-date>
  }
</div>


