export class Page<T> {
  content: T[];

  number: number;

  numberOfElements: number;

  totalElements: number;

  size: number;
}
