import { Component, OnInit } from '@angular/core';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterCriteria } from '../../core/filter/filterCriteria';
import { FilterCriteriaResource } from '../../core/filter/filterCriteriaResource';
import { FilterType } from '../../core/filter/filterType';
import { SortDirection } from '../../core/filter/sort-direction';
import { Agrarartikel } from '../../model/agrarartikel';
import { AgrarartikelService } from '../../service/agrarartikel/agrarrartikel.service';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-bestand-agrarartikel',
  templateUrl: './bestand-agrarartikel.component.html',
  styleUrls: ['./bestand-agrarartikel.component.scss']
})
export class BestandAgrarartikelComponent extends AbstractServerPaginationTable<Agrarartikel> implements OnInit {
  static readonly TECHNIKER_NR: string = 'technikerNummer';

  sortField = [
    {
      prop: 'bezeichnung',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  isBesamungstechniker: boolean;

  minValue = '1';

  private bestandFilterCriteria: FilterCriteria;

  private technikerFilterCriteria: FilterCriteria;

  constructor(
    modaTranslateService: ModaTranslateService,
    customErrorHandlerService: CustomErrorHandlerService,
    private readonly agrarartikelService: AgrarartikelService,
    private readonly modaKeycloakService: ModaKeycloakService
  ) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Merkt sich die aktuelles Filtercriterium und ruft die Suche auf.
   * @param filterCriteria Filtercriteria
   */
  public doAndSearchBestand(filterCriteria: FilterCriteria) {
    this.bestandFilterCriteria = filterCriteria;
    this.joinedDoAndSearch();
  }

  /**
   * Merkt sich die aktuelles Filtercriterium und ruft die Suche auf.
   * @param filterCriteria Filtercriteria
   */
  doAndSearchTechniker(filterCriteria: FilterCriteria) {
    this.technikerFilterCriteria = filterCriteria;
    this.joinedDoAndSearch();
  }

  /**
   * Liefert das Suchfeld fuer den Min-Max-Filter.
   */
  getSearchFieldDefinitionBestand(): string {
    return 'agrarartikelbestandList.istBestand';
  }

  /**
   * Liefert das Suchfeld fuer den Min-Max-Filter.
   */
  getSearchFieldDefinitionTechniker(): string {
    return BestandAgrarartikelComponent.TECHNIKER_NR;
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [new SearchFieldDefinition('artikelnummer', FilterType.STRING), new SearchFieldDefinition('bezeichnung', FilterType.STRING)];
  }

  /**
   * Filtern nach Techniker
   */
  getSearchFieldTechniker() {
    return [new SearchFieldDefinition(BestandAgrarartikelComponent.TECHNIKER_NR, FilterType.NUMBER)];
  }

  public getService(): ServerPaginationService<Agrarartikel> {
    return this.agrarartikelService;
  }

  /**
   * {@inheritDoc}
   */
  getSortFields(): any {
    return this.sortField;
  }

  ngOnInit() {
    this.bestandFilterCriteria = new FilterCriteria();
    const defaultBestandVonFilter = new FilterCriteriaResource(
      this.getSearchFieldDefinitionBestand(),
      {
        min: this.minValue
      },
      FilterType.RANGE
    );
    this.bestandFilterCriteria.addFilterCriteriaResource(defaultBestandVonFilter);
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
    this.joinedDoAndSearch();
  }

  /**
   * {@inheritDoc}
   */
  rowIdentity(row: Agrarartikel) {
    return row.artikelnummer;
  }

  /**
   * Fuegt alle  Filtercriteria Techniker und Bestand zusammen.
   */
  private joinedDoAndSearch() {
    const fc: FilterCriteria = new FilterCriteria();
    if (this.bestandFilterCriteria) {
      this.bestandFilterCriteria.getFilterCriteria().forEach((fcr) => fc.addFilterCriteriaResource(fcr));
    }
    if (this.technikerFilterCriteria) {
      this.technikerFilterCriteria.getFilterCriteria().forEach((fcr) => fc.addFilterCriteriaResource(fcr));
    }
    this.doAndSearch(fc);
  }
}
