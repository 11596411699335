import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { Auftrag } from '../../model/auftrag';
import { Auftragsposition } from '../../model/auftragsposition';
import { ModaKeycloakService } from '../moda-keycloak/moda-keycloak.service';
import { ServerPaginationService } from '../serverPaginationService';

@Injectable({
  providedIn: 'root'
})
export class AuftragspositionService implements ServerPaginationService<Auftragsposition> {
  private url = '/api/v1/auftragposition';

  constructor(
    private http: HttpClient,
    private readonly modaKeycloakService: ModaKeycloakService
  ) {}

  /**
   * Erstellt einen neuen Auftrag.
   * @param auftrag Auftrag
   *
   * @return Observable<Auftrag>
   */
  createOrUpdate(auftrag: Auftrag): Observable<void> {
    auftrag.technikerKey = this.modaKeycloakService.getTechnikerKey();
    return this.http.post<void>(this.url, auftrag.positionen);
  }

  /**
   * Loescht die Auftragspositionen mit der uebergebenen Auftragsnummer.
   *
   * @param auftragsnummer Auftragsnummer
   */
  deleteByAuftragsnummer(auftragsnummer: string): Observable<void> {
    return this.http.delete<void>(this.url + '/' + auftragsnummer);
  }

  /**
   * Liefert eine Auftragspositionenliste mit der angegebenen Auftragsnummer.
   *
   * @param auftragsNr Auftragsnummer
   * @return Liste mit den Auftragspositionen
   */
  findAuftragPositionenByAuftragsNr(auftragsNr: string): Observable<Array<Auftragsposition>> {
    return this.http.get<Array<Auftragsposition>>(this.url + '/listAuftragsPos/' + auftragsNr);
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource alle fuer den angemeldeten Benutzer sichtbaren
   * Auftragspositionen.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return Observable<Page<Auftragsposition>>
   */
  loadBetriebData(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Auftragsposition>> {
    return this.http.post<Page<Auftragsposition>>(this.url + '/listBetriebPage', searchFilterPagingResource);
  }

  /**
   * Liefert entsprechend der SearchFilterPagingResource alle fuer den angemeldeten Benutzer sichtbaren
   * Auftragsposition.
   *
   * @param searchFilterPagingResource SearchFilterPagingResource
   * @return Observable<Page<Auftragsposition>>
   */
  loadData(searchFilterPagingResource: SearchFilterPagingResource): Observable<Page<Auftragsposition>> {
    return this.http.post<Page<Auftragsposition>>(this.url + '/listPage', searchFilterPagingResource);
  }
}
