import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'portal-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  messageKey: string;

  message: string;

  headlineKey: string;

  public headline: string;

  constructor(@Inject(MAT_DIALOG_DATA) private readonly dialogData: any) {}

  ngOnInit() {
    this.headlineKey = this.dialogData.headlineKey;
    this.headline = this.dialogData.headline;
    this.messageKey = this.dialogData.messageKey;
    this.message = this.dialogData.message;
  }
}
