export class CattleKey {
  lom: string;

  betriebsschluessel: string;

  constructor(lom: string, betriebsschluessel: string) {
    this.lom = lom;
    this.betriebsschluessel = betriebsschluessel;
  }
}
