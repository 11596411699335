import { Moment } from 'moment';
import { Aenderungstyp } from '../enumeration/aenderungstyp.enum';
import { Auftragspositionsstatus } from '../enumeration/auftragspositionsstatus.enum';
import { Breed } from './breed';
import { FarmInfo } from './farmInfo';
import { TechnikerKey } from './techniker-key';

export class Spermatausch {
  id: string;

  quelleTechnikerKey: TechnikerKey;

  technikerKey: TechnikerKey;

  spermCategoryCode: number;

  treatmentCodes: Array<number> = [];

  sireLom: string;

  herdbookNumber: string;

  name: string;

  itemNumber: string;

  behandlungsart: string;

  rasse: Breed;

  anzahl: number;

  aenderungstyp: Aenderungstyp;

  orderNumber: string;

  receiptNumber: string;

  comment: string;

  paymentMethod: number;

  collectionTime: Moment;

  reportingTime: Moment;

  transmissionTime: Moment;

  farmInfo: FarmInfo;

  auftragsdatum: Moment;

  auftragspositionsstatus: Auftragspositionsstatus;

  deviceNumber: string;

  lotNumber: string;

  technikerVorname: string;

  technikerNachname: string;

  quelleTechnikerVorname: string;

  quelleTechnikerNachname: string;
}
