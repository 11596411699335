import { Component, OnInit } from '@angular/core';
import { AbstractServerPaginationTable } from '../../core/component/table/abstract-server-pagination-table';
import { FilterType } from '../../core/filter/filterType';
import { Page } from '../../core/filter/page';
import { SearchFilterPagingResource } from '../../core/filter/searchFilterPagingResource';
import { SortDirection } from '../../core/filter/sort-direction';
import { Inseminator } from '../../model/Inseminator';
import { TechnikerKey } from '../../model/techniker-key';
import { CustomErrorHandlerService } from '../../service/custom-error-handler/custom-error-handler.service';
import { EventService } from '../../service/event/event.service';
import { InseminatorService } from '../../service/inseminator/inseminator.service';
import { ModaKeycloakService } from '../../service/moda-keycloak/moda-keycloak.service';
import { ModaTranslateService } from '../../service/moda-translate.service';
import { NotificationService } from '../../service/notification/notification.service';
import { ServerPaginationService } from '../../service/serverPaginationService';
import { SearchFieldDefinition } from '../search/search-field-definition';

@Component({
  selector: 'portal-vertretung-techniker',
  templateUrl: './vertretung-techniker.component.html',
  styleUrls: ['./vertretung-techniker.component.scss']
})
export class VertretungTechnikerComponent extends AbstractServerPaginationTable<Inseminator> implements OnInit {
  sortField = [
    {
      prop: 'technikerKey.number',
      dir: SortDirection.ASC.frontendkuerzel
    }
  ];

  isBesamungstechniker: boolean;

  nurZugewiesene = false;

  vertreterTechniker: Array<TechnikerKey> = new Array<TechnikerKey>();

  constructor(
    modaTranslateService: ModaTranslateService,
    customErrorHandlerService: CustomErrorHandlerService,
    private readonly inseminatorService: InseminatorService,
    private readonly notificationService: NotificationService,
    private readonly modaKeycloakService: ModaKeycloakService,
    private eventService: EventService
  ) {
    super(modaTranslateService, customErrorHandlerService);
  }

  /**
   * Die Felder, die nicht aus der Db kommen, werden hier vorbereitet.
   *
   * @param page Farm-Page
   */
  calculateData(page: Page<Inseminator>) {
    const technikernummer = this.modaKeycloakService.getTechnikernummer();
    this.selected = [];
    page.content.forEach((inseminator) => {
      const vertreterInTechniker: TechnikerKey = inseminator.vertretungVon.find((techniker) => techniker.number === technikernummer);
      if (vertreterInTechniker || this.vertreterTechniker.some((technikerKey) => technikerKey.number === inseminator.technikerKey.number)) {
        this.selected.push(inseminator);
      }
    });
  }

  /**
   * {@inheritDoc}
   */
  doLoadData(searchFilterPagingResource: SearchFilterPagingResource) {
    if (this.nurZugewiesene) {
      this.loadDataSubscription = this.inseminatorService.loadDataForUser(searchFilterPagingResource).subscribe(
        (data) => {
          this.doAfterLoadData(data);
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    } else {
      this.loadDataSubscription = this.inseminatorService.loadData(searchFilterPagingResource).subscribe(
        (data) => {
          this.doAfterLoadData(data);
        },
        (error) => this.customErrorHandlerService.handleError(error)
      );
    }
  }

  /**
   * Selektiert bzw. deselektiert die Zeilen.
   *
   * @param selected Selektierte Zeilen
   */
  doSelect(selected: any) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.data.forEach((value) => {
      if (this.selected.includes(value)) {
        if (!this.vertreterTechniker.some((technikerKey) => technikerKey.number === value.technikerKey.number)) {
          this.vertreterTechniker.push(value.technikerKey);
        }
      } else {
        if (this.vertreterTechniker.some((technikerKey) => technikerKey.number === value.technikerKey.number)) {
          this.vertreterTechniker.splice(
            this.vertreterTechniker.findIndex((techniker) => techniker.number === value.technikerKey.number),
            1
          );
        }
      }
    });
  }

  /**
   * Liefert alle Suchfelder mit den entsprechenden Typen
   */
  public getSearchFieldDefinitions(): SearchFieldDefinition[] {
    return [
      new SearchFieldDefinition('number', FilterType.NUMBER),
      new SearchFieldDefinition('firstname', FilterType.STRING),
      new SearchFieldDefinition('lastname', FilterType.STRING)
    ];
  }

  /**
   * {@inheritDoc}
   */
  getService(): ServerPaginationService<Inseminator> {
    return this.inseminatorService;
  }

  /**
   * {@inheritDoc}
   */
  getSortFields() {
    return this.sortField;
  }

  ngOnInit() {
    this.isBesamungstechniker = this.modaKeycloakService.isBesamungstechniker();
    this.inseminatorService.findAllVertreterTechniker().subscribe(
      (inseminator) => {
        this.vertreterTechniker = inseminator;
      },
      (error) => this.customErrorHandlerService.handleError(error)
    );
    this.initPagination();
    this.setDefaultSortCriteria();
    this.loadData(this.searchFilterPagingResource);
  }

  /**
   * Select Event.
   *
   * @param event Event
   */
  onSelect(event) {
    if (event) {
      this.doSelect(event.selected);
    }
  }

  /**
   * {@inheritDoc}
   */
  rowIdentity(row: Inseminator) {
    return row.technikerKey.number;
  }

  /**
   * Setzt den angemeldeten Benutzer zu den Betrieben der uebergebenen Techniker oder entfernt diese.
   */
  vertreten() {
    this.inseminatorService.changeVertretung(this.vertreterTechniker).subscribe(
      () => {
        this.eventService.updateMessage('update data');
        this.notificationService.showSuccessNotification('VERTRETUNG_TECHNIKER.MESSAGE.TECHNIKER_ZUWEISEN.SUCCESS');
      },
      (error) => {
        this.customErrorHandlerService.handleError(error);
        this.notificationService.showErrorNotification('VERTRETUNG_TECHNIKER.MESSAGE.TECHNIKER_ZUWEISEN.ERROR');
      }
    );
  }
}
